import styled from 'styled-components';
import SiderBar from '../components/sidebar/Sidebar';
import StuManagementContainer from '../components/Mypage/MypageStudent/StuManagement.js/StuManagementContainer';
import Buttombar from '../components/sidebar/Buttombar';

const MypageManagement = () => {
  return (
    <MyPageStyled>
      <MyPageSectionStyled>
        <SiderBar />

        <StuManagementContainer />
      </MyPageSectionStyled>
      <Buttombar />
    </MyPageStyled>
  );
};

const MyPageStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f5f8ff;
  overflow: hidden;
`;

const MyPageSectionStyled = styled.div`
  width: 100%;
  display: flex;
  height: 90%;
  @media (max-width: 768px) {
    overflow-y: auto;
  }
`;

export default MypageManagement;
