import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../routes/css/SignUp.module.css';
import Agree from './agree';
import phoneStyle from './mobileSignUp.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import DaumPostCode from 'react-daum-postcode';
import DuplicateCheck from './duplicateCheck';
function MobileSignUpStu() {
  const { state } = useLocation();

  const [button, setButton] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [passwordSame, setPasswordSame] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);
  const [page, setPage] = useState(1);
  const [popup, setPopup] = useState(false);
  const [Duplicate, setDuplicate] = useState(null);
  const [userInfo, setUserInfo] = useState({
    phoneNo: state.phoneNo,
    name: '',
    childName: '',
    loginId: '',
    password: '',
    email: '',
    address: '',
    detailAddress: '',
    school: '',
    grade: '',
    gender: '',
  });
  const API = process.env.REACT_APP_BASE_URL;
  console.log(passwordSame != userInfo.password);
  useEffect(() => {
    if (
      userInfo.loginId === '' ||
      userInfo.password === '' ||
      userInfo.childName === '' ||
      userInfo.name === '' ||
      Duplicate != false ||
      userInfo.password != passwordSame
    )
      setButton(true);
    else setButton(false);
  }, [userInfo, Duplicate, passwordSame]);
  const handleSignUp = () => {
    // console.log("입력되었습니당");

    axios
      .post(`${API}/api/user/signup/student`, userInfo)
      .then((res) => {
        // console.log("회원가입 성공", res);
        alert('회원가입 성공하셨습니다.');
        navigate('/signupclear');
      })
      .catch((error) => {
        // console.log(" 회원가입 실패", error);
      });
  };

  const handleUserInfo = (e) => {
    console.log(e.target.value);
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value,
    });

    console.log(userInfo);
    if (e.target.id == 'password') {
      const pattern1 = /[0-9]/; // 숫자
      if (pattern1.test(e.target.value) && e.target.value.length > 7) {
        // console.log(
        //   "num",
        //   e.target.value.match(/[^0~9]/),
        //   "length",
        //   e.target.value.length > 7
        // );
        setPasswordCheck(true);
      }
    } else if (e.target.id == 'email') {
      if (e.target.value.includes('@') && e.target.value.includes('.'))
        setEmailCheck(true);
    }
    // console.log(e.target.value, e.target.id);
    // console.log(userInfo);
  };

  const navigate = useNavigate();
  const handleNext = () => {
    if (userInfo.loginId === '') alert('아이디를 입력해주세요.');
    else if (userInfo.password === '' || passwordCheck === false)
      alert('비밀번호를 올바르게 입력해주세요.');
    else if (userInfo.childName === '') alert('자녀 이름을 입력해주세요.');
    else if (userInfo.name === '') alert('학부모 이름을 입력해주세요.');
    else setPage(2);
  };

  const onCompletePost = (data) => {
    setUserInfo({
      ...userInfo,
      ['address']: data.address,
      ['postNo']: data.zonecode,
    });
  };
  const handlePasswordSame = (e) => {
    setPasswordSame(e.target.value);
  };
  return (
    <div>
      <div style={{ marginTop: '10px' }} onClick={() => navigate(-1)}>
        <button className={styles.goBack}> {'←'} </button>
        <div className={styles.title}>회원가입</div>
      </div>
      {page === 1 ? (
        <div className={phoneStyle.authBox}>
          <div className={phoneStyle.title}>회원정보 입력</div>
          <div className={phoneStyle.subTitle}>학부모 이름</div>
          <input
            id="name"
            value={userInfo.name}
            placeholder="이름 입력"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
          ></input>
          <div className={phoneStyle.subTitle}>자녀 이름</div>
          <input
            id="childName"
            value={userInfo.childName}
            placeholder="이름 입력"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
          ></input>
          <div className={phoneStyle.subTitle}>아이디</div>
          <input
            id="loginId"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            placeholder="아이디 입력"
          ></input>
          <DuplicateCheck
            loginId={userInfo.loginId}
            setDuplicate={setDuplicate}
          />
          <div className={phoneStyle.subTitle}>
            비밀번호(8-16자의 영문, 숫자 포함)
          </div>
          <input
            id="password"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            type="password"
            placeholder="비밀번호 입력"
          ></input>
          <input
            id="passwordCheck"
            onChange={handlePasswordSame}
            className={phoneStyle.inputs}
            style={{ marginTop: '6px' }}
            type="password"
            placeholder="비밀번호 확인"
          ></input>
          {userInfo.password == '' || passwordSame == '' ? (
            ''
          ) : userInfo.password != passwordSame ? (
            <p style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</p>
          ) : (
            <p style={{ color: 'blue' }}>비밀번호가 일치합니다.</p>
          )}
          <button
            onClick={handleNext}
            disabled={button}
            className={phoneStyle.bottomBtn}
          >
            다음
          </button>
        </div>
      ) : (
        <div className={phoneStyle.authBox}>
          <div className={phoneStyle.title}>추가정보 입력</div>
          <div className={phoneStyle.subTitle}>이메일</div>
          <input
            id="email"
            value={userInfo.email}
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            placeholder="이메일 입력"
          ></input>
          <div className={phoneStyle.subTitle}>집 주소</div>
          <div
            onClick={() => {
              setPopup(!popup);
            }}
            className={styles.inputs}
            style={{ width: '308px', height: '48px', marginTop: '2px' }}
          >
            {userInfo.address == '' ? (
              <p className={styles.addressText}>집 주소 검색</p>
            ) : (
              <p className={styles.addressText} style={{ color: 'black' }}>
                {userInfo.address}
              </p>
            )}
          </div>

          {popup && (
            <div>
              <DaumPostCode onComplete={onCompletePost} autoClose />
            </div>
          )}
          {userInfo.postNo == undefined ? (
            ''
          ) : (
            <div
              className={phoneStyle.inputs}
              style={{ marginTop: '6px', alignItems: 'center' }}
            >
              {userInfo.postNo}
            </div>
          )}

          <input
            id="detailAddress"
            value={userInfo.detailAddress}
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            style={{ marginTop: '6px' }}
            placeholder="상세주소 입력"
          ></input>

          <div>
            <div style={{ float: 'left' }}>
              <div className={phoneStyle.subTitle}>초등학교</div>
              <input
                id="school"
                value={userInfo.school}
                onChange={handleUserInfo}
                className={phoneStyle.inputs}
                style={{ width: '130px' }}
                placeholder="학교 이름 입력"
              ></input>
            </div>
            <div style={{ float: 'right' }}>
              <div className={phoneStyle.subTitle}>학년</div>
              <select
                id="grade"
                value={userInfo.grade}
                onChange={handleUserInfo}
                style={{ width: '150px', height: '51px' }}
                className={phoneStyle.inputs}
              >
                <option value="">학년 선택</option>
                <option value="1">1학년</option>
                <option value="2">2학년</option>
                <option value="3">3학년</option>
                <option value="4">4학년</option>
                <option value="5">5학년</option>
                <option value="6">6학년</option>
              </select>
            </div>
            <div style={{ clear: 'left', paddingTop: '1px' }}>
              <div className={phoneStyle.subTitle}>성별</div>
              <div>
                <button
                  id="gender"
                  value={false}
                  onClick={handleUserInfo}
                  className={
                    userInfo.gender === 'false'
                      ? phoneStyle.sexBtnClick
                      : phoneStyle.sexBtn
                  }
                >
                  남자
                </button>
                <button
                  id="gender"
                  value={true}
                  className={
                    userInfo.gender === 'true'
                      ? phoneStyle.sexBtnClick
                      : phoneStyle.sexBtn
                  }
                  onClick={handleUserInfo}
                  style={{ marginLeft: '6px' }}
                >
                  여자
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={handleSignUp}
            disabled={button}
            className={phoneStyle.bottomBtn}
          >
            회원가입하기
          </button>
        </div>
      )}
    </div>
  );
}
export default MobileSignUpStu;
