// Nav.js
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Navigation from "./Nav.module.css";
import Logo from "../../assets/Logo.png";
import styled from "styled-components";
import CircleIcon from "../../assets/ProfileImage.png";

function Nav() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const handleLogout = () => {
    localStorage.setItem("token", "");
    window.location.href = "/";
  };
  const isTeacher =
    localStorage.getItem("role") == "ROLE_TEACHER" ? true : false;

  return (
    <header className={Navigation.header}>
      <div style={{ marginLeft: "2%" }}>
        <Link to="/" className={Navigation.linkStyle_l}>
          <img
            src={Logo}
            alt="Logo"
            style={{ marginRight: "10px", width: "32px", height: "32px" }}
          />
          <h1 style={{ margin: "0" }}>리틀코더</h1>
        </Link>
      </div>

      <nav>
        <DropdownContainer>
          <CustomDropdownButton onClick={toggleDropdown}>
            <img src={CircleIcon} alt="CircleIcon" />
            <span>{localStorage.getItem("name")}</span>
          </CustomDropdownButton>{" "}
          <DropdownContent isVisible={isDropdownVisible}>
            {isTeacher ? (
              <DropdownLink to="/mypageteach">마이페이지</DropdownLink>
            ) : (
              <DropdownLink to="/mypage">마이페이지</DropdownLink>
            )}
            {isTeacher ? (
              <DropdownLink to="/tInfo">회원정보관리</DropdownLink>
            ) : (
              <DropdownLink to="/sInfo">회원정보관리</DropdownLink>
            )}
            <DropdownLink to="/" onClick={handleLogout}>
              로그아웃
            </DropdownLink>
          </DropdownContent>
        </DropdownContainer>
      </nav>
    </header>
  );
}

export default Nav;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CustomDropdownButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 5px;
  }

  span {
    color: var(--BC3, #3460fe);
    text-align: right;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  position: absolute;
  border-radius: 8px;
  border: 1px solid var(--Line1, #ccc);
  background: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 110px;
  margin-top: 5px;
`;

const DropdownLink = styled(Link)`
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333333;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */

  &:hover {
    background-color: #f5f5f5;
    color: var(--BC3, #3460fe);
  }
`;
