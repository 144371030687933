import { useState } from 'react';
import styled from 'styled-components';
import ScheduleDetail from './ScheduleDetail';
import CreateMonthSchedule from './CreateMonthSchedule';
import CreateDateSchedule from './CreateDateSchedule';
function Schedules(prop) {
  const [dropDown, setDropDown] = useState(false);
  const [monthPopup, setMonthPopup] = useState(false);
  const [datePopup, setDatePopup] = useState(false);

  const scheduleDetail = prop.DtoList;
  const info = prop.info;
  const userMatchId = info.userMatchId;

  return (
    <div>
      {monthPopup ? (
        <CreateMonthSchedule
          userMatchId={userMatchId}
          onClose={setMonthPopup}
        />
      ) : (
        ''
      )}
      {datePopup ? (
        <CreateDateSchedule userMatchId={userMatchId} onClose={setDatePopup} />
      ) : (
        ''
      )}
      <ScheduleBox
        onClick={() => {
          setDropDown(!dropDown);
        }}
      >
        <Text color="#5C80FF" fontSize="16px" width="92px">
          #{info.userMatchId}
        </Text>
        <Text color="#888" fontSize="16px" width="28px">
          초{info.schoolGrade}
        </Text>
        <Text color="#333" fontSize="20px" width="52px">
          {info.studentName}
        </Text>
        <Text
          style={{ marginLeft: '40px' }}
          color="#888"
          fontSize="16px"
          width="140px"
        >
          {info.subjectName}
        </Text>
        {dropDown ? (
          <CreateBtnContainer>
            <CreateBtn onClick={() => setDatePopup(!datePopup)}>
              일별 일정 추가
            </CreateBtn>
            <CreateBtn onClick={() => setMonthPopup(!monthPopup)}>
              월별 일정 추가
            </CreateBtn>
          </CreateBtnContainer>
        ) : (
          ''
        )}
      </ScheduleBox>
      {dropDown ? (
        <ScheduleDetail
          setSchedule={prop.setSchedule}
          scheduleDetail={scheduleDetail}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Schedules;

const ScheduleBox = styled.div`
  display: flex;
  width: 90vw;
  height: 64px;
  background-color: #fff;
  border-radius: 8px;
  padding-left: 12px;
  margin: 8px 0 0 28px;
  &:visited {
    border: 2px solid var(--SC3, #5c80ff);
  }

  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
const Text = styled.div`
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};
  font-weight: 600;
  color: ${(props) => props.color};
  margin-left: 8px;
`;

const CreateBtnContainer = styled.div`
  width: 252px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 16px;
`;

const CreateBtn = styled.button`
  width: 120px;
  height: 36px;

  border: 0;
  border-radius: 8px;
  background: var(--BC3, #3460fe);
  font-family: Pretendard;

  color: var(--BG1, #fff);
  font-size: 14px;
  font-weight: 600;
`;
