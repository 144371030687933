import StudentInfo from './configuration/StudentInfo';
import styled from 'styled-components';
import SearchImg from '../../../assets/Search.svg';
import Paging from './configuration/Paging';
import { useEffect, useState } from 'react';
import { getScheduleList } from '../../../api/axios/axios.custom';

function Students() {
  const [page, setPage] = useState(1);
  const [info, setInfo] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  console.log('student');
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    getScheduleList()
      .then((res) => {
        setMaxPage(res.data.length);
        setInfo(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Paging
          text="전체 학생"
          page={page}
          setPage={setPage}
          totalPage={Math.ceil(maxPage / 9)}
        />
        <Search>
          <Filter>
            <option value="1">Filter</option>
          </Filter>
          <SearchInput
            placeholder="검색어 입력"
            onChange={handleSearch}
          ></SearchInput>
          <object data={SearchImg} style={{ marginRight: '6px' }}></object>
        </Search>
      </div>
      <ReviewContainer>
        {info?.map((e) => {
          return (
            <StudentInfo
              code={e.userMatchId}
              name={e.studentName}
              grade={e.schoolGrade}
              class={e.subjectName}
              nextClass={e.scheduleInfoDtoList[0]?.startTime}
            />
          );
        })}
      </ReviewContainer>
    </Container>
  );
}

export default Students;

export const Container = styled.div`
  width: 47vw;
  padding-left: 24px;
  padding-top: 36px;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 47vw;
  margin-top: 26px;
`;

const Search = styled.div`
  width: 372px;
  height: 36px;

  border-radius: 18px;
  background: var(--BG1, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Filter = styled.select`
  border: 0;
  margin-left: 16px;
`;

const SearchInput = styled.input`
  border: 0;
  width: 199px;
`;
