import { styled } from "styled-components";
import StuManagement from "./StuManagement";
import { axiosGetStuSpecific } from "../../../../api/axios/axios.custom";
import { useEffect, useState } from "react";
import { axiosStuModifySave } from "../../../../api/axios/axios.custom";
import LeftArrow from "../../../../assets/LeftArrow.svg";
import { useNavigate } from "react-router-dom";

const StuManagementContainer = () => {
  const getUserId = localStorage.getItem("userId");
  const [studentData, setStudentData] = useState(null);
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPostNo, setSelectedPostNo] = useState("");
  const [selectedDetailAddress, setSelectedDetailAddress] = useState("");
  const [editedGrade, setEditedGrade] = useState("");
  const [saveMessage, setSaveMessage] = useState(null);

  useEffect(() => {
    const getStudentSpecipic = async () => {
      try {
        const response = await axiosGetStuSpecific(getUserId);
        setStudentData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudentSpecipic();
  }, [getUserId]);

  useEffect(() => {
    setSelectedAddress(studentData?.address || "");
    setSelectedPostNo(studentData?.postNo || "");
    setSelectedDetailAddress(studentData?.detailAddress || "");
    setEditedGrade(studentData?.grade || "");
  }, [studentData]);

  const toggleAddressForm = () => {
    setIsAddressFormVisible(!isAddressFormVisible);
  };

  const handleAddressComplete = (data) => {
    setSelectedAddress(data.address);
    setSelectedPostNo(data.zonecode);
    toggleAddressForm();
  };

  const handleGradeChange = (e) => {
    setEditedGrade(e.target.value);
  };

  const handleDetailAddressChange = (e) => {
    setSelectedDetailAddress(e.target.value);
  };

  const handleSave = async () => {
    try {
      await axiosStuModifySave({
        address: selectedAddress,
        detailAddress: selectedDetailAddress,
        postNo: selectedPostNo,
        studentId: getUserId,
        grade: editedGrade,
      });
      setSaveMessage("저장되었습니다");
    } catch (error) {
      setSaveMessage("저장에 실패했습니다");
    }
  };

  const navigate = useNavigate();

  return (
    <StuManagementWrapped onClick={isAddressFormVisible ? toggleAddressForm : null}>
      <MobileTopStyled>
        <img src={LeftArrow} onClick={() => navigate(-1)}></img>
        <NavTitleStyled>회원정보</NavTitleStyled>
      </MobileTopStyled>
      <StuManagement
        studentData={studentData}
        toggleAddressForm={toggleAddressForm}
        selectedAddress={selectedAddress}
        selectedDetailAddress={selectedDetailAddress}
        handleDetailAddressChange={handleDetailAddressChange}
        isAddressFormVisible={isAddressFormVisible}
        handleAddressComplete={handleAddressComplete}
        editedGrade={editedGrade}
        handleGradeChange={handleGradeChange}
        handleSave={handleSave}
        saveMessage={saveMessage}
        setSaveMessage={setSaveMessage}
      ></StuManagement>
    </StuManagementWrapped>
  );
};

const StuManagementWrapped = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const MobileTopStyled = styled.div`
  width: 100%;
  height: 10vh;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 30px;
  @media (min-width: 768px) {
    display: none;
  }
`;
const NavTitleStyled = styled.div`
  color: var(--Font1, #333);
  font-family: Jalnan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 4px;
`;
export default StuManagementContainer;
