import styled from 'styled-components';
import {
  deleteScheduleApi,
  getScheduleList,
} from '../../api/axios/axios.custom';
import deleteImg from '../../assets/mypageTeach/delete.svg';

function ScheduleDetailBox(props) {
  let startTime = props.data.startTime.split('T')[1];
  let endTime = props.data.endTime.split('T')[1];
  let date = props.data.startTime.split('-');
  let day = date[2].split('T')[0];

  [startTime, endTime] = [startTime.slice(0, 5), endTime.slice(0, 5)];

  const handleDelete = () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      deleteScheduleApi(props.data.subjectScheduleId)
        .then((res) => {
          console.log(res);
          alert('삭제되었습니다.');
          getScheduleList()
            .then((res) => {
              console.log(res);
              props.setSchedule(res.data);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log(err);
          alert('삭제에 실패했습니다.');
        });
    } else {
      alert('취소하셨습니다.');
    }
  };

  return (
    <ScheduleDetailContainer>
      <Text margin="20px">{`${date[0]}년 ${date[1]}월 ${day}일 ${startTime}~${endTime}`}</Text>
      <Text margin="51px">{`1시간 40분`}</Text>
      <Text margin="47px">{props.data.type}</Text>
      <Delete onClick={handleDelete}>
        <img src={deleteImg} />
      </Delete>
    </ScheduleDetailContainer>
  );
}

export default ScheduleDetailBox;

const ScheduleDetailContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 29px 0;
  width: 90vw;
  height: 48px;
  border-radius: 8px;
  background: var(--BG1, #fff);
`;
const Text = styled.div`
  width: 250px;
  color: var(--Font2, #555);
  font-size: 16px;
  font-weight: 500;
  margin-left: ${(props) => props.margin};
`;

const Delete = styled.div`
  display: flex;
  margin-left: auto;
  width: 64px;
  height: 28px;
  margin-right: 4px;
  font-size: 13px;
  font-weight: 500;

  border-radius: 8px;

  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
