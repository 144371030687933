import styled from "styled-components";
import lodingImg from "../../assets/loading.svg";

const Loading = () => {
  return (
    <LodingStyledWrapped>
      <LodingStyled>
        <img src={lodingImg}></img>
      </LodingStyled>
    </LodingStyledWrapped>
  );
};

const LodingStyledWrapped = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const LodingStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: #3333334d;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 150px;
    height: 68px;
  }
`;

export default Loading;
