import { Link } from "react-router-dom";
import Buttombar from "../components/sidebar/Buttombar";
import Logo from "../assets/Logo.png";
import FeedButton from "../assets/Feedback.png";
import CalendarButton from "../assets/calendar.png";
import Info from "../assets/Profile2.png";
import TeacherImage from "../assets/TeacherImage.png";
import Teacher from "../components/Mypage/MypageStudent/MobileTeacher";
import styled from "styled-components";
import UserInfo from "../components/Mypage/MypageStudent/MobileUserInfo";

function MobileMyPage() {
  return (
    <MyPageStyled>
      <Header>
        <LogoImage src={Logo} alt="로고" />
        <UserInfo />
        <LogoutLink to="/">로그아웃</LogoutLink>
      </Header>
      <Teacher />
      <Fee>
        <FeeContent>
          <FeeTitle>7월 수강료</FeeTitle>
          <FeeFont>240,000원</FeeFont>
        </FeeContent>
        <FeeButton as={Link} to="/payment">
          수강료 결제
        </FeeButton>{" "}
      </Fee>
      <GroupButton>
        <Link to="/feedback">
          <img
            src={FeedButton}
            style={{ width: "170px", height: "176px", flexShrink: "0" }}
            alt="Feedback"
          />
        </Link>
        <Link to="/mypage/class">
          <img
            src={CalendarButton}
            style={{ width: "170px", height: "176px", flexShrink: "0" }}
            alt="Calendar"
          />
        </Link>
      </GroupButton>
      <MemberInfo>
        <Fee>
          <FeeContent>
            <FeeTitle>회원정보 관리</FeeTitle>
          </FeeContent>
          <RightFeeContent>
            <img
              src={Info}
              style={{ width: "32px", height: "32px" }}
              alt="회원정보"
            />
          </RightFeeContent>
        </Fee>
      </MemberInfo>
      <Buttombar />
    </MyPageStyled>
  );
}

const MyPageStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f5f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Header = styled.div`
  width: 100vw;
  height: 52px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 4%;
`;

const LogoutLink = styled(Link)`
  font-size: 16px;
  color: var(--Font3, #888);
  text-decoration: none;
  margin-right: 4%;
  font-weight: 700;
  font-family: Pretendard;
`;

const MemberInfo = styled.div`
  display: flex;
  width: 90vw;
  align-items: center;
  justify-content: center;
`;

const Fee = styled.div`
  display: flex;
  width: 350px;
  height: 76px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background: var(--BG1, #fff);
  border-radius: 12px;
  margin-top: 15px;
`;

const FeeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%;
`;

const FeeTitle = styled.div`
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const FeeFont = styled.div`
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
`;

const FeeButton = styled.button`
  border-radius: 12px;
  border: 1px solid #5c80ff;
  background: #fff;
  color: var(--BC3, #3460fe);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 96px;
  height: 44px;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const GroupButton = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
`;

const LeftFeeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3%;
`;

const LeftFeeTitle = styled.div`
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const RightFeeContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3%;
`;
export default MobileMyPage;
