import styled from "styled-components";
import style from "./FeedTeacher.module.css";
import React, { useState, useCallback, useEffect } from "react";
import Prev from "./Preview";
import UserInfo from "../../components/Mypage/MypageStudent/MobileUserInfo";
import Paging from "./MobilePaging";
import Logo from "../../assets/Logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ClassFeedback from "./MobileClassFeed";

function Feed() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  return (
    <div className={style.back}>
      <Header>
        <LogoImage src={Logo} alt="로고" />
        <UserInfo />
        <LogoutLink to="/">로그아웃</LogoutLink>
      </Header>
      <MypageBackground>
        <div style={{ marginLeft: "28px", marginTop: "8px" }}>
          <Paging
            text="수업 피드백"
            page={page}
            setPage={setPage}
            totalPage={Math.ceil(totalPage / 5)}
          />
        </div>
        <div style={{ display: "flex", width: "80vw" }}>
          <StyledLink to="/mobilefeedtab">
            <ClassFeedback page={page} setTotalPage={setTotalPage} />
          </StyledLink>{" "}
        </div>
      </MypageBackground>
    </div>
  );
}

const Header = styled.div`
  width: 100vw;
  height: 52px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 4%;
`;

const LogoutLink = styled(Link)`
  font-size: 16px;
  color: var(--Font3, #888);
  text-decoration: none;
  margin-right: 4%;
  font-weight: 700;
  font-family: Pretendard;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit; /* 상속된 색상 사용 */
`;
export default Feed;

export const MypageBackground = styled.div`
  background-color: #f5f8ff;
  width: 100vw;
  height: 100vh;
`;
