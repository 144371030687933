import { useNavigate } from "react-router-dom";
import { axiosGetKakaoToken } from "../api/axios/axios.custom";

const KakaoAuth = () => {
  const navigate = useNavigate();

  const params = new URL(document.location.toString()).searchParams;
  const code = params.get("code");

  const fetchToken = async (code) => {
    try {
      const jwtToken = await axiosGetKakaoToken(code);

      if (jwtToken) {
        localStorage.setItem("token", jwtToken);
        navigate("/");
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error fetching Kakao token:", error);
    }
  };
  fetchToken(code);

  return <h1>로그인 중입니다</h1>;
};

export default KakaoAuth;
