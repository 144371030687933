import React from "react";
import styled from "styled-components";

const SaveMessageModal = ({ saveMessage, setSaveMessage }) => {
  return (
    <ModalWrapper>
      {saveMessage && (
        <SaveMessageModalStyled>
          <p>{saveMessage}</p>
          <CloseMessageModalButton onClick={() => setSaveMessage(null)}>
            닫기
          </CloseMessageModalButton>
        </SaveMessageModalStyled>
      )}
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
`;

const SaveMessageModalStyled = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const CloseMessageModalButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 8px;
  background: var(--main-color);
  border: none;
  color: white;
  font-size: 16px;
`;

export default SaveMessageModal;
