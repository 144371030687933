import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { paymentInfoApi } from '../../../api/axios/axios.custom';
import { useEffect, useState } from 'react';

function Fee() {
  const today = new Date();
  const todayMonth = today.getMonth() + 1;
  const [totalPrice, setTotalPrice] = useState('');
  useEffect(() => {
    paymentInfoApi()
      .then((res) => {
        console.log(res);
        setTotalPrice(res.data.data.totalPrice);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(totalPrice);
  return (
    <FeeBox>
      <div style={{ marginLeft: '16px', marginTop: '20px' }}>
        <FeeContent
          style={{ fontSize: '12px' }}
        >{`${todayMonth}월 수강료`}</FeeContent>
        <FeeContent>
          {totalPrice == ''
            ? '0원'
            : `${parseInt(totalPrice).toLocaleString()}원`}
        </FeeContent>
        <Link to="/payment" style={{ textDecoration: 'none' }}>
          <FeeBtn>수강료 결제</FeeBtn>
        </Link>
      </div>
    </FeeBox>
  );
}

export default Fee;

const FeeBox = styled.div`
  margin-top: 10px;
  margin-left: 24px;
  width: 184px;
  height: 184px;
  border-radius: 12px;
  background: var(--BG1, #fff);
`;
const FeeContent = styled.div`
  color: var(--Font1, #333);
  font-size: 20px;
  font-weight: 600;
`;
const FeeBtn = styled.button`
  margin-top: 64px;
  width: 152px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid var(--SC3, #5c80ff);
  color: var(--BC3, #3460fe);
  background-color: #fff;
  font-size: 14px;

  font-weight: 700;
`;
