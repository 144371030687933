import style from "./Prev.module.css";
import React, { useState, useEffect } from "react";
import { axiosFeedbackDetail } from "../../api/axios/axios.custom";

function Prev() {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [preview, setPreview] = useState("");
  const previewData = [
    {
      content:
        "안녕하세요, 어머니! 아이들과 수업하는 남우쌤입니다. 이번 시간에는 저번 시간에 만들었던 메뚜기에 이어서 청소로봇을 만들어보았습니다. 청소로봇을 통해 떨어진 물건을 청소봇으로 잡고 제 자리로 가져다 두기 위해 손으로 잡는 단계를 세부적으로 분리하여 만드는 코딩을 진행하였습니다. 아이가 정말 잘따라와주고 항상 새로운 방법을 제시하는 새로운 시각에 매번 놀라는 것 같네요! 저도 아이와 수업하러 가는 것이 정말 재미있어 졌습니다. 다음 수업들도 최선을 다해 진행할수 있도록 노력하겠습니다. 다음주 수업은 어머니가 말씀하신 대로 한주 쉬어가도록 하겠습니다.",
      day: 15,
      month: "01",
      title: "♣ Hello, Spike Prime - 깨끗하게 청소하기",
      videoUrl: "https://www.youtube.com/embed/WF0Ivtf1cZE", // YouTube 링크
      year: 2024,
    },
  ];

  const getPreview = async (feedbackId) => {
    try {
      const response = await axiosFeedbackDetail(feedbackId);
      setPreview(response.data); // 서버에서 받아온 실제 데이터로 업데이트
    } catch (error) {
      console.error("Error fetching feedback detail:", error);
      // 오류가 발생하면 상태를 초기화하거나 오류 처리 로직을 추가할 수 있습니다.
      setPreview(null);
    }
  };

  useEffect(() => {
    const feedbackId = localStorage.getItem("feedbackId");
    getPreview(feedbackId);
  }, []); // 빈 배열을 사용하여 컴포넌트가 처음 마운트될 때만 호출되도록 설정

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const getYoutubeVideoId = (url) => {
    const regex =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|youtu\.be\/|v=)([^#\\?\s*]{11}).*/;
    const match = url.match(regex);
    return match && match[2] ? match[2] : null;
  };

  const getEmbedYoutubeUrl = (videoId) => {
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <div className={style.preview}>
      <button
        style={{
          position: "absolute",
          top: "20px",
          right: "45px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "var(--Font3, #888)",
          fontSize: "28px",
        }}
      >
        ...
      </button>
      <button
        onClick={handleClose}
        style={{
          position: "absolute",
          top: "25px",
          right: "20px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "var(--BC2, #FC4)",
          fontSize: "28px",
        }}
      >
        x
      </button>
      <p
        style={{
          marginLeft: "3%",
          marginTop: "5%",
          color: "#333",
          fontSize: "24px",
          fontWeight: "600",
        }}
      >
        {previewData[0].title}
      </p>

      <p
        style={{
          marginLeft: "3%",
          marginTop: "8%",
          color: "#555555",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {previewData[0].content}
      </p>
      <p
        style={{
          marginLeft: "5%",
          marginTop: "3%",
          color: "#FFCC44",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        [수업 제목]
      </p>
      <p
        style={{
          marginLeft: "5%",
          marginTop: "-2%",
          color: "#555555",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {previewData[0].title}
      </p>
      <p
        style={{
          marginLeft: "5%",
          marginTop: "3%",
          color: "#FFCC44",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        [수업 목표]
      </p>
      <p
        style={{
          marginLeft: "5%",
          marginTop: "20px",
          color: "#555555",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {" "}
        1. 이전 시간에 배운 모터 및 센서에 대해 이야기할 수 있다.
        <br />
        2. 각 모터의 동작을 사람의 행동에 대입하여 생각할 수 있다.
        <br />
        3. 직접 만든 결과물을 활용하여 직접 레고 교구를 옮길 수 있다.
        <br />
        감사합니다.
      </p>

      {previewData[0].videoUrl && (
        <iframe
          title="YouTube Video"
          width="560"
          height="325"
          src={getEmbedYoutubeUrl(getYoutubeVideoId(previewData[0].videoUrl))}
          allowFullScreen
          style={{
            display: "block",
            margin: "0 auto",
            marginTop: "20px",
          }}
        ></iframe>
      )}
    </div>
  ) : null;
}

export default Prev;
