import AdminSiderBar from "../../components/sidebar/AdminSidebar";
import styled from "styled-components";
import AdminApplyTableContainer from "../../components/Admin/ApplyTable/AdminApplyTable.container";

const AdminMainPage = () => {
  return (
    <AdminLoginPageStyeld>
      <AdminSiderBar />
      <AdminApplyTableContainer />
    </AdminLoginPageStyeld>
  );
};

const AdminLoginPageStyeld = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  overflow: hidden;
`;

export default AdminMainPage;
