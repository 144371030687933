import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function StudentInfo(props) {
  const navigate = useNavigate();
  const handlePage = () => {
    navigate(`/mypageteach/student/${props.code}`);
  };
  const getNextClassDate = () => {
    const [nextClassYear, nextClassMonth, nextClassDate] =
      props.nextClass.split('-');
    return `${nextClassYear}년 ${nextClassMonth}월 ${
      nextClassDate.split('T')[0]
    }일`;
  };

  return (
    <Box onClick={handlePage}>
      <div style={{ marginTop: '16px', marginLeft: '20px' }}>
        <Code>#{props.code}</Code>
        <div style={{ display: 'flex', height: '28px' }}>
          <Name>{props.name}</Name>
          <Grade>초{props.grade}</Grade>
        </div>
        <Class>{props.class}</Class>
        <NextClass>
          {props.nextClass == undefined
            ? '수업 일정이 존재하지 않습니다.'
            : `다음수업 : ${getNextClassDate()}`}
        </NextClass>
      </div>
    </Box>
  );
}
export default StudentInfo;

const Box = styled.div`
  width: 15vw;
  height: 144px;

  border-radius: 12px;
  background: var(--BG1, #fff);
  margin-left: 6px;
  margin-top: 6px;
  &:hover {
    cursor: pointer;
  }
`;
export const Code = styled.div`
  color: var(--SC3, #5c80ff);
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
`;
export const Name = styled.div`
  color: var(--Font1, #333);

  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding-top: 2px;
`;
export const Grade = styled.div`
  color: var(--Font3, #888);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */

  display: flex;
  align-items: end;
  margin-left: 4px;
`;
const Class = styled.div`
  color: var(--Font2, #555);
  font-size: 12px;
  font-weight: 600;
  margin-top: 32px;
`;
const NextClass = styled.div`
  color: var(--Font3, #888);
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
`;
