import styled from 'styled-components';
import { StuContainer, StuDetailBox, StuDetailTitle } from './Student';
import { Text } from './Tool';

function Progress() {
  return (
    <StuContainer>
      <StuDetailTitle>진도 관리</StuDetailTitle>
      <StuDetailBox>
        <div style={{ paddingTop: '16px', paddingLeft: '20px' }}>
          <Text>학생 진도를 관리해보세요.</Text>
          <Soon>*곧 출시 예정입니다.</Soon>
        </div>
      </StuDetailBox>
    </StuContainer>
  );
}

export default Progress;
const Soon = styled.div`
  margin-top: 112px;
  margin-right: 20px;
  color: var(--Font3, #888);
  text-align: right;

  font-size: 12px;
  font-weight: 500;
`;
