import Side from "../components/sidebar/Sidebar";
import Feed from "../components/FeedBack/Feed";
import React, { useEffect, useState } from "react";
import Buttombar from "../components/sidebar/Buttombar";
import MobileFeed from "../components/FeedBack/MobileFeed";

function Feedback() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 예시로 768px 이하일 경우 모바일로 간주
    };

    // 초기화 및 화면 크기 변경 시 이벤트 처리
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {isMobile ? <Buttombar /> : <Side />}
      {isMobile ? <MobileFeed /> : <Feed />}
    </div>
  );
}

export default Feedback;
