import { useState, useEffect } from 'react';
import Nav from '../Nav/Nav';
import LoginNav from '../Nav/LoginNav';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import lecoLanding from '../../assets/lecoLandingWeb.png';
import buttonVector from '../../assets/ButtonVector.svg';
import buttonVector2 from '../../assets/ButtonVector2.svg';
import { useNavigate } from 'react-router-dom';
function LandingWeb() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태를 관리하는 상태
  const handleLink = () => {
    navigate('/consult');
  };
  // 컴포넌트가 처음 마운트될 때, 로그인 상태를 확인하여 초기화
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      // 토큰이 저장되어 있으면 로그인 상태로 설정
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div>
      {isLoggedIn ? <LoginNav /> : <Nav />}
      <ButtonFirst onClick={handleLink}>
        무료 체험 수업 신청하고 안내 받기
        <img style={{ marginLeft: '10px' }} src={buttonVector}></img>
      </ButtonFirst>
      <ButtonSecond onClick={handleLink}>
        1:1 맞춤형 코딩 교육 무료 체험 신청
        <img style={{ marginLeft: '10px' }} src={buttonVector2}></img>
      </ButtonSecond>
      <ButtonThird onClick={handleLink}>
        리틀코더 상담 신청하기
        <img style={{ marginLeft: '10px' }} src={buttonVector2}></img>
      </ButtonThird>
      <Landing src={lecoLanding}></Landing>

      <Footer />
    </div>
  );
}

export default LandingWeb;
const ButtonFirst = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 62vw;
  left: 50%;
  transform: translate(-50%, -100%);

  width: 63vw;
  height: 10vw;
  border-radius: 10vw;
  background: #f5f5f5;

  color: #39393e;
  font-size: 3.2vw;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;
const ButtonSecond = styled(ButtonFirst)`
  background-color: #3460fe;
  width: 56vw;
  height: 125px;
  flex-shrink: 0;
  margin-top: 374.7vw;

  font-size: 3vw;
  color: #fff;
`;

const ButtonThird = styled(ButtonFirst)`
  background-color: #3460fe;
  width: 56vw;
  height: 9vw;
  flex-shrink: 0;
  margin-top: 974vw;

  font-size: 4vw;
  color: #fff;
`;
const Landing = styled.img`
  width: 100vw;
`;
