import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from '../../routes/css/selectSignUp.module.css';
import phoneStyle from './mobileSignUp.module.css';
import Agree from './agree';
import { axiosSmsVerify, axiosSmsSend } from '../../api/axios/axios.custom';

import { useState } from 'react';
import { useEffect } from 'react';
import Timer from './timer';

function MobilePhone(props) {
  const state = useLocation();
  const isTeacher = state.state.isTeacher;
  const [button, setButton] = useState(true);
  const [authInputBox, setAuthInputBox] = useState(false);
  const [phoneNum, setPhoneNum] = useState('');
  const [certificationNumber, setCertificationNumber] = useState('');
  const navigate = useNavigate();
  const handlePhoneNum = (e) => {
    setPhoneNum(e.target.value);
  };
  useEffect(() => {
    if (phoneNum.length == 11) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [phoneNum]);
  const handleAuthInputBox = () => {
    axiosSmsSend(phoneNum)
      .then((res) => {
        setAuthInputBox(true);
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleCertificationNumber = (e) => {
    setCertificationNumber(e.target.value);
  };
  const handleVerify = () => {
    axiosSmsVerify(phoneNum, certificationNumber)
      .then((res) => {
        // console.log(res);
        alert('인증되었습니다.');
        {
          isTeacher
            ? navigate('/mobilesignupTeach', { state: { phoneNo: phoneNum } })
            : navigate('/mobilesignupstu', { state: { phoneNo: phoneNum } });
        }
      })
      .catch((err) => {
        alert('올바르지 않은 인증코드입니다.');
      });
  };

  return (
    <div>
      <div
        style={{ marginTop: '10px', display: 'flex' }}
        onClick={() => navigate(-1)}
      >
        <button className={styles.goBack}> {'←'} </button>
        <div className={styles.title}>회원가입</div>
      </div>
      <div className={phoneStyle.authBox}>
        <div className={phoneStyle.title} style={{ marginBottom: '12px' }}>
          본인인증
        </div>

        <input
          className={phoneStyle.inputs}
          onChange={handlePhoneNum}
          placeholder="전화번호 입력 (-없이 숫자만 입력)"
        ></input>
        <button
          disabled={button}
          onClick={handleAuthInputBox}
          className={phoneStyle.authBtn}
        >
          인증번호 전송
        </button>
        {authInputBox === true ? (
          <div className={phoneStyle.inputs} style={{ alignItems: 'center' }}>
            <input
              placeholder="인증번호 입력"
              style={{ border: '0px' }}
              onChange={handleCertificationNumber}
            ></input>
            <Timer style="100px" />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={phoneStyle.authBox}>
        <button onClick={handleVerify} className={phoneStyle.bottomBtn}>
          확인
        </button>
      </div>
    </div>
  );
}
export default MobilePhone;
