import styled from "styled-components";
import PaginationBtn from "../../assets/PaginationBtn.svg";

const Pagination = ({ currentPage, totalPage, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }
  const handleClickPrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleClickNext = () => {
    if (currentPage < totalPage) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <PaginationWrapper>
      <ul>
        <PrevBtn onClick={handleClickPrev} disabled={currentPage === 1}>
          <img src={PaginationBtn} alt="Prev" />
        </PrevBtn>
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber}>
            <PageNumber
              onClick={() => onPageChange(pageNumber)}
              active={pageNumber === currentPage}
            >
              {pageNumber}
            </PageNumber>
          </li>
        ))}
        <NextBtn onClick={handleClickNext} disabled={currentPage === totalPage}>
          <img src={PaginationBtn} alt="Prev" />
        </NextBtn>
      </ul>
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  width: 100%;
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
  }
`;

const PageNumber = styled.button`
  background-color: #f4f4f4;
  color: #555555;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 5px;
  padding: 5px 10px;
  font-size: 16px;
  border: ${(props) => (props.active ? "1px solid #007bff" : "noen")};

  &:hover {
    border: 1px solid #007bff;
  }
`;

const PrevBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    filter: grayscale(0.9);
  }
`;

const NextBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  &:hover {
    opacity: 0.8;
  }
  img {
    transform: rotate(180deg);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    filter: grayscale(0.9);
  }
`;

export default Pagination;
