import Foot from "./Footer.module.css";

function Footer() {
  return (
    <div className={Foot.wrap}>
      <footer>
        <nav>
          <h1>
            방문형 1:1 코딩 교육{" "}
            <span style={{ color: "var(--BC3, #3460FE)" }}>LittleCoder</span>
          </h1>
        </nav>
        <p>
          <span>대표자: 김태운</span>{" "}
          <span style={{ color: "#CCCCCC" }}>|</span>
          <span>사업자 등록 번호: 457-34-01442</span>{" "}
          <span class={Foot.custom_span}>|</span>
          <span>
            본사 주소: 서울시 광진구 자양동 227-7번지 스타시티몰 시티존
            <br className={Foot.line}></br>
            B1F(B128호) 스타시티 스타트업 존 공유오피스
          </span>
          <br />
          <span className={Foot.title}>
            LAB 주소: 서울시 광진구 능동로 209 세종대학교 학생회관 304B
          </span>{" "}
          <span class={Foot.custom_span}>|</span>
          <span>연락처: 010-9863-3020</span>{" "}
          <span style={{ color: "#CCCCCC" }}>|</span>
          <span>이메일: lecoeducare@gmail.com</span>
        </p>
        <div>
          <div class={Foot.footer_left}>
            <h1 class={Foot.h_black}>
              Copyright © 2023 Leco. All Right Reserved.
            </h1>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
