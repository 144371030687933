import styled from "styled-components";
import downArrow from "../../assets/DownArrow.svg";
import noneArrow from "../../assets/noneArrow.svg";

function Paging(props) {
  const maxPage = props.totalPage;
  const handlePageDown = () => {
    props.setPage((prev) => prev - 1);
  };
  const handlePageUp = () => {
    props.setPage((prev) => prev + 1);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", height: "36px" }}>
      <Title>{props.text}</Title>
      {maxPage == 0 ? (
        ""
      ) : (
        <div style={{ display: "flex" }}>
          <ArrowBox style={{ marginLeft: "12px" }}>
            {props.page != 1 ? (
              <ArrowUp onClick={handlePageDown} src={downArrow} />
            ) : (
              <ArrowUp src={noneArrow} />
            )}
          </ArrowBox>
          {`${props.page} / ${maxPage}`}
          <ArrowBox>
            {props.page != maxPage ? (
              <ArrowDown onClick={handlePageUp} src={downArrow} />
            ) : (
              <ArrowDown src={noneArrow} />
            )}
          </ArrowBox>
        </div>
      )}
    </div>
  );
}
export default Paging;

export const Title = styled.div`
  color: var(--Font1, #333);
  font-size: 20px;
  font-weight: 700;
`;
const ArrowBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ArrowDown = styled.img`
  width: 14px;
  height: 14px;
  &:hover {
    cursor: ${(props) => (props.src === noneArrow ? "" : "pointer")};
  }
`;

const ArrowUp = styled(ArrowDown)`
  rotate: 180deg;
`;
