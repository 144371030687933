import { useNavigate } from "react-router-dom";
import { axiosGetNaverToken } from "../api/axios/axios.custom";

const NaverAuth = () => {
  const navigate = useNavigate();

  const params = new URL(document.location.toString()).searchParams;
  const code = params.get("code");

  const fetchToken = async (code) => {
    try {
      const Token = await axiosGetNaverToken(code);

      if (Token) {
        localStorage.setItem("token", Token);
        navigate("/");
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error fetching naver token:", error);
    }
  };
  fetchToken(code);

  return <h1>로그인 중입니다</h1>;
};

export default NaverAuth;
