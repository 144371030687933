import SignIn from './routes/SignIn';
import Mypage from './routes/MypageMain';
import Landing from './routes/Landing';
import ScrollToTop from './components/ScrollUp';
import SignUpStu from './routes/SignUpStu';
import MobileSignUpTeach from './components/Signup/mobileSignUpTeach';
import SelectSignUp from './routes/SelectSignUp';
import SignUpTeach from './routes/SignUpTeach';
import SignUpStuMobile from './components/Signup/mobileSignUpStu';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Consult from './routes/Consult';
import Feedback from './routes/Feedback';
import FeedbackTeacher from './routes/FeedbackTeacher';
import MobilePhone from './components/Signup/mobilePhone';
import AdminMainPage from './routes/Admin/AdminMainPage';
import { createGlobalStyle } from 'styled-components';
import KakaoAuth from './routes/KakaoAuth';
import NaverAuth from './routes/NaverAuth';
import AdminStuInfo from './routes/Admin/AdminStuInfo';
import Schedule from './routes/Schedule';
import Payment from './routes/Payment/Payment';
import SignUpClear from './routes/SignUpClear';
import AdminTeacherInfo from './routes/Admin/AdminTeacherInfo';
import MypageTeach from './routes/MypageTeach/MypageTeach';
import MypageManageClass from './routes/MypageManageClass';
import MypageTeachStu from './routes/MypageTeach/MypageTeachStu';
import MobileFeedTab from './routes/MobileFeedTab';
import Success from './routes/Payment/Success';
import Fail from './routes/Payment/Fail';
import './index.css';
import MypageManagement from './routes/MyPageManagement';
import TMypageManagement from './routes/TMypageManagement';

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signupteacher" element={<SignUpTeach />} />
        <Route path="/signupstudent" element={<SignUpStu />} />
        <Route path="/mobilesignupstu" element={<SignUpStuMobile />} />
        <Route path="/mobilesignupTeach" element={<MobileSignUpTeach />} />
        <Route path="/signupclear" element={<SignUpClear />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/mypage/class" element={<MypageManageClass />} />
        <Route path="/mypageteach" element={<MypageTeach />} />
        <Route path="/mypageteach/student/:id" element={<MypageTeachStu />} />
        <Route path="/mypageteach/schedule" element={<Schedule />} />
        <Route
          path="/mypageteach/student/feedback/:id"
          element={<FeedbackTeacher />}
        />
        <Route path="/consult" element={<Consult />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/mobilefeedtab" element={<MobileFeedTab />} />
        <Route path="/admin/main" element={<AdminMainPage />} />
        <Route path="/mobilephone" element={<MobilePhone />} />
        <Route path="/selectsignup" element={<SelectSignUp />} />
        <Route path="/api/user/kakao-login" element={<KakaoAuth />} />
        <Route path="/api/user/naver-login" element={<NaverAuth />} />
        <Route path="/admin/studentInfo" element={<AdminStuInfo />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/payment/fail" element={<Fail />} />
        <Route path="/admin/teacherInfo" element={<AdminTeacherInfo />} />
        <Route path="/sInfo" element={<MypageManagement />} />
        <Route path="/tInfo" element={<TMypageManagement />} />
      </Routes>
    </Router>
  );
}

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export default App;
