import { useEffect } from 'react';
import { useState } from 'react';
import styles from '../../routes/css/SignUp.module.css';
import style from './mobileSignUp.module.css';

import isMobile from '../mobileCheck';
import { Link, useNavigate } from 'react-router-dom';

function Agree(props) {
  const navigate = useNavigate();
  const isTeacher = props.isTeacher;
  const [allAgreed, setAllAgreed] = useState(false);
  const [lecoAgreed, setLecoAgreed] = useState(false);
  const [eventAgreed, setEventAgreed] = useState(false);
  const [button, setButton] = useState(false);
  const checkMobile = isMobile();

  const allCheck = () => {
    if (allAgreed === false) {
      setAllAgreed(true);
      setLecoAgreed(true);

      setEventAgreed(true);
    } else {
      setAllAgreed(false);
      setLecoAgreed(false);

      setEventAgreed(false);
    }
  };
  const lecoCheck = () => {
    if (lecoAgreed === false) {
      setLecoAgreed(true);
    } else {
      setLecoAgreed(false);
    }
  };

  const eventCheck = () => {
    if (eventAgreed === false) {
      setEventAgreed(true);
    } else {
      setEventAgreed(false);
    }
  };
  useEffect(() => {
    if (lecoAgreed === true && eventAgreed === true) {
      setAllAgreed(true);
    } else {
      setAllAgreed(false);
    }
  }, [lecoAgreed, eventAgreed]);
  useEffect(() => {
    if (lecoAgreed === true) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [lecoAgreed]);

  return (
    <div>
      <div className={styles.terms_list}>
        <div className={styles.terms_ul}>
          {checkMobile === true ? (
            <div className={style.title}>약관동의</div>
          ) : (
            ''
          )}
          <li className={styles.check}>
            <div className={styles.terms_set}>
              <input
                type="checkbox"
                className={styles.checkbox}
                onClick={allCheck}
                checked={allAgreed}
              />
              <span className={styles.checktext}> 약관 전체 동의하기</span>
              {button === true ? (
                <p style={{ width: '320px' }} className={styles.errorSign}>
                  *약관에 동의해주세요
                </p>
              ) : (
                ''
              )}
              <div className={styles.terms_box_all}>
                <p>
                  실명 인증된 아이디로 가입, 위치기반서비스 이용약관(선택),
                  이벤트 • 혜택 정보 수신(선택) 동의를 포함합니다.
                </p>
              </div>
            </div>
          </li>
          <li className={styles.check}>
            <div className={styles.terms_set}>
              <input
                type="checkbox"
                className={styles.checkbox}
                onClick={lecoCheck}
                checked={lecoAgreed}
              />
              <span className={styles.checktext}>
                <span className={styles.color}> [필수]</span> 레코 이용약관
              </span>
            </div>
            <div className={styles.terms_box}>
              <p style={{ paddingLeft: '12px' }}>
                (예시) 레코 서비스 및 제품(이하 ‘서비스’)을 이용해 주셔서
                감사합니다. 본 약관은 다양한 레코 서비스의 이용과 관련하여 레코
                서비스를 제공하는 레코와 이를 이용하는 레코 서비스 회원(이하
                회원) 또는 비회원과의 관계를 설명하며, 아울러 여러분의 레코
                서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고 있습니다.
              </p>
            </div>
          </li>

          <li className={styles.check}>
            <div className={styles.terms_set}>
              <input
                type="checkbox"
                className={styles.checkbox}
                onClick={eventCheck}
                checked={eventAgreed}
              />
              <span className={styles.checktext}>
                <span className={styles.color2}> [선택]</span>
                이벤트 • 혜택 정보수신
              </span>
            </div>
            <div className={styles.terms_box}>
              <p style={{ paddingLeft: '12px', fontWeight: '400' }}>
                (예시) 레코 서비스 및 제품(이하 ‘서비스’)을 이용해 주셔서
                감사합니다. 본 약관은 다양한 레코 서비스의 이용과 관련하여 레코
                서비스를 제공하는 레코와 이를 이용하는 레코 서비스 회원(이하
                회원) 또는 비회원과의 관계를 설명하며, 아울러 여러분의 레코
                서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고 있습니다.
              </p>
            </div>
          </li>
          <div
            onClick={() =>
              navigate('/mobilephone', {
                state: {
                  isTeacher: isTeacher,
                },
              })
            }
          >
            {checkMobile === true ? (
              <button disabled={button} className={styles.btn}>
                다음
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agree;
