import styled from 'styled-components';
import { useState } from 'react';
import { axiosAdminStuSpec } from '../../../api/axios/axios.custom';
import AdminStuModal from './AdminStuModal';
import { AdminStuFilter } from '../../../assets/Data/AdminStuFilter';

const AdminStuTable = ({ dataList, getConsultantList }) => {
  if (!Array.isArray(dataList)) {
    dataList = [];
  }

  const getStudentDetails = async (studentId) => {
    try {
      const response = await axiosAdminStuSpec(studentId);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [selectedData, setSelectedData] = useState(null);
  const handleRowClick = async (data) => {
    const studentDetails = await getStudentDetails(data.studentId);
    setSelectedData(studentDetails);
  };

  const closeModal = () => {
    setSelectedData(null);
  };

  const truncateText = (text, maxLength) => {
    if (text) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 2) + '..';
      } else {
        return text;
      }
    } else {
      return '';
    }
  };

  return (
    <AdminStuTableStyled>
      <table>
        <TheadStyled>
          <tr>
            {AdminStuFilter.map((filter, index) => (
              <th key={index}>{filter}</th>
            ))}
          </tr>
        </TheadStyled>
        <TbodyStyled>
          {dataList.map((data, index) => (
            <tr key={index} onClick={() => handleRowClick(data)}>
              <td>{data.studentId}</td>
              <td>{truncateText(data.childName, 5)}</td>
              <td>{data.gender ? '여자' : '남자'}</td>
              <td>{data.birthDate}</td>
              <td>{truncateText(data.school, 5)}</td>
              <td>{data.grade}학년</td>
              <td>{truncateText(data.address, 12)}</td>
              <td>{data.phoneNo}</td>
              <td>{data.totalPrice}</td>

              <td>{data.selectedTeacherDto?.selectedTeacherName}</td>
            </tr>
          ))}
        </TbodyStyled>
      </table>
      {selectedData && (
        <AdminStuModal
          data={selectedData}
          onClose={closeModal}
          getConsultantList={getConsultantList}
        />
      )}
    </AdminStuTableStyled>
  );
};

const AdminStuTableStyled = styled.div`
  width: 100%;
  margin: 15px 0 30px 0;
  overflow: auto;
  & > table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
`;

const TheadStyled = styled.thead`
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #555555;
  & > tr > th {
    font-weight: 500;
  }
`;

const TbodyStyled = styled.tbody`
  & > tr {
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    height: 55px;
    background-color: white;
    border-top: 3px solid #f4f4f4;
    position: relative;
  }
  & > tr > td:first-child {
    border-radius: 8px 0 0 8px;
  }
  & > tr > td:last-child {
    border-radius: 0 8px 8px 0;
  }
  & > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
`;

const NoListStyled = styled.div`
  text-align: center;
  font-size: 30px;
  margin: 50px;
`;

export default AdminStuTable;
