import styled from 'styled-components';
import Side from '../components/sidebar/Sidebar';
import UserInfo from '../components/Mypage/MypageStudent/UserInfo';
import ClassFeedback from '../components/Mypage/MypageStudent/ClassFeedback';
import Teacher from '../components/Mypage/MypageStudent/Teacher';
import { useEffect, useState } from 'react';
import Paging from '../components/Mypage/MypageTeach/configuration/Paging';
import Fee from '../components/Mypage/MypageStudent/Fee';
import ScheduleInfoBox from '../components/Mypage/MypageStudent/ScheduleInfoBox';

function MyPage() {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  return (
    <div style={{ display: 'flex' }}>
      <Side />
      <MypageBackground>
        <UserInfo />
        <div style={{ marginLeft: '28px', marginTop: '48px' }}>
          <Paging
            text="수업 피드백"
            page={page}
            setPage={setPage}
            totalPage={Math.ceil(totalPage / 7)}
          />
        </div>
        <div style={{ display: 'flex', width: '80vw' }}>
          <ClassFeedback page={page} setTotalPage={setTotalPage} />
          <div>
            <div style={{ display: 'flex' }}>
              <Teacher />
              <Fee />
            </div>
            <ScheduleInfoBox />
          </div>
        </div>
      </MypageBackground>
    </div>
  );
}

export default MyPage;

export const MypageBackground = styled.div`
  background-color: #f5f8ff;
  width: 100vw;
  height: 100vh;
`;
