import { useEffect, useState } from 'react';
import Paging from '../MypageTeach/configuration/Paging';
import FeedbackBox from './FeedbackBox';
import styled from 'styled-components';
import { teacherMypageFeedbackListApi } from '../../../api/axios/axios.custom';
import { useParams } from 'react-router-dom';
import style from '../../FeedBack/FeedTeacher.module.css';
import Posting from '../../FeedBack/Posting';
function Feedback(props) {
  const [page, setPage] = useState(1);
  const [feedbackList, setFeedbackList] = useState();
  const [totalNum, setTotalNum] = useState(1);
  const param = useParams();
  const [isPostingModalOpen, setIsPostingModalOpen] = useState(false);
  console.log(props);
  useEffect(() => {
    teacherMypageFeedbackListApi(page, props.info?.studentId)
      .then((res) => {
        setFeedbackList(res.data.feedbackListDtoList);
        setTotalNum(res.data.totalNum);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, props.info]);
  console.log(feedbackList);
  const handleWriteButtonClick = () => {
    setIsPostingModalOpen(!isPostingModalOpen);
  };
  return (
    <FeedbackContainer>
      <Paging
        text="수업 피드백"
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(totalNum / 7)}
      />
      <div className={style.topWord}>
        최근 피드백 순
        <button
          onClick={handleWriteButtonClick}
          style={{
            width: '85px',
            height: '28px',
            flexShrink: '0',
            borderRadius: '8px',
            background: 'var(--BC3, #3460FE)',
            border: 'none',
            color: 'var(--BG1, #FFF)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '20px',
            marginLeft: '70%',
            cursor: 'pointer',
          }}
        >
          + 작성하기
        </button>
      </div>
      <FeedbackBoxContainer>
        {feedbackList?.map((e) => {
          return (
            <FeedbackBox
              title={e.title}
              content={e.content}
              month={e.month}
              day={e.day}
              feedbackId={e.feedbackId}
            />
          );
        })}
      </FeedbackBoxContainer>
      {isPostingModalOpen && (
        <Posting onClose={() => setIsPostingModalOpen(false)} />
      )}{' '}
    </FeedbackContainer>
  );
}

export default Feedback;

const FeedbackContainer = styled.div`
  margin-top: 28px;
  margin-left: 32px;
  width: 47vw;
`;

const FeedbackBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
`;
