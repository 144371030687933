import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/Logo.png';
import Footer from '../Footer/Footer';
import lecoLandingMobile from '../../assets/lecoLandingMobile.png';
import styled from 'styled-components';
import lecoLanding from '../../assets/lecoLandingWeb.png';
import buttonVector from '../../assets/ButtonVector.svg';
import buttonVector2 from '../../assets/ButtonVector2.svg';
import { Hidden } from '@mui/material';

function LandingMobile() {
  const navigate = useNavigate();
  const handleLink = () => {
    navigate('/consult');
  };
  return (
    <div style={{ margin: '0px', overflowX: 'hidden' }}>
      <Header>
        <LogoImage src={Logo} alt="로고" />
        <LogoutLink to="/signin">로그인</LogoutLink>
      </Header>
      <ButtonFirst onClick={handleLink}>
        무료 체험 수업 신청하고 안내 받기
        <img
          style={{ width: '30px', marginLeft: '10px' }}
          src={buttonVector}
        ></img>
      </ButtonFirst>
      <ButtonSecond onClick={handleLink}>
        1:1 맞춤형 코딩 교육 무료 체험 신청
        <img
          style={{ width: '30px', marginLeft: '10px' }}
          src={buttonVector2}
        ></img>
      </ButtonSecond>
      <ButtonThird onClick={handleLink}>
        리틀코더 상담 신청하기
        <img
          style={{ width: '30px', marginLeft: '10px' }}
          src={buttonVector2}
        ></img>
      </ButtonThird>

      <Landing src={lecoLandingMobile}></Landing>
      <Footer />
    </div>
  );
}
const Landing = styled.img`
  width: 100vw;
`;
const Header = styled.div`
  width: 100vw;
  height: 7vh;
  background-color: #f5f8ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 4%;
`;

const LogoutLink = styled(Link)`
  font-size: 16px;
  color: var(--Font3, #888);
  text-decoration: none;
  margin-right: 4%;
  font-weight: 700;
  font-family: Pretendard;
`;
const ButtonFirst = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 178vw;
  left: 50%;
  transform: translate(-50%, -100%);

  width: 86vw;
  height: 15vw;
  border-radius: 10vw;
  background: #f5f5f5;

  color: #39393e;
  font-size: 5vw;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;
const ButtonSecond = styled(ButtonFirst)`
  background-color: #3460fe;
  width: 85vw;
  height: 13vw;
  flex-shrink: 0;
  margin-top: 891.5vw;
  font-size: 5vw;
  color: #fff;
`;

const ButtonThird = styled(ButtonFirst)`
  background-color: #3460fe;
  width: 85vw;
  height: 14vw;
  flex-shrink: 0;
  margin-top: 2186vw;

  font-size: 6vw;
  color: #fff;
`;
export default LandingMobile;
