import styled from 'styled-components';
import CloseBtn from '../../assets/CloseBtn.svg';
import {
  PopupBg,
  CreateScheduleContainer,
  Title,
  Close,
  TitleBox,
  FlexBox,
  SemiTitle,
} from './CreateMonthSchedule';
import CreateBtns from './CreateBtn';
import SelectDate from './SelectDate';
import { useState } from 'react';
import time from '../../assets/Time.svg';
function CreateDateSchedule(props) {
  const { onClose } = props;
  const [classStartTime, setClassStartTime] = useState('');
  const [classEndTime, setClassEndTime] = useState('');
  const [dailyInfo, setDailyInfo] = useState();

  const getEndTime = (startTime) => {
    let [startHour, startMin] = startTime.split(':');

    startHour = parseInt(startHour);
    startMin = parseInt(startMin);
    startMin += 100;
    if (startMin >= 60) {
      startHour += 1;
      startMin -= 60;
    }
    const endTime = startHour.toString() + ':' + startMin.toString();
    console.log('끝나는시간', endTime, '시작시간', startHour, startMin);
    // return endTime;
  };

  const handleStartTime = (e) => {
    setClassStartTime(e.target.value);
    setClassEndTime(getEndTime(e.target.value));
  };

  // console.log(dailyInfo + 'T' + classStartTime);

  return (
    <PopupBg>
      <ScheduleContainer>
        <TitleBox>
          <Title>일별 일정 추가</Title>
          <Close onClick={() => onClose(false)} src={CloseBtn}></Close>
        </TitleBox>
        <FlexBox style={{ marginLeft: '16px', marginTop: '20px' }}>
          <SelectDate title="수업 시작 일시" setDailyInfo={setDailyInfo} />
          <div style={{ marginLeft: '8px' }}>
            <SemiTitle>수업 시작 시간</SemiTitle>
            <StartTIme
              placeholder="00:00"
              onChange={handleStartTime}
            ></StartTIme>
            <img
              style={{
                position: 'absolute',
                top: '94px',
                right: '20px',
              }}
              src={time}
            ></img>
          </div>
        </FlexBox>
        <CreateBtns
          daily={true}
          classEndTime={dailyInfo + 'T' + classEndTime}
          classStartTime={dailyInfo + 'T' + classStartTime}
          subjectId={1}
          userMatchId={props.userMatchId}
          onClose={props.onClose}
        />
      </ScheduleContainer>
    </PopupBg>
  );
}
const ScheduleContainer = styled(CreateScheduleContainer)`
  width: 392px;
  height: 224px;
`;

const StartTIme = styled.input`
  width: 160px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 4px;
  padding-left: 16px;
  color: var(--Font3, #888);
  font-size: 16px;
  font-weight: 400;
`;
export default CreateDateSchedule;
