import { useEffect, useState } from 'react';
import Paging from '../MypageTeach/configuration/Paging';
import SemiBox from '../MypageTeach/configuration/SemiBox';
import FeedbackBox from './FeedbackBox';
import styled from 'styled-components';
import { info } from '../../../assets/Data/TeacherMypageFeedback';
import { teacherMypageFeedbackListApi } from '../../../api/axios/axios.custom';
import { useParams } from 'react-router-dom';
function Feedback(props) {
  const [page, setPage] = useState(1);
  const [feedbackList, setFeedbackList] = useState();
  const [totalNum, setTotalNum] = useState(1);
  const param = useParams();

  useEffect(() => {
    teacherMypageFeedbackListApi(page, props.info?.studentId)
      .then((res) => {
        setFeedbackList(res.data.feedbackListDtoList);
        setTotalNum(res.data.totalNum);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, props.info]);
  console.log(feedbackList);
  return (
    <FeedbackContainer>
      <Paging
        text="수업 피드백"
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(totalNum / 6)}
      />
      <SemiBox
        title="최근 피드백 순"
        btn="+ 작성하기"
        navigate={`/mypageteach/student/feedback/${param.id}`}
      />
      <FeedbackBoxContainer>
        {feedbackList?.map((e) => {
          return (
            <FeedbackBox
              title={e.title}
              content={e.content}
              month={e.month}
              day={e.day}
            />
          );
        })}
      </FeedbackBoxContainer>
    </FeedbackContainer>
  );
}

export default Feedback;

const FeedbackContainer = styled.div`
  margin-top: 28px;
  margin-left: 32px;
  width: 47vw;
`;

const FeedbackBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
`;
