import style from './FeedTeacher.module.css';
import React, { useState, useCallback, useEffect } from 'react';
import { axiosFeedbackList } from '../../api/axios/axios.custom';
import Nav from '../../components/Nav/LoginNav';
import Posting from './Posting';
import Prev from './Preview';
import { studentFeedbackApi } from '../../api/axios/axios.custom';
import Back from '../Mypage/StudentDetail/Back';
import Feedback from '../Mypage/StudentDetail/FeedCreate';
import { useParams } from 'react-router-dom';
import { getStudentInfoApi } from '../../api/axios/axios.custom';

function FeedTeacher(prop) {
  const params = useParams();
  const [info, setInfo] = useState();
  useEffect(() => {
    getStudentInfoApi(params.id)
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [feedBackList, setFeedBackList] = useState([]);
  const [isPostingModalOpen, setIsPostingModalOpen] = useState(false);
  const page = prop.page;
  const [teacherId, setTeacherId] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);

  const getFeedBackList = useCallback(async (page, studentId, teacherId) => {
    try {
      const requestData = {
        page,
        studentId,
        teacherId,
      };

      // 로그를 사용하여 요청 전에 requestData 값을 확인
      console.log('List Request Data:', requestData);

      const response = await axiosFeedbackList(requestData);
      setFeedBackList(response.feedbackListDtoList.reverse());
    } catch (error) {
      console.error('피드백 리스트 불러오기 실패:', error);
    }
  }, []);

  const handleRowClick = async (feedbackId) => {
    localStorage.setItem('feedbackId', feedbackId);
    // TODO: 특정 피드백 내용을 불러와서 처리
  };

  const handleWriteButtonClick = () => {
    setIsPostingModalOpen(!isPostingModalOpen);
  };

  useEffect(() => {
    if (teacherId !== '') {
      studentFeedbackApi(
        page,
        teacherId,
        parseInt(localStorage.getItem('userId'))
      )
        .then((res) => {
          console.log(res);
          setTotalPage(res.data.totalNum);
          prop.setTotalPage(res.data.totalNum);
          setFeedbacks(res.data.feedbackListDtoList);
        })
        .catch((err) => {
          console.log(err);
        });

      // getFeedBackList 함수를 호출하여 피드백 리스트 갱신
      getFeedBackList(
        page,
        parseInt(localStorage.getItem('userId')),
        teacherId
      );
    }
  }, [page, teacherId, getFeedBackList]);

  const handleUpload = async (uploadedData) => {
    try {
      // 구조를 확인하기 위해 uploadedData를 콘솔에 기록
      console.log('Uploaded Data:', uploadedData);

      // 업로드된 데이터를 기반으로 feedBackList 업데이트
      await getFeedBackList(
        page,
        uploadedData.studentId,
        uploadedData.teacherId
      );
    } catch (error) {
      console.error('업로드 후 목록 갱신 실패:', error);
      // 실패 시 에러 처리 로직 추가
    }
  };

  return (
    <div className={style.back}>
      <Nav />
      <Back name={info?.name} />
      <div className={style.list}>
        <Feedback info={info} />
      </div>
      {isPostingModalOpen && <Posting onUpload={handleUpload} />}
    </div>
  );
}

export default FeedTeacher;
