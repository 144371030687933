export const AdminSearchFilter = [
  "이름",
  "학년",
  "성별",
  "상담번호",
  "거주지역",
  "코딩 경험",
  "문의사항",
  "상담 상태",
  "체험수업",
  "신청 시간",
];
