import { styled } from "styled-components";
import DaumPostCode from "react-daum-postcode";
import SearchImg from "../../../../assets/Search.svg";
import SaveMessageModal from "../../../Common/SaveMessageModal";

const TeaManagement = ({
  teacherData,
  toggleAddressForm,
  selectedAddress,
  selectedDetailAddress,
  handleDetailAddressChange,
  isAddressFormVisible,
  handleAddressComplete,
  handleSave,
  saveMessage,
  setSaveMessage,
}) => {
  return (
    <StuManagementStyled>
      <InfoStyled>
        <TitleText>기본 정보</TitleText>
        {["teacherName", "loginId", "phoneNo", "email"].map((field) => (
          <InfoBoxStyled key={field}>
            <SubjectStyled>{infoLabels[field]}</SubjectStyled>
            <DataStyeld>{teacherData?.[field] || ""}</DataStyeld>
          </InfoBoxStyled>
        ))}
      </InfoStyled>
      <InfoStyled>
        <TitleText>상세 정보</TitleText>
        <InfoBoxStyled style={{ height: "90px" }}>
          <SubjectStyled>집주소</SubjectStyled>
          <FormWrapped>
            <FormStyled onClick={toggleAddressForm}>
              <DataStyeld>{selectedAddress}</DataStyeld>
              <img
                src={SearchImg}
                alt="search"
                onClick={toggleAddressForm}
              ></img>
            </FormStyled>
            <InputStyled
              value={selectedDetailAddress}
              onChange={handleDetailAddressChange}
            ></InputStyled>
          </FormWrapped>
        </InfoBoxStyled>
        {isAddressFormVisible && (
          <Modal>
            <DaumPostCode onComplete={handleAddressComplete} />
          </Modal>
        )}
        <InfoBoxStyled>
          <SubjectStyled>최종학력</SubjectStyled>
          <DataStyeld>
            <span>{teacherData?.school || ""}</span>
            <span>{teacherData?.major || ""}</span>
            <span>{teacherData?.enrollmentStatus || ""}</span>
          </DataStyeld>
        </InfoBoxStyled>
        <InfoBoxStyled>
          <SubjectStyled>성별</SubjectStyled>
          <DataStyeld>{teacherData?.gender || ""}</DataStyeld>
        </InfoBoxStyled>
      </InfoStyled>
      <BottomBox>
        <FronBox>
          <CommonButton isBlue={false}>비밀번호 변경</CommonButton>
          <LeaveBtn>회원탈퇴</LeaveBtn>
        </FronBox>
        <CommonButton isBlue={true} onClick={handleSave}>
          변경사항 저장
        </CommonButton>
      </BottomBox>
      <SaveMessageModal
        saveMessage={saveMessage}
        setSaveMessage={setSaveMessage}
      />
    </StuManagementStyled>
  );
};

const infoLabels = {
  teacherName: "이름",
  loginId: "아이디",
  phoneNo: "전화번호",
  email: "이메일",
};

const StuManagementStyled = styled.div`
  width: 810px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 340px;
  }
`;

const InfoStyled = styled.div`
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const TitleText = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: #333333;
  margin: 10px;
`;

const InfoBoxStyled = styled.div`
  width: 810px;
  height: 48px;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 340px;
  }
`;

const SubjectStyled = styled.div`
  width: 100px;
  color: #333333;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
`;

const DataStyeld = styled.div`
  color: #333333;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  span + span {
    margin-left: 8px;
  }
`;

const SchoolWrapped = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 700px;
  height: 450px;
  @media (max-width: 768px) {
    width: 230px;
  }
`;

const FormStyled = styled.div`
  width: 660px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 3px;
  @media (max-width: 768px) {
    width: 220px;
  }
`;

const InputStyled = styled.input`
  width: 660px;
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 1rem;
  @media (max-width: 768px) {
    width: 220px;
  }
`;

const FormWrapped = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomBox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommonButton = styled.button`
  margin: 0px 3px;
  width: 120px;
  height: 36px;
  border-radius: 8px;
  background: ${(props) => (props.isBlue ? "var(--main-color)" : "white")};
  border: none;
  color: ${(props) => (props.isBlue ? "white" : "#333333")};
  font-size: 1rem;
  font-weight: 600;
`;

const FronBox = styled.div`
  display: flex;
  align-items: center;
`;

const LeaveBtn = styled.div`
  color: #888888;
  font-weight: 500;
  margin-left: 1rem;
  text-decoration: underline;
`;

export default TeaManagement;
