import SignUpTeachWeb from '../components/Signup/SignUpTeachWeb';
import isMobile from '../components/mobileCheck';
import MobileAgree from '../components/Signup/mobileAgree';
function SignUpStu() {
  return (
    <div>
      {isMobile() === true ? (
        <MobileAgree isTeacher={true} />
      ) : (
        <SignUpTeachWeb />
      )}
    </div>
  );
}
export default SignUpStu;
