import styled from 'styled-components';
import { StuContainer, StuDetailBox, StuDetailTitle } from './Student';

function Tool() {
  return (
    <StuContainer>
      <StuDetailTitle>교구 손망실</StuDetailTitle>
      <StuDetailBox>
        <div style={{ paddingTop: '16px', paddingLeft: '20px' }}>
          <Text>필요한 부품을 신청하세요.</Text>
        </div>
        <Btn>본사에 문의하기</Btn>
      </StuDetailBox>
    </StuContainer>
  );
}

export default Tool;

export const Text = styled.div`
  color: var(--Font2, #555);
  font-size: 14px;
  font-weight: 600;
`;
const Btn = styled.button`
  margin: 88px auto;

  width: 12vw;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--SC3, #5c80ff);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--BC3, #3460fe);
  font-size: 12px;
  font-weight: 700;
  background: #fff;
`;
