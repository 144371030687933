import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CloseButton from '../../assets/Close.png';
import {
  axiosFeedbackCreate,
  FeedbackClassList,
} from '../../api/axios/axios.custom';
import { useParams } from 'react-router-dom';

function Posting(props) {
  const [isPostingOpen, setPostingOpen] = useState(true);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [video, setVideo] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [date, setDate] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const param = useParams();
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await FeedbackClassList()
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log(classesData);
        setClasses(classesData);
      } catch (error) {
        console.error('수업 목록을 불러오는 데 실패했습니다:', error);
      }
    };

    fetchClasses();
  }, []);

  const handleClose = () => {
    setPostingOpen(false);
  };

  const handleContent = (e) => {
    setContent(e.target.value);
  };

  const handleDate = (e) => {
    const rawDate = e.target.value;

    const [year, month, day] = rawDate.split('.');

    setDate(rawDate);
    setYear(year);
    setMonth(month);
    setDay(day);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    console.log(date + title);
  };

  const handleVideo = (e) => {
    setVideo(e.target.value);
    console.log(video);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleUpload = async () => {
    try {
      const postData = {
        content: content,
        subjectScheduleId: 3,
        title: date + title,
        userMatchId: param.id,
        videoUrl: video,
      };

      const response = await axiosFeedbackCreate(postData);

      console.log(postData);
      console.log(response);
      alert('피드백이 성공적으로 업로드되었습니다!');

      window.location.reload();

      setPostingOpen(false);
    } catch (error) {
      console.error('게시물 업로드 실패:', error);
    }
  };

  return (
    <div>
      <PostingStyled>
        <TitleText>
          수업 피드백
          <button
            onClick={handleClose}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <img
              src={CloseButton}
              alt="Close"
              style={{ width: '24px', height: '24px' }}
            />
          </button>
        </TitleText>
        <SelectClass>
          <label htmlFor="classSelect">수업 선택</label>
          <Select
            id="classSelect"
            onChange={handleClassChange}
            value={selectedClass}
          >
            <option value="" disabled>
              피드백할 수업 선택
            </option>
            {classes?.map((classItem) => (
              <option key={classItem.id} value={classItem.id}>
                {classItem.name}
              </option>
            ))}
          </Select>
        </SelectClass>
        <ClassDate>
          수업 일자
          <Date
            onChange={handleDate}
            placeholder="날짜 입력 예)2023.08.09"
          ></Date>
        </ClassDate>
        <Title>
          제목
          <InputTitle
            onChange={handleTitle}
            placeholder="제목 입력"
          ></InputTitle>
        </Title>
        <FeedContent>
          <InputLabel>피드백 및 과제</InputLabel>
          <InputFeed
            onChange={handleContent}
            placeholder="피드백 및 과제 입력"
          ></InputFeed>
        </FeedContent>
        <VideoUpload>
          수업 동영상
          <InputVideo
            onChange={handleVideo}
            placeholder="유튜브 영상 링크 입력"
          ></InputVideo>
        </VideoUpload>
        <UploadButton onClick={handleUpload}>피드백 업로드 하기</UploadButton>
      </PostingStyled>
    </div>
  );
}

export default Posting;

const PostingStyled = styled.div`
  width: 600px;
  height: 914px;
  border-radius: 12px;
  background: var(--BG1, #fff);
  position: absolute;
  top: 160px;
  right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled.div`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectClass = styled.div`
  width: 536px;
  height: 70px;
  flex-shrink: 0;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 20px;

  label {
    display: block;
    margin-bottom: 5px;
  }
`;

const Select = styled.select`
  width: 536px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 5px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const ClassDate = styled.div`
  width: 536px;
  height: 70px;
  flex-shrink: 0;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 15px;
`;

const Date = styled.input`
  width: 536px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 5px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Title = styled.div`
  width: 536px;
  height: 70px;
  flex-shrink: 0;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 15px;
`;

const InputTitle = styled.input`
  width: 536px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 5px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const FeedContent = styled.div`
  width: 536px;
  flex-shrink: 0;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
`;

const InputFeed = styled.textarea`
  width: 536px;
  height: 320px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
  resize: none; /* Disable textarea resizing */
`;

const VideoUpload = styled.div`
  width: 536px;
  height: 70px;
  flex-shrink: 0;
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 15px;
`;

const InputVideo = styled.input`
  width: 536px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 5px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const UploadButton = styled.button`
  width: 536px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--BC3, #3460fe);
  border: none;
  color: var(--BG1, #fff);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  cursor: pointer;
`;
const CloseButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const CloseImage = styled.img`
  width: 24px;
  height: 24px;
`;
