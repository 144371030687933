import style from "./Feed.module.css";
import React, { useState } from "react";
import Prev from "./Preview";
import UserInfo from "../../components/Mypage/MypageStudent/UserInfo";
import Paging from "../../components/Mypage/MypageTeach/configuration/Paging";
import ClassFeedback from "./FeedbackClass";
import styled from "styled-components";

function Feed() {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  return (
    <div className={style.back}>
      <MypageBackground>
        <UserInfo />
        <div style={{ marginLeft: "28px", marginTop: "48px" }}>
          <Paging
            text="수업 피드백"
            page={page}
            setPage={setPage}
            totalPage={Math.ceil(totalPage / 7)}
          />
        </div>
        <div style={{ display: "flex", width: "80vw" }}>
          <ClassFeedback page={page} setTotalPage={setTotalPage} />
        </div>
      </MypageBackground>
    </div>
  );
}

export default Feed;

export const MypageBackground = styled.div`
  background-color: #f5f8ff;
  width: 100%;
  height: 140vh;
`;
