import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { paymentSuccessApi } from '../../api/axios/axios.custom';
import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

function Success() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const orderId = params.get('orderId');
  const paymentKey = params.get('paymentKey');
  const amount = params.get('amount');
  const successInfo = {
    orderId: orderId,
    paymentKey: paymentKey,
    amount: amount,
  };
  useEffect(() => {
    paymentSuccessApi(successInfo)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <LoadingContainer>
        <Loading></Loading>
        <div
          style={{
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          결제가 완료되었습니다.
        </div>
        <Button onClick={() => navigate('/mypage')}>확인</Button>
      </LoadingContainer>
    </Container>
  );
}

export default Success;
const Button = styled.div`
  position: absolute;
  bottom: 0;

  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 350px;
  height: 56px;

  border-radius: 8px;
  background: var(--BC3, #3460fe);

  &:hover {
    cursor: pointer;
  }
`;
const rotationanimation = keyframes`
from{
    transform: rotate(0deg);
  border-radius: 0px;
}
to{
    transform: rotate(180deg);
    border-radius: 20px;
}
`;
const LoadingContainer = styled.div`
  width: 100vw;
`;
const Container = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const Loading = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #ff812e;
  animation: ${rotationanimation} 1s linear infinite;

  margin: 0 auto;
`;
