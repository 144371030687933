import styled from "styled-components";
import Paging from "../Mypage/MypageTeach/configuration/Paging";
import Feedback, { FeedbackBox } from "./FeedDetail";
import { info } from "../../assets/Data/StudentMypageFeedback";
import { useEffect, useState } from "react";
import { getTeacher, studentFeedbackApi } from "../../api/axios/axios.custom";

function ClassFeedback(prop) {
  const page = prop.page;
  const [teacherId, setTeacherId] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getTeacher();
        setTeacherId(res.data.teacherId);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (teacherId != "")
      studentFeedbackApi(
        page,
        teacherId,
        parseInt(localStorage.getItem("userId"))
      )
        .then((res) => {
          console.log(res);
          setTotalPage(res.data.totalNum);
          prop.setTotalPage(res.data.totalNum);
          setFeedbacks(res.data.feedbackListDtoList);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [page, teacherId]);

  const dummyFeedbacks = [
    { month: "01", day: "01", title: "피드백 제목 1" },
    { month: "01", day: "02", title: "피드백 제목 2" },
    // 여기에 더미 데이터 추가 가능
  ];

  return (
    <FeedbackContainer>
      {totalPage === 0 ? (
        <FeedbackBoxNull>등록된 피드백이 없습니다.</FeedbackBoxNull>
      ) : (
        feedbacks?.map((e) => {
          return (
            <Feedback
              date={`${e.month}/${e.day}`}
              title={e.title}
              year={e.year}
            />
          );
        })
      )}
    </FeedbackContainer>
  );
}

export default ClassFeedback;

const FeedbackContainer = styled.div`
  margin-left: 28px;
  width: 35vw;
`;

const FeedbackBoxNull = styled(FeedbackBox)`
  background-color: #f5f8ff;
  text-align: center;
  margin: 0 auto;
  margin-top: 48px;
  font-size: 24px;
`;
