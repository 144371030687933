import styled from 'styled-components';
import { FlexBox, SelectBox, SemiTitle } from './CreateMonthSchedule';

function MonthSchedulTime(props) {
  const handleStartTime = (e) => {
    props.timeInfo.classStartTime = e.target.value;
  };
  const handleEndTime = (e) => {
    props.timeInfo.classEndTime = e.target.value;
  };
  const handleDay = (e) => {
    props.timeInfo.dayOfWeek = e.target.value;
  };
  return (
    <FlexBox style={{ marginTop: '16px' }}>
      <div>
        <SemiTitle>수업 요일</SemiTitle>
        <SelectBox onChange={handleDay} width="116px">
          <option value="MONDAY">월요일</option>
          <option value="TUESDAY">화요일</option>
          <option value="WEDNESDAY">수요일</option>
          <option value="THURSDAY">목요일</option>
          <option value="FRIDAY">금요일</option>
          <option value="SATURDAY">토요일</option>
          <option value="SUNDAY">일요일</option>
        </SelectBox>
      </div>
      <div>
        <SemiTitle>시작 시간</SemiTitle>
        <InputBox placeholder="00:00" onChange={handleStartTime}></InputBox>
      </div>
      <div>
        <SemiTitle>종료 시간</SemiTitle>
        <InputBox placeholder="00:00" onChange={handleEndTime}></InputBox>
      </div>
    </FlexBox>
  );
}
export default MonthSchedulTime;

const InputBox = styled.input`
  width: 94px;

  width: ${(props) => props.width};
  height: 36px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding-left: 16px;
  color: var(--Font3, #888);
  font-size: 14px;
  font-weight: 400;
  margin-left: 6px;
`;
