import styles from './SignIn.module.css';
import kakagoLogo from '../../assets/kakaoLogo.png';

function KakaoLoginButton() {
  const API_KEY = process.env.REACT_APP_KAKAO_API_KEY;
  const REDIRECTION = 'http://littlecoder.kr/mypage';
  const KakaoLoginAPI = `https://kauth.kakao.com/oauth/authorize?client_id=${API_KEY}&redirect_uri=${REDIRECTION}&response_type=code`;
  const loginHandle = () => {
    window.location.href = KakaoLoginAPI;
  };
  return (
    <button
      onClick={loginHandle}
      type="button"
      className={`${styles.Button} ${styles.kakao}`}
    >
      <div className={styles.buttonText}>
        <div>
          <img className={styles.btnLogo} src={kakagoLogo}></img>
          <span>카카오 로그인</span>
        </div>
      </div>
    </button>
  );
}
export default KakaoLoginButton;
