import styled from 'styled-components';
import more from '../../../../assets/mypageTeach/more.svg';
import { ContentPasteOffSharp } from '@mui/icons-material';
import { useEffect, useState } from 'react';
function ClassInfo(props) {
  const [DdayRender, setDday] = useState(0);
  const [month, setMonth] = useState(props.month);
  const [day, setDay] = useState(props.day);
  const bigMonth = [1, 3, 5, 7, 8, 10, 12];
  const checkMonthDay = (info) => {
    if (info < 10) return `0${info}`;
    else return info;
  };
  const classDateInfo = {
    classYear: parseInt(props.date.slice(0, 4)),
    classMonth: parseInt(props.date.slice(6, 8)),
    classDate: parseInt(props.date.slice(9, 12)),
  };

  const today = new Date();
  const todayDateInfo = {
    todayYear: today.getFullYear(),
    todayMonth: today.getMonth() + 1,
    todayDate: today.getDate(),
  };

  let DdayYear = classDateInfo.classYear - todayDateInfo.todayYear;
  let DdayMonth = classDateInfo.classMonth - todayDateInfo.todayMonth;
  let DdayDate = classDateInfo.classDate - todayDateInfo.todayDate;

  // useEffect(() => {
  //   while (DdayYear != 0) {
  //     DdayYear -= 1;
  //     DdayMonth += 12;
  //   }

  //   while (DdayMonth > 0) {
  //     DdayMonth -= 1;
  //     if (bigMonth.includes(DdayMonth)) DdayDate += 31;
  //     else if (todayDateInfo.todayMonth == 2) DdayDate += 29;
  //     else DdayDate += 30;
  //   }
  //   setDday(DdayDate);
  // }, []);

  return (
    <ClassInfoContainer stuManage={props.StuManage}>
      {props.leftDay == 0 ? (
        <Dday color="#FF6500">오늘</Dday>
      ) : props.leftDay >= `${-7}` ? (
        <Dday color="#5C80FF">D{props.leftDay}</Dday>
      ) : (
        <Dday> D{props.leftDay}</Dday>
      )}

      <TextContainer stuManage={props.StuManage}>
        {props.StuManage ? (
          <Text>{`${props.date} ${props.day} ${props.startTime}-${props.endTime}`}</Text>
        ) : (
          <div style={{ display: 'flex' }}>
            <Text>{`${props.year}년 ${checkMonthDay(
              props.month
            )}월 ${checkMonthDay(props.day)}일 (${props.date})`}</Text>
            <Text>{`${props.startTime}-${props.endTime}`}</Text>
          </div>
        )}

        {props.Stu ? (
          <Class>{props.class}</Class>
        ) : (
          <Text>{`${props.student} 학생`}</Text>
        )}
      </TextContainer>
      {props.StuManage ? (
        ''
      ) : props.Stu ? (
        <img src={more} style={{ marginRight: '16px' }}></img>
      ) : (
        ''
      )}
    </ClassInfoContainer>
  );
}

export default ClassInfo;

const ClassInfoContainer = styled.div`
  width: ${(props) => (props.stuManage ? '70vw' : '47vw')};
  height: 48px;
  border-radius: 8px;
  background: var(--BG1, #fff);
  display: flex;

  align-items: center;
  margin-top: 4px;
  @media screen and (max-width: 750px) {
    width: 90vw;
  }
`;
const TextContainer = styled.div`
  width: 47vw;
  margin: 0 50px 0 8px;
  display: flex;
  justify-content: ${(props) =>
    props.stuMange ? 'space-between' : 'space-between'};
  align-items: center;
  @media screen and (max-width: 750px) {
    width: 95vw;
  }
`;
const Text = styled.div`
  color: var(--Font2, #555);
  font-size: 16px;
  font-weight: 500;
  @media screen and (max-width: 400px) {
    font-size: 15px;
  }
`;
const Dday = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--BC4, #888888);

  font-size: 12px;
  font-weight: 700;

  border-radius: 8px;
  border: 1px solid var(--SC4, #888888);
  background: var(--BG1, #fff);

  width: 60px;
  height: 24px;
  margin-left: 12px;
  color: ${(props) => props.color};
  border-color: ${(props) => props.color};
`;
const Class = styled(Text)`
  @media screen and (max-width: 750px) {
    display: none;
  }
`;
