import styled from 'styled-components';
import CloseBtn from '../../assets/CloseBtn.svg';
import DownArrow from '../../assets/DownArrow.png';
import CreateBtns from './CreateBtn';
import { useEffect, useState } from 'react';
import MonthSchedulTime from './MonthScheduleTime';
function CreateSchedule(props) {
  const { onClose } = props;

  const [classInfo, setClassInfo] = useState({
    subjectId: '1',
    classMonth: '1',
    classNum: '1',
  });
  const [timeInfo, setTimeInfo] = useState([]);

  classInfo.timeInfo = timeInfo;

  useEffect(() => {
    setTimeInfo((prevTimeInfoArray) => {
      const updatedTimeInfoArray = [...prevTimeInfoArray];

      for (
        let i = prevTimeInfoArray.length;
        i < parseInt(classInfo.classNum);
        i++
      ) {
        updatedTimeInfoArray.push({
          classEndTime: '00:00',
          classStartTime: '00:00',
          dayOfWeek: 'MONDAY',
        });
      }

      return updatedTimeInfoArray;
    });
  }, [classInfo.classNum]);
  const handleChange = (e) => {
    setClassInfo({ ...classInfo, [e.target.name]: e.target.value });
  };

  return (
    <PopupBg>
      <CreateScheduleContainer>
        <TitleBox>
          <Title>월별 일정 추가</Title>
          <Close onClick={() => onClose(false)} src={CloseBtn}></Close>
        </TitleBox>
        <div style={{ margin: '20px 0 0 16px' }}>
          <SemiTitle>수업과목</SemiTitle>
          <SelectBox onChange={handleChange} name="subjectId" width="360px">
            <option value="scratch1"> 레고 스파이크 프라임</option>
            <option value="scratch2"> 스크래치 블록코딩</option>
            <option value="scratch3"> 파이썬</option>
          </SelectBox>
          <FlexBox style={{ marginTop: '16px' }}>
            <div>
              <SemiTitle>교육 기간</SemiTitle>
              <SelectBox
                onChange={handleChange}
                name="classMonth"
                width="116px"
              >
                <option value="1"> 1월</option>
                <option value="2"> 2월</option>
                <option value="3"> 3월</option>
                <option value="4"> 4월</option>
                <option value="5"> 5월</option>
                <option value="6"> 6월</option>
                <option value="7"> 7월</option>
                <option value="8"> 8월</option>
                <option value="9"> 9월</option>
                <option value="10"> 10월</option>
                <option value="11"> 11월</option>
                <option value="12"> 12월</option>
              </SelectBox>
            </div>
            <div>
              <FlexBox style={{ alignItems: 'center' }}>
                <SemiTitle>주 수업횟수</SemiTitle>
                <Content>*주 수업횟수 x 4회 등록</Content>
              </FlexBox>
              <SelectBox
                onChange={handleChange}
                name="classNum"
                marginL="6px"
                width="238px"
              >
                <option value="1"> 1회</option>
                <option value="2"> 2회</option>
                <option value="3"> 3회</option>
              </SelectBox>
            </div>
          </FlexBox>
          {timeInfo.map((e) => {
            return <MonthSchedulTime timeInfo={e} time={classInfo} />;
          })}
        </div>
        <CreateBtns
          daily={false}
          timeInfo={timeInfo}
          subjectId={1}
          userMatchId={1}
          onClose={props.onClose}
        />
      </CreateScheduleContainer>
    </PopupBg>
  );
}
export const FlexBox = styled.div`
  display: flex;
`;
export const PopupBg = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;

  position: fixed;
  top: 0;
`;
export const CreateScheduleContainer = styled.div`
  width: 392px;
  padding-bottom: 24px;
  border-radius: 8px;
  background: var(--BG1, #fff);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;
export const Close = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
export const Title = styled.div`
  color: var(--Font1, #333);
  font-size: 16px;
  font-weight: 700;
`;
export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 16px 0 16px;
`;
export const SemiTitle = styled.div`
  color: var(--Font1, #333);
  font-size: 14px;
  font-weight: 600;
  margin-top: 4px;
  margin-left: 6px;
`;
const Content = styled.div`
  color: var(--Font3, #888);
  font-size: 12px;
  font-weight: 600;
  padding-top: 4px;
`;
export const SelectBox = styled.select`
  width: ${(props) => props.width};
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ccc;

  color: var(--Font3, #888);
  font-size: 14px;
  font-weight: 400;
  margin-left: ${(props) => props.marginL};
  padding-left: 16px;
  appearance: none;
  background: url(${DownArrow}) no-repeat 95% 50%/15px;

  &:hover {
    cursor: pointer;
  }
`;

export default CreateSchedule;
