import styled from 'styled-components';

import Students from '../../components/Mypage/MypageTeach/Students';
import Nav from '../../components/Nav/LoginNav';
import ExpectedClass from '../../components/Mypage/MypageTeach/ExpectedClass';

function MypageTeach() {
  return (
    <div>
      <Nav />
      <div style={{ display: 'flex' }}>
        <MainBackground>
          <Students />
          <div style={{ display: 'flex' }}>
            <ExpectedClass />
          </div>
        </MainBackground>
      </div>
    </div>
  );
}
export default MypageTeach;

export const MainBackground = styled.div`
  background-color: #f4f4f4;
  width: 200vw;
  height: 100vh;
  background: #f5f8ff;
  display: flex;
`;
