import styled from 'styled-components';
import { AdminStuSpecFilter } from '../../../assets/Data/AdminStuSpecFilter';
import SearchImg from '../../../assets/Search.svg';
import { useEffect, useState } from 'react';
import { axiosAdminStuSave } from '../../../api/axios/axios.custom';

const AdminStuModal = ({ data, onClose, getConsultantList }) => {
  const [saveMessage, setSaveMessage] = useState(null);
  const [formData, setFormData] = useState({
    memo: data.memo,
    monthlySubjectCount: data.monthlySubjectCount,
    selectedSubjectId: data.selectedSubjectDto?.selectedSubjectId || '',
    selectedTeacherId: data.selectedTeacherDto?.selectedTeacherId || '',
    studentId: data.studentId,
    subjectTimePerSession: data.subjectTimePerSession,
    totalPrice: data.totalPrice,
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axiosAdminStuSave(formData);
      if (getConsultantList) getConsultantList();
      setSaveMessage('저장되었습니다');
    } catch (error) {
      setSaveMessage('저장에 실패했습니다');
    }
  };

  const [showTeacherList, setShowTeacherList] = useState(false);
  const [showClassList, setShowClassList] = useState(false);

  const handleToggleTeacherList = () => {
    setShowTeacherList((prevShowTeacherList) => !prevShowTeacherList);
  };

  const handleToggleClassList = () => {
    setShowClassList((prevShowClassList) => !prevShowClassList);
  };

  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [classList, setClassList] = useState([]);

  const handleTeacherSelect = (teacherName, teacherId) => {
    setSelectedTeacher(teacherName);
    setShowTeacherList(false);
    setShowClassList(false);
    setSelectedClass('');

    const selectedTeacherData = data.teacherList.find(
      (teacher) => teacher.teacherId === teacherId
    );
    if (selectedTeacherData) {
      setClassList(selectedTeacherData.subjectList || []);
    }
    handleInputChange('selectedTeacherId', teacherId);
  };

  const handleClassSelect = (className, classId) => {
    setSelectedClass(className);
    setShowClassList(false);
    handleInputChange('selectedSubjectId', classId);
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <UserInfoStyled>
          <Userinfo>
            <UserBoxstyeld>#{data.studentId}</UserBoxstyeld>
            {data.name}님
          </Userinfo>
          <ModalBtnStyled>
            <CancelButton onClick={onClose}>취소</CancelButton>
            <SaveButton onClick={handleSave}>저장</SaveButton>
          </ModalBtnStyled>
        </UserInfoStyled>

        <h3>학생 기본 정보</h3>
        <table>
          <TheadStyled>
            <tr>
              {AdminStuSpecFilter.map((filter, index) => (
                <th key={index}>{filter}</th>
              ))}
            </tr>
          </TheadStyled>
          <TbodyStyled>
            <tr>
              <td>{data.gender ? '여자' : '남자'}</td>
              <td>{data.birthDate}</td>
              <td>{data.school}</td>
              <td>{data.grade}학년</td>
              <td>{data.address}</td>
              <td>{data.name}</td>
              <td>{data.phoneNo}</td>
              <td>{data.email}</td>
            </tr>
          </TbodyStyled>
        </table>
        <h3>수강료</h3>
        <PayboxStyled>
          <PayDetailStyled>
            월 수업 횟수
            <InputBottonStyled>
              <InputStyled>
                <input
                  placeholder="월 수업 횟수 입력"
                  value={
                    formData.monthlySubjectCount
                      ? formData.monthlySubjectCount
                      : ''
                  }
                  onChange={(e) =>
                    handleInputChange(
                      'monthlySubjectCount',
                      parseInt(e.target.value, 10)
                    )
                  }
                ></input>
                회
              </InputStyled>
              <button>확인</button>
            </InputBottonStyled>
          </PayDetailStyled>
          <PayDetailStyled>
            회당 희망시간{' '}
            <InputBottonStyled>
              <InputStyled>
                <input
                  placeholder="회당 희망시간 입력"
                  value={
                    formData.subjectTimePerSession
                      ? formData.subjectTimePerSession
                      : ''
                  }
                  onChange={(e) =>
                    handleInputChange(
                      'subjectTimePerSession',
                      parseInt(e.target.value, 10)
                    )
                  }
                ></input>
                분
              </InputStyled>
              <button>확인</button>
            </InputBottonStyled>
          </PayDetailStyled>
          <PayDetailStyled>
            총 수업 금액{' '}
            <InputBottonStyled>
              <InputStyled>
                <input
                  placeholder="총 수업 금액 입력"
                  value={formData.totalPrice ? formData.totalPrice : ''}
                  onChange={(e) =>
                    handleInputChange(
                      'totalPrice',
                      parseInt(e.target.value, 10)
                    )
                  }
                ></input>
                원
              </InputStyled>
              <button>확인</button>
            </InputBottonStyled>
          </PayDetailStyled>
        </PayboxStyled>
        <ClassBoxWraped>
          <ClassWraped>
            <h3>교사/수업 지정</h3>
            <ClassBoxStyled>
              <InputWrap>
                교사 지정
                <InputBottonStyled>
                  <InputStyled>
                    <input
                      placeholder={
                        data.selectedTeacherDto?.selectedTeacherName ||
                        '교사 이름 입력'
                      }
                      onClick={handleToggleTeacherList}
                      value={
                        selectedTeacher ||
                        (data.selectedTeacherDto?.selectedTeacherName ?? '')
                      }
                    ></input>{' '}
                    <button onClick={handleToggleTeacherList}>
                      <img src={SearchImg} alt="search" />
                    </button>
                  </InputStyled>
                  <button>확인</button>
                </InputBottonStyled>
                <TeacherListContainer showTeacherList={showTeacherList}>
                  {showTeacherList && Array.isArray(data.teacherList) && (
                    <TeacherList>
                      {data.teacherList.map((teacher) => (
                        <div
                          key={teacher.teacherId}
                          onClick={() =>
                            handleTeacherSelect(
                              teacher.teacherName,
                              teacher.teacherId
                            )
                          }
                        >
                          {teacher.teacherName}
                        </div>
                      ))}
                    </TeacherList>
                  )}
                </TeacherListContainer>
              </InputWrap>
              <InputWrap>
                수업 지정
                <InputBottonStyled>
                  <InputStyled>
                    <input
                      placeholder={
                        classList.length === 0
                          ? '해당 교사의 수업이 없습니다.'
                          : '수업 과목 입력'
                      }
                      onClick={handleToggleClassList}
                      value={
                        !selectedTeacher &&
                        data.selectedSubjectDto?.selectedSubjectName
                          ? data.selectedSubjectDto.selectedSubjectName
                          : selectedClass || ''
                      }
                    ></input>{' '}
                    <button onClick={handleToggleClassList}>
                      <img src={SearchImg} alt="search" />
                    </button>
                  </InputStyled>
                  <button>확인</button>
                </InputBottonStyled>
                <TeacherListContainer showClassList={showClassList}>
                  {showClassList && Array.isArray(classList) && (
                    <TeacherList>
                      {classList.map((subject) => (
                        <div
                          key={subject.subjectId}
                          onClick={() =>
                            handleClassSelect(
                              subject.subjectName,
                              subject.subjectId
                            )
                          }
                        >
                          {subject.subjectName}
                        </div>
                      ))}
                    </TeacherList>
                  )}
                </TeacherListContainer>
              </InputWrap>
            </ClassBoxStyled>
          </ClassWraped>

          <ClassWraped>
            <h3>메모</h3>
            <ClassBoxStyled>
              <InputBoxStyeld>
                <textarea
                  placeholder="메모 입력"
                  value={formData.memo ? formData.memo : ''}
                  onChange={(e) => handleInputChange('memo', e.target.value)}
                ></textarea>
              </InputBoxStyeld>
            </ClassBoxStyled>
          </ClassWraped>
        </ClassBoxWraped>
        {saveMessage && (
          <SaveMessageModal>
            <p>{saveMessage}</p>
            <CloseMessageModalButton onClick={() => setSaveMessage(null)}>
              닫기
            </CloseMessageModalButton>
          </SaveMessageModal>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  padding: 100px 20px 20px 260px;
  border-radius: 5px;
  background-color: #f4f4f4;
  & > table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
`;

const UserInfoStyled = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Userinfo = styled.div`
  float: left;
  display: flex;
  color: #333333;
  font-size: 20px;
`;

const UserBoxstyeld = styled.div`
  width: 65px;
  height: 20px;
  background-color: var(--sub-color);
  font-size: 14px;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const TheadStyled = styled.thead`
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #555555;
  background-color: white;
`;
const ModalBtnStyled = styled.div`
  float: right;
  margin-bottom: 10px;
`;

const TbodyStyled = styled.tbody`
  & > tr {
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    height: 55px;
    background-color: white;
    border-top: 3px solid #f4f4f4;
  }
  & > tr > td:first-child {
    border-radius: 8px 0 0 8px;
  }
  & > tr > td:last-child {
    border-radius: 0 8px 8px 0;
  }
  & > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PayboxStyled = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 5px;
`;

const PayDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClassBoxStyled = styled.div`
  width: 90%;
  height: 180px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
`;

const ClassBoxWraped = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputBottonStyled = styled.div`
  margin-top: 5px;
  display: flex;
  button {
    margin-left: 3px;
    width: 70px;
    height: 44px;
    border-radius: 8px;
    background: var(--main-color);
    border: none;
    color: white;
  }
`;

const InputStyled = styled.div`
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: 100%;
  height: 44px;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  input {
    width: 90%;
    font-size: 16px;
    border: none;
  }
  button {
    background-color: white;
    width: 24px;
  }
`;

const ClassWraped = styled.div`
  width: 50%;
`;

const InputBoxStyeld = styled.div`
  height: 100%;
  width: 100%;
  textarea {
    width: 95%;
    height: 90%;
    resize: none;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;

const TeacherListContainer = styled.div`
  position: absolute;
  overflow: auto;
  background-color: white;
  max-height: 120px;
  transition: max-height 0.3s ease-in-out;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  margin: 0 3px 0 3px;
`;

const TeacherList = styled.div`
  div {
    padding: 8px;
    border: 1px solid #ccc;
    color: #555555;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 15px;
  width: 100%;
  position: relative;
`;

const CancelButton = styled.button`
  margin-right: 5px;
  width: 68px;
  height: 40px;
  border-radius: 8px;
  background: white;
  border: none;
  color: black;
  font-size: 16px;
`;

const SaveButton = styled.button`
  margin-left: 3px;
  width: 68px;
  height: 40px;
  border-radius: 8px;
  background: var(--main-color);
  border: none;
  color: white;
  font-size: 16px;
`;

const SaveMessageModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;
    margin-bottom: 15px;
  }
  transition: opacity 0.5s ease-in-out;
`;

const CloseMessageModalButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 8px;
  background: var(--main-color);
  border: none;
  color: white;
  font-size: 16px;
`;

export default AdminStuModal;
