import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';
import SignInBox from '../components/login/SignInBox';
import { useEffect, useState } from 'react';
import MobileCheck from '../components/mobileCheck';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
function SignIn() {
  const navigate = useNavigate();
  const isMobile = MobileCheck();
  return (
    <Background>
      {isMobile ? '' : <Nav />}

      <SignInBox />
    </Background>
  );
}

export default SignIn;

const Background = styled.div`
  background: #f5f8ff;
  height: 100vh;
  @media screen and (max-width: 768px) {
    background-color: #fff;
  }
`;
