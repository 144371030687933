import { useNavigate } from 'react-router-dom';
import styles from '../../routes/css/SignUp.module.css';
import Agree from './agree';
import { useState } from 'react';
function MobileAgree(props) {
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ marginTop: '10px' }} onClick={() => navigate(-1)}>
        <button className={styles.goBack}> {'←'} </button>
        <div className={styles.title}>회원가입</div>
      </div>

      <Agree isTeacher={props.isTeacher} />
    </div>
  );
}
export default MobileAgree;
