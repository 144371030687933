import styled from "styled-components";

function UserInfo() {
  return (
    <UserInfoContainer>
      <Role>학생</Role>
      <Name>{localStorage.getItem("name")}</Name>
    </UserInfoContainer>
  );
}
export default UserInfo;

const UserInfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 28px;
  margin-right: 170px;
`;
const Role = styled.div`
  width: 33px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--SC3, #5c80ff);
  color: var(--BG1, #fff);
  font-size: 12px;
  font-weight: 600;
`;
const Name = styled.div`
  color: var(--Font1, #333);
  font-size: 20px;
  font-weight: 600;
`;
