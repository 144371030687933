import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import myhomeActive from "../../assets/HomeActive.png";
import myhomeInActive from "../../assets/HomeInActive.png";
import reviewActive from "../../assets/ReviewActive.png";
import reviewInActive from "../../assets/ReviewInActive.png";
import scheduleActive from "../../assets/ScheduleActive.png";
import scheduleInActive from "../../assets/ScheduleIncActive.png";
import profileActive from "../../assets/ProfileActive.png";
import profileInActive from "../../assets/ProfileInActive.png";

function Buttombar() {
  const location = useLocation();

  return (
    <ButtombarStyled>
      <CustomLink to="/mypage" isActive={location.pathname === "/mypage"}>
        <IconContainer>
          <Icon
            src={
              location.pathname === "/mypage" ? myhomeActive : myhomeInActive
            }
            alt="My Home"
          />
          <Text>마이홈</Text>
        </IconContainer>
      </CustomLink>
      <CustomLink to="/feedback" isActive={location.pathname === "/feedback"}>
        <IconContainer>
          <Icon
            src={
              location.pathname === "/feedback" ? reviewActive : reviewInActive
            }
            alt="Review"
          />
          <Text>수업피드백</Text>
        </IconContainer>
      </CustomLink>
      <CustomLink
        to="/mypage/class"
        isActive={location.pathname === "/mypage/class"}
      >
        <IconContainer>
          <Icon
            src={
              location.pathname === "/mypage/class"
                ? scheduleActive
                : scheduleInActive
            }
            alt="Schedule"
          />
          <Text>수업일정</Text>
        </IconContainer>
      </CustomLink>
      <CustomLink to="/sinfo" isActive={location.pathname === "/sinfo"}>
        <IconContainer>
          <Icon
            src={
              location.pathname === "/sinfo" ? profileActive : profileInActive
            }
            alt="Profile"
          />
          <Text>회원정보</Text>
        </IconContainer>
      </CustomLink>
    </ButtombarStyled>
  );
}

const ButtombarStyled = styled.div`
  width: 100vw;
  height: 10vh;
  background-color: var(--BG1, #fff);
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  @media (min-width: 768px) {
    display: none;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: ${(props) =>
    props.isActive ? "var(--BC3, #3460FE)" : "var(--Font3, #888)"};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Text = styled.p`
  font-size: 10px;
  margin-top: 4px;
  font-family: Pretendard;
  font-weight: 600;
`;

export default Buttombar;
