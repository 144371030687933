import styled from 'styled-components';
import { AdminConsultingFilter } from '../../../assets/Data/AdminConsultingFilter';
import { useState } from 'react';
import { axiosAdminConSave } from '../../../api/axios/axios.custom';

const AdminApplyModal = ({ data, onClose, getConsultantList, formatDate }) => {
  const [saveMessage, setSaveMessage] = useState(null);

  const [formData, setFormData] = useState({
    consultantId: data.consultantId,
    consultantStatus: data.consultantStatus,
    wantTryClass: data.wantTryClass,
    memo: data.memo,
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axiosAdminConSave(formData);
      if (getConsultantList) getConsultantList();
      setSaveMessage('저장되었습니다');
    } catch (error) {
      setSaveMessage('저장에 실패했습니다');
    }
  };

  const handleStatusChange = (status) => {
    handleInputChange('consultantStatus', status);
  };

  const handleTryChange = (status) => {
    handleInputChange('wantTryClass', status);
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <TitleStyle>상담신청 세부내용</TitleStyle>
        <TableWrap>
          <table>
            <TheadStyled>
              <tr>
                {AdminConsultingFilter.map((filter, index) => (
                  <th key={index}>{filter}</th>
                ))}
              </tr>
            </TheadStyled>
            <TbodyStyled>
              <tr>
                <td>{data.name}</td>
                <td>{data.grade}</td>
                <td>{data.gender ? '여자' : '남자'}</td>
                <td>{data.phoneNo}</td>
                <td>{data.region}</td>
                <td>{data.codingExperience ? 'O' : 'X'}</td>
                <td>{formatDate(data.applicationTime)}</td>
              </tr>
            </TbodyStyled>
          </table>
        </TableWrap>
        <ContentBoxWraped>
          <TextStyled>문의사항</TextStyled>
          <InputBoxStyeld>
            <textarea
              placeholder="문의사항이 없습니다."
              value={data.content}
              readOnly
            ></textarea>
          </InputBoxStyeld>
        </ContentBoxWraped>
        <BoxWrapd>
          <MemoBoxWraped>
            <TextStyled>메모</TextStyled>
            <InputBoxStyeld>
              <textarea
                placeholder="메모 입력를 입력하세요."
                value={formData.memo ? formData.memo : ''}
                onChange={(e) => handleInputChange('memo', e.target.value)}
              ></textarea>
            </InputBoxStyeld>
          </MemoBoxWraped>
          <ToggleBoxWraped>
            <BtnStatusWrap>
              <BtnStatus>
                <p>상담진행상황</p>
                <div>
                  <BlueBtn
                    onClick={() => handleStatusChange(true)}
                    active={formData.consultantStatus}
                  >
                    완료
                  </BlueBtn>
                  <BlueBtn
                    onClick={() => handleStatusChange(false)}
                    active={!formData.consultantStatus}
                  >
                    미진행
                  </BlueBtn>
                </div>
              </BtnStatus>
              <BtnStatus>
                <p>체험수업 희망여부</p>
                <div>
                  <BlueBtn
                    onClick={() => handleTryChange(true)}
                    active={formData.wantTryClass}
                  >
                    희망
                  </BlueBtn>
                  <BlueBtn
                    onClick={() => handleTryChange(false)}
                    active={!formData.wantTryClass}
                  >
                    미희망
                  </BlueBtn>
                </div>
              </BtnStatus>
            </BtnStatusWrap>
          </ToggleBoxWraped>
        </BoxWrapd>
        <BottomBtnWrap>
          <CommonButton isBlue={false} style={{ visibility: 'hidden' }}>
            삭제하기
          </CommonButton>
          <div>
            <CommonButton isBlue={false} onClick={onClose}>
              취소하기
            </CommonButton>
            <CommonButton isBlue={true} onClick={handleSave}>
              저장하기
            </CommonButton>
          </div>
        </BottomBtnWrap>
        {saveMessage && (
          <SaveMessageModal>
            <p>{saveMessage}</p>
            <CloseMessageModalButton onClick={() => setSaveMessage(null)}>
              닫기
            </CloseMessageModalButton>
          </SaveMessageModal>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #3333334d;
  backdrop-filter: blur(2px);
`;

const ModalContent = styled.div`
  width: 900px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  padding: 8px 20px;
  z-index: 999;
  filter: none;
`;

const TitleStyle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: var(--main-color);
  margin: 15px 0;
`;

const TableWrap = styled.div`
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0 6px 0 6px;
  background-color: white;
  margin-bottom: 8px;
  & > table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
`;

const TheadStyled = styled.thead`
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #555555;
  background-color: white;
  font-weight: 600;
`;

const TbodyStyled = styled.tbody`
  & > tr {
    height: 40px;
    color: #333333;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    background-color: white;
    border-top: 1px solid #d9d9d9;
  }
  & > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BoxWrapd = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentBoxWraped = styled.div`
  height: 100px;
  border: 1px solid #cccccc;
  padding: 12px 10px;
  border-radius: 4px;
  margin-top: 8px;
`;

const MemoBoxWraped = styled.div`
  width: 76%;
  height: 150px;
  border: 1px solid #cccccc;
  padding: 12px 10px;
  border-radius: 4px;
  margin-top: 8px;
`;

const ToggleBoxWraped = styled.div`
  width: 18%;
  height: 150px;
  border: 1px solid #cccccc;
  padding: 12px 10px;
  border-radius: 4px;
  margin-top: 8px;
`;

const InputBottonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const InputBoxStyeld = styled.div`
  height: 100%;
  width: 100%;
  textarea {
    width: 100%;
    height: 60%;
    resize: none;
    border: none;
    padding: 8px 0px;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;

const CommonButton = styled.button`
  margin: 0px 3px;
  width: 120px;
  height: 36px;
  border-radius: 8px;
  background: ${(props) => (props.isBlue ? 'var(--main-color)' : '#F4F4F4')};
  border: none;
  color: ${(props) => (props.isBlue ? 'white' : '#333333')};
  font-size: 14px;
  font-weight: 600;
`;

const SaveMessageModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;
    margin-bottom: 15px;
  }
  transition: opacity 0.5s ease-in-out;
`;

const CloseMessageModalButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 8px;
  background: var(--main-color);
  border: none;
  color: white;
  font-size: 16px;
`;

const TextStyled = styled.div`
  color: #333333;
  font-weight: 600;
  padding: 8px 2px;
`;

const BottomBtnWrap = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;
const BtnStatusWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BtnStatus = styled.div`
  margin-bottom: 15px;
  p {
    margin: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
`;

const BlueBtn = styled.button`
  margin-left: 5px;
  width: 72px;
  height: 40px;
  border-radius: 4px;
  background: ${(props) => (props.active ? 'white' : 'white')};
  border: 1px solid
    ${(props) => (props.active ? 'var(--main-color)' : '#cccccc')};
  color: ${(props) => (props.active ? 'var(--main-color)' : '#555555')};
  font-size: 16px;
`;
export default AdminApplyModal;
