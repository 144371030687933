import styled from 'styled-components';
import Schedule from '../../../assets/myPageStudent/Scedule.svg';
import Info from '../../../assets/myPageStudent/InfoManagement.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function ScheduleInfoBox() {
  const navigate = useNavigate();

  return (
    <BoxContainer>
      <Box onClick={() => navigate('/mypage/class')}>
        <Text style={{ marginTop: '16px' }}>수업일정 관리</Text>
        <Text style={{ fontSize: '14px', fontWeight: '500' }}>
          수업 일정을 관리하세요
        </Text>
        <img
          style={{ marginTop: '55px', marginLeft: '165px' }}
          src={Schedule}
        ></img>
      </Box>

      <Box
        onClick={() => navigate('/sinfo')}
        style={{ marginLeft: '24px', backgroundColor: '#5C80FF' }}
      >
        <Text style={{ marginTop: '16px' }}>회원 정보 관리</Text>
        <Text style={{ fontSize: '14px', fontWeight: '500' }}>
          회원 정보를 관리하세요
        </Text>
        <img
          style={{ marginTop: '55px', marginLeft: '165px' }}
          src={Info}
        ></img>
      </Box>
    </BoxContainer>
  );
}

export default ScheduleInfoBox;

const BoxContainer = styled.div`
  display: flex;
  margin: 24px 0 0 24px;
`;
const Box = styled.div`
  width: 288px;
  height: 252px;
  border-radius: 12px;
  background: #ff812e;
  box-shadow: 0px 0px 4px 0px rgba(0, 166, 255, 0.24);
  &:hover {
    cursor: pointer;
  }
`;
const Text = styled.div`
  margin-left: 20px;
  height: 32px;
  color: var(--BG1, #fff);
  font-size: 20px;
  font-weight: 700;
`;
