import styled from "styled-components";
import TeaManagementContainer from "../components/Mypage/MypageTeach/TeaManagement.jsx/TeaManagementContainer";

const TMypageManagement = () => {
  return (
    <MyPageStyled>
      <TeaManagementContainer />
    </MyPageStyled>
  );
};

const MyPageStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f5f8ff;
  display: flex;
  overflow-y: auto;
`;

export default TMypageManagement;
