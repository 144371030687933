import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../routes/css/SignUp.module.css';
import signUpProfile from '../../assets/signUpProfile.svg';
import phoneStyle from './mobileSignUp.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import DaumPostCode from 'react-daum-postcode';
import DuplicateCheck from './duplicateCheck';
import styled from 'styled-components';
function MobileSignUpTeach() {
  const { state } = useLocation();
  const [img, setImg] = useState('');
  const [previewImg, setPreviewImg] = useState(signUpProfile);
  const [button, setButton] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [passwordSame, setPasswordSame] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);
  const [page, setPage] = useState(1);
  const [popup, setPopup] = useState(false);
  const [Duplicate, setDuplicate] = useState(null);
  const [userInfo, setUserInfo] = useState({
    phoneNo: state.phoneNo,
    userName: '',
    loginId: '',
    password: '',
    email: '',
    address: '',
    detailAddress: '',
    graduationDegree: '',
    school: '',
    enrollmentStatus: '',
    profile: '',
    gender: '',
  });
  const readURL = (e) => {
    setImg(e.target.files[0]);
    setUserInfo({
      ...userInfo,
      ['profile']: e.target.files[0],
    });

    if (e.target.files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        setPreviewImg(e.target.result);
      };
    }
  };
  const API = process.env.REACT_APP_BASE_URL;
  console.log(passwordSame != userInfo.password);
  useEffect(() => {
    if (
      userInfo.loginId === '' ||
      userInfo.password === '' ||
      userInfo.userName === '' ||
      Duplicate != false ||
      userInfo.password != passwordSame ||
      userInfo.address === '' ||
      userInfo.detailAddress === '' ||
      userInfo.graduationDegree === '' ||
      userInfo.enrollmentStatus === '' ||
      userInfo.school === '' ||
      userInfo.major === '' ||
      userInfo.gender === '' ||
      userInfo.profile === ''
    )
      setButton(true);
    else setButton(false);
  }, [userInfo, Duplicate, passwordSame]);
  const handleSignUp = () => {
    // console.log("입력되었습니당");

    axios
      .post(`${API}/api/user/signup/student`, userInfo)
      .then((res) => {
        // console.log("회원가입 성공", res);
        alert('회원가입 성공하셨습니다.');
        navigate('/signupclear');
      })
      .catch((error) => {
        // console.log(" 회원가입 실패", error);
      });
  };

  const handleUserInfo = (e) => {
    console.log(e.target.value);
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value,
    });

    console.log(userInfo);
    if (e.target.id == 'password') {
      const pattern1 = /[0-9]/; // 숫자
      if (pattern1.test(e.target.value) && e.target.value.length > 7) {
        // console.log(
        //   "num",
        //   e.target.value.match(/[^0~9]/),
        //   "length",
        //   e.target.value.length > 7
        // );
        setPasswordCheck(true);
      }
    } else if (e.target.id == 'email') {
      if (e.target.value.includes('@') && e.target.value.includes('.'))
        setEmailCheck(true);
    }
    // console.log(e.target.value, e.target.id);
    // console.log(userInfo);
  };

  const navigate = useNavigate();
  const handleNext = () => {
    if (userInfo.loginId === '') alert('아이디를 입력해주세요.');
    else if (userInfo.password === '' || passwordCheck === false)
      alert('비밀번호를 올바르게 입력해주세요.');
    else if (userInfo.userName === '') alert('이름을 입력해주세요.');
    else setPage(page + 1);
  };

  const onCompletePost = (data) => {
    setUserInfo({
      ...userInfo,
      ['address']: data.address,
      ['postNo']: data.zonecode,
    });
  };
  const handlePasswordSame = (e) => {
    setPasswordSame(e.target.value);
  };
  return (
    <div>
      <div style={{ marginTop: '10px' }} onClick={() => navigate(-1)}>
        <button className={styles.goBack}> {'←'} </button>
        <div className={styles.title}>회원가입</div>
      </div>
      {page === 1 ? (
        <div className={phoneStyle.authBox}>
          <div className={phoneStyle.title}>회원정보 입력</div>
          <div className={phoneStyle.subTitle}>이름</div>
          <input
            id="userName"
            value={userInfo.userName}
            placeholder="이름 입력"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
          ></input>

          <div className={phoneStyle.subTitle}>아이디</div>
          <input
            id="loginId"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            placeholder="아이디 입력"
          ></input>
          <DuplicateCheck
            loginId={userInfo.loginId}
            setDuplicate={setDuplicate}
          />
          <div className={phoneStyle.subTitle}>
            비밀번호(8-16자의 영문, 숫자 포함)
          </div>
          <input
            id="password"
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            type="password"
            placeholder="비밀번호 입력"
          ></input>
          <input
            id="passwordCheck"
            onChange={handlePasswordSame}
            className={phoneStyle.inputs}
            style={{ marginTop: '6px' }}
            type="password"
            placeholder="비밀번호 확인"
          ></input>
          {userInfo.password == '' || passwordSame == '' ? (
            ''
          ) : userInfo.password != passwordSame ? (
            <p style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</p>
          ) : (
            <p style={{ color: 'blue' }}>비밀번호가 일치합니다.</p>
          )}
          <div className={phoneStyle.subTitle}>이메일</div>
          <input
            id="email"
            value={userInfo.email}
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            placeholder="이메일 입력"
          ></input>
          <button
            onClick={handleNext}
            disabled={button}
            className={phoneStyle.bottomBtn}
          >
            다음
          </button>
        </div>
      ) : page == 2 ? (
        <div className={phoneStyle.authBox}>
          <div className={phoneStyle.title}>교사정보 입력</div>

          <div className={phoneStyle.subTitle}>집 주소</div>
          <div
            onClick={() => {
              setPopup(!popup);
            }}
            className={styles.inputs}
            style={{ width: '308px', height: '48px', marginTop: '2px' }}
          >
            {userInfo.address == '' ? (
              <p className={styles.addressText}>집 주소 검색</p>
            ) : (
              <p className={styles.addressText} style={{ color: 'black' }}>
                {userInfo.address}
              </p>
            )}
          </div>

          {popup && (
            <div>
              <DaumPostCode onComplete={onCompletePost} autoClose />
            </div>
          )}
          {userInfo.postNo == undefined ? (
            ''
          ) : (
            <div
              className={phoneStyle.inputs}
              style={{ marginTop: '6px', alignItems: 'center' }}
            >
              {userInfo.postNo}
            </div>
          )}

          <input
            id="detailAddress"
            value={userInfo.detailAddress}
            onChange={handleUserInfo}
            className={phoneStyle.inputs}
            style={{ marginTop: '6px' }}
            placeholder="상세주소 입력"
          ></input>

          <div>
            <div style={{ float: 'left' }}>
              <div className={phoneStyle.subTitle}>최종학력</div>

              <select
                id="graduationDegree"
                value={userInfo.graduationDegree}
                onChange={handleUserInfo}
                style={{ width: '150px', height: '51px' }}
                className={phoneStyle.inputs}
              >
                <option value="">최종 학력</option>
                <option value="고등학교">고등학교</option>
                <option value="대학교">대학교</option>
                <option value="대학원">대학원</option>
              </select>
            </div>
            <div style={{ float: 'right' }}>
              <div className={phoneStyle.subTitle}>재학 상태</div>
              <select
                id="enrollmentStatus"
                value={userInfo.enrollmentStatus}
                onChange={handleUserInfo}
                style={{ width: '150px', height: '51px' }}
                className={phoneStyle.inputs}
              >
                <option value="">재학 상태 선택</option>
                <option value="재학">재학</option>
                <option value="휴학">휴학</option>
                <option value="졸업">졸업</option>
              </select>
            </div>
            <div
              className={phoneStyle.subTitle}
              style={{ paddingTop: '100px' }}
            >
              학교
            </div>
            <input
              id="school"
              value={userInfo.school}
              placeholder="학교 입력"
              onChange={handleUserInfo}
              className={phoneStyle.inputs}
            ></input>
            <input
              id="major"
              value={userInfo.major}
              placeholder="학과 입력"
              onChange={handleUserInfo}
              className={phoneStyle.inputs}
            ></input>
            <div style={{ clear: 'left', paddingTop: '1px' }}>
              <div className={phoneStyle.subTitle}>성별</div>
              <div>
                <button
                  id="gender"
                  value={false}
                  onClick={handleUserInfo}
                  className={
                    userInfo.gender === 'false'
                      ? phoneStyle.sexBtnClick
                      : phoneStyle.sexBtn
                  }
                >
                  남자
                </button>
                <button
                  id="gender"
                  value={true}
                  className={
                    userInfo.gender === 'true'
                      ? phoneStyle.sexBtnClick
                      : phoneStyle.sexBtn
                  }
                  onClick={handleUserInfo}
                  style={{ marginLeft: '6px' }}
                >
                  여자
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={handleNext}
            disabled={button}
            className={phoneStyle.bottomBtn}
          >
            다음
          </button>
        </div>
      ) : (
        <div className={phoneStyle.authBox}>
          <ProfileHeader>프로필 이미지</ProfileHeader>
          <img className={styles.profileImg} src={previewImg}></img>
          <label for="profile">
            <div className={styles.profileText}>사진 올리기</div>
          </label>
          <input
            type="file"
            id="profile"
            style={{ display: 'none' }}
            accept="image/jpg, image/jpeg, image/png"
            onChange={readURL}
          ></input>
          <button
            onClick={handleSignUp}
            disabled={button}
            className={phoneStyle.bottomBtn}
          >
            회원가입하기
          </button>
        </div>
      )}
    </div>
  );
}
export default MobileSignUpTeach;

const ProfileHeader = styled.div`
  margin-top: 34px;
  margin-bottom: 12px;
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
`;
