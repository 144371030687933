import styled from 'styled-components';
import Back from '../../components/Mypage/StudentDetail/Back';
import Feedback from '../../components/Mypage/StudentDetail/Feedback';
import Nav from '../../components/Nav/LoginNav';
import { MainBackground } from './MypageTeach';
import ExpectedClass from '../../components/Mypage/MypageTeach/ExpectedClass';
import Student from '../../components/Mypage/StudentDetail/Student';
import Tool from '../../components/Mypage/StudentDetail/Tool';
import Progress from '../../components/Mypage/StudentDetail/Progress';
import { getStudentInfoApi } from '../../api/axios/axios.custom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

function MypageTeachStu() {
  const params = useParams();
  const [info, setInfo] = useState();
  useEffect(() => {
    getStudentInfoApi(params.id)
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Nav />
      <StuMainBackground>
        <Back name={info?.name} />
        <FlexBox>
          <Feedback info={info} />
          <div>
            <ExpectedClass Stu={true} />
            <FlexBox style={{ justifyContent: 'space-between' }}>
              <Student
                code={params.id}
                name={info?.name}
                grade={info?.grade}
                gender={info?.gender}
                phoneNo={info?.phoneNo}
              />
              <Tool />
              <Progress />
            </FlexBox>
          </div>
        </FlexBox>
      </StuMainBackground>
    </div>
  );
}
export default MypageTeachStu;
const StuMainBackground = styled(MainBackground)`
  display: table;
`;

const FlexBox = styled.div`
  display: flex;
`;
