import styled from "styled-components";
import Circle from "../assets/Circle.png";
import { useNavigate } from "react-router-dom";

function SignUpClear() {
  const navigate = useNavigate();

  const handleConfirmClick = () => {
    navigate("/signin");
  };

  return (
    <div>
      <SignUpClearPageStyled>
        <ClearComment>
          <img src={Circle} style={{}} />
          <p
            style={{
              fontSize: "16px",
              textAlign: "center",
              color: "var(--Font2, #555)",
              fontWeight: 700,
              fontFamily: "Pretendard",
              lineHeight: "24px",
              margin: 0,
              position: "absolute",
              top: 320,
            }}
          >
            회원가입을 완료했습니다.
          </p>
          <h1
            style={{
              color: "var(--Font1, #333)",
              textAlign: "center",
              fontSize: "40px",
              fontWeight: 400,
              lineHeight: "52px",
              fontFamily: "RixInooAriDuri",
              margin: 0,
              position: "absolute",
              top: 350,
            }}
          >
            Hello World!
          </h1>
        </ClearComment>
        <ClearButton onClick={handleConfirmClick}>확인</ClearButton>
      </SignUpClearPageStyled>
    </div>
  );
}

const SignUpClearPageStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

const ClearComment = styled.div`
  width: 50vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ClearButton = styled.button`
  position: fixed;
  margin: 0 auto;
  bottom: 16px;
  width: 310px;
  height: 48px;

  border: 0px;
  border-radius: 8px;
  background: var(--BC3, #3460fe);

  color: var(--BG1, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

export default SignUpClear;
