import styled from 'styled-components';
import { AdminTeDetailFilter1 } from '../../../assets/Data/AdminTeFilter';
import { AdminTeDetailFilter2 } from '../../../assets/Data/AdminTeFilter';
import { useState } from 'react';
import { axiosAdminTeacSave } from '../../../api/axios/axios.custom';
import Select from 'react-select';

const AdminTeModal = ({ data, onClose, getTeacherList }) => {
  const [saveMessage, setSaveMessage] = useState(null);
  const initialTotalPrice = (data.selectedStudentList || []).reduce(
    (sum, student) => sum + (student.totalPrice || 0),
    0
  );
  const [formData, setFormData] = useState({
    memo: data.memo || '',
    teacherId: data.teacherId || '',
    selectedStudentList: (data.selectedStudentList || []).length
      ? data.selectedStudentList
      : [
          {
            studentId: 0,
          },
        ],
    totalPrice: initialTotalPrice,
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axiosAdminTeacSave(formData);
      if (getTeacherList) getTeacherList();
      setSaveMessage('저장되었습니다');
    } catch (error) {
      setSaveMessage('저장에 실패했습니다');
    }
  };

  const [selectedStudents, setSelectedStudents] = useState(
    (data.selectedStudentList || []).map((student) => ({
      value: student.studentId,
      label: student.studentName,
      price: student.totalPrice || 0,
    })) || []
  );

  const handleStudentSelect = (selectedOptions) => {
    const selectedStudentIds = selectedOptions.map((option) => option.value);
    const selectedStudentPrices = selectedOptions.map((option) => option.price);
    const totalPriceSum = selectedStudentPrices.reduce(
      (sum, price) => sum + (price || 0),
      0
    );

    setSelectedStudents(selectedOptions);
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedStudentList: selectedStudentIds.map((studentId) => ({
        studentId,
      })),
      totalPrice: totalPriceSum,
    }));
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <TitleStyle>교사 정보</TitleStyle>
        <TableWrap>
          <table>
            <TheadStyled>
              <tr>
                {AdminTeDetailFilter1.map((filter, index) => (
                  <th key={index}>{filter}</th>
                ))}
              </tr>
            </TheadStyled>
            <TbodyStyled>
              <tr>
                <td>{data.teacherId}</td>
                <td>{data.name}</td>
                <td>{data.gender ? '여자' : '남자'}</td>
                <td>{data.enrollmentStatus}</td>
                <td>{data.school}</td>
                <td>{data.enrollmentStatus}</td>
                <td>{data.major}</td>
              </tr>
            </TbodyStyled>
          </table>
        </TableWrap>
        <TableWrap>
          <table>
            <TheadStyled>
              <tr>
                {AdminTeDetailFilter2.map((filter, index) => (
                  <th key={index}>{filter}</th>
                ))}
              </tr>
            </TheadStyled>
            <TbodyStyled>
              <tr>
                <td>{data.address}</td>
                <td>{data.phoneNo}</td>
                <td>{data.email}</td>
              </tr>
            </TbodyStyled>
          </table>
        </TableWrap>
        <BoxWrapd>
          <BoxStyeld>
            <InputWrap>
              <TextStyled>담당 학생</TextStyled>
              <InputBottonStyled>
                <Select
                  isMulti
                  options={data.allStudentList.map((student) => ({
                    value: student.studentId,
                    label: student.studentName,
                    price: student.totalPrice,
                  }))}
                  value={selectedStudents}
                  onChange={handleStudentSelect}
                  styles={selectStyles}
                  placeholder="학생 이름 입력"
                />
              </InputBottonStyled>
            </InputWrap>
          </BoxStyeld>
          <BoxStyeld>
            <TextStyled>지급 임금 계산</TextStyled>
            <MiddleContentStyeld>
              <div>
                <SubTextStyeld>지급 임금</SubTextStyeld>
                <SmallBox
                  placeholder="지급 임금"
                  value={formData.totalPrice}
                  readOnly
                ></SmallBox>
              </div>
            </MiddleContentStyeld>
          </BoxStyeld>
        </BoxWrapd>
        <MemoBoxWraped>
          <TextStyled>메모</TextStyled>
          <InputBoxStyeld>
            <textarea
              placeholder="메모 입력를 입력하세요"
              value={formData.memo ? formData.memo : ''}
              onChange={(e) => handleInputChange('memo', e.target.value)}
            ></textarea>
          </InputBoxStyeld>
        </MemoBoxWraped>
        <BottomBtnWrap>
          <CommonButton isBlue={false} style={{ visibility: 'hidden' }}>
            삭제하기
          </CommonButton>
          <div>
            <CommonButton isBlue={false} onClick={onClose}>
              취소하기
            </CommonButton>
            <CommonButton isBlue={true} onClick={handleSave}>
              저장하기
            </CommonButton>
          </div>
        </BottomBtnWrap>
        {saveMessage && (
          <SaveMessageModal>
            <p>{saveMessage}</p>
            <CloseMessageModalButton onClick={() => setSaveMessage(null)}>
              닫기
            </CloseMessageModalButton>
          </SaveMessageModal>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #3333334d;
  backdrop-filter: blur(2px);
`;

const ModalContent = styled.div`
  width: 800px;
  height: 540px;
  border-radius: 12px;
  background-color: white;
  padding: 8px 20px;
  z-index: 999;
  filter: none;
`;

const TitleStyle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: var(--main-color);
  margin: 15px 0;
`;

const TableWrap = styled.div`
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0 6px 0 6px;
  background-color: white;
  margin-bottom: 8px;
  & > table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
`;

const TheadStyled = styled.thead`
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #555555;
  background-color: white;
  font-weight: 600;
`;

const TbodyStyled = styled.tbody`
  & > tr {
    height: 40px;
    color: #333333;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    background-color: white;
    border-top: 1px solid #d9d9d9;
  }
  & > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BoxWrapd = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BoxStyeld = styled.div`
  border-radius: 4px;
  height: 100px;
  border: 1px solid #cccccc;
  padding: 8px;
`;

const MemoBoxWraped = styled.div`
  height: 100px;
  border: 1px solid #cccccc;
  padding: 12px 10px;
  border-radius: 4px;
  margin-top: 8px;
`;

const InputBottonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const InputBoxStyeld = styled.div`
  height: 100%;
  width: 100%;
  textarea {
    width: 100%;
    height: 60%;
    resize: none;
    border: none;
    padding: 8px 0px;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;

const InputWrap = styled.div`
  width: 100%;
  position: relative;
  margin-top: 5px;
`;

const CommonButton = styled.button`
  margin: 0px 3px;
  width: 120px;
  height: 36px;
  border-radius: 8px;
  background: ${(props) => (props.isBlue ? 'var(--main-color)' : '#F4F4F4')};
  border: none;
  color: ${(props) => (props.isBlue ? 'white' : '#333333')};
  font-size: 14px;
  font-weight: 600;
`;

const SaveMessageModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;
    margin-bottom: 15px;
  }
  transition: opacity 0.5s ease-in-out;
`;

const CloseMessageModalButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 8px;
  background: var(--main-color);
  border: none;
  color: white;
  font-size: 16px;
`;

const TextStyled = styled.div`
  color: #333333;
  font-weight: 600;
  padding: 8px 2px;
`;

const SubTextStyeld = styled.div`
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 4px 8px;
`;

const MiddleContentStyeld = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const SmallBox = styled.input`
  width: 250px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1px 10px;
  font-size: 16px;
`;

const BottomBtnWrap = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

const selectStyles = {
  control: (provided) => ({
    ...provided,
    width: 460,
    margin: 10,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#5C80FF',
      color: 'white',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'var(--main-color)',
      color: 'white',
    },
  }),
};

export default AdminTeModal;
