import styled from "styled-components";
import PaymentInfo from "../../components/Payments/PaymentInfo";
import PaymentTopNav from "../../components/Payments/PaymentTopNav";
import { useEffect, useRef, useState } from "react";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import axios from "axios";
import { paymentInfoApi } from "../../api/axios/axios.custom";

const clientKey = "test_ck_DLJOpm5QrlPQ5ABQopQNrPNdxbWn";
function Payment() {
  const [allow, setAllow] = useState(false);
  const [orderId, setOrderId] = useState("");
  // const [userId, setUserId] = useState('');
  const price = 10000;
  const [payInfo, setPayInfo] = useState("");
  useEffect(() => {
    paymentInfoApi()
      .then((res) => {
        setPayInfo(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTossPayments(clientKey).then((tossPayments) => {
      tossPayments.requestPayment("카드", {
        amount: payInfo?.totalPrice, //주문가격
        orderId: orderId, //문자열 처리를 위한 ``사용
        orderName: payInfo?.selectedSubjectDto?.selectedSubjectName, //결제 할 상품(여러건일 경우 복수처리)
        customerName: "리틀코더(주)", //판매자, 판매처 이름
        successUrl: process.env.REACT_APP_TOSS_SUCCESS,
        failUrl: process.env.REACT_APP_TOSS_FAIL,
      });
    });
  }, [orderId]);

  const handlePayment = () => {
    axios
      .post(
        "/api/order/request",
        { orderAmount: payInfo.totalPrice },
        {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        setOrderId(res.data.orderId);
        // setUserId(res.data.userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <PaymentPageContainer>
      <PaymentTopNav />
      <PaymentInfo payInfo={payInfo} setAllow={setAllow} />
      <div id="payment-widget" />
      <PaymentBtn allow={allow} onClick={handlePayment} disabled={!allow}>
        결제하기
      </PaymentBtn>
    </PaymentPageContainer>
  );
}
const PaymentPageContainer = styled.div`
  width: 100vw;
  height: 120vh;
  background: #f5f8ff;
`;
const PaymentBtn = styled.button`
  width: 350px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 24px;
  border-radius: 8px;
  background: ${(props) => (props.allow ? "#3460fe" : "#ccc")};

  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;

  border: 0;
`;
export default Payment;
