import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import LecoLogoImg from "../../assets/Logo.png";
import HomeInactive from "../../assets/HomeInActive.png";
import HomeImg from "../../assets/HomeActive.png";
import FeedInactive from "../../assets/ReviewInActive.png";
import FeedImg from "../../assets/ReviewActive.png";
import ScheduleInactive from "../../assets/ScheduleIncActive.png";
import ScheduleImg from "../../assets/ScheduleActive.png";
import { ReactComponent as ProfileImg } from "../../assets/Profile.svg";
import { ReactComponent as LogoutImg } from "../../assets/Logout.svg";

const SiderBar = () => {
  const { pathname } = useLocation();

  const getIconImage = (section) => {
    switch (section) {
      case "/mypage":
        return pathname === "/mypage" ? HomeImg : HomeInactive;
      case "/feedback":
        return pathname.includes("feedback") ? FeedImg : FeedInactive;
      case "/mypage/class":
        return pathname.startsWith("/mypage/class")
          ? ScheduleImg
          : ScheduleInactive;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <SiderBarStyled>
      <LinkStyled to="/">
        <LogoStyled>
          <img
            src={LecoLogoImg}
            alt=""
            style={{ width: "32px", height: "32px" }}
          />
          <LittleCoderText>리틀코더</LittleCoderText>
        </LogoStyled>
      </LinkStyled>
      <SectionWrap>
        <LinkStyled to="/mypage">
          <SectionStyled active={pathname === "/mypage"} fill={true}>
            마이홈
            <div>
              <img
                src={getIconImage("/mypage")}
                alt="mypage"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </SectionStyled>
        </LinkStyled>
        <LinkStyled to="/feedback">
          <SectionStyled active={pathname.includes("feedback")}>
            수업피드백
            <div>
              <img
                src={getIconImage("/feedback")}
                alt="feedback"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </SectionStyled>
        </LinkStyled>
        <LinkStyled to="/mypage/class">
          <SectionStyled active={pathname.includes("mypage/class")} fill={true}>
            수업일정 관리
            <div>
              <img
                src={getIconImage("/mypage/class")}
                alt="schedule"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </SectionStyled>
        </LinkStyled>
        <LinkStyled to="/sInfo">
          <SectionStyled active={pathname.includes("/sInfo")}>
            회원정보 관리
            <div>
              <ProfileImg stroke="#888888" />
            </div>
          </SectionStyled>
        </LinkStyled>
        <SectionStyledBottom onClick={handleLogout}>
          로그아웃{" "}
          <div>
            <LogoutImg fill="#888888" />
          </div>
        </SectionStyledBottom>{" "}
      </SectionWrap>
    </SiderBarStyled>
  );
};

const SiderBarStyled = styled.div`
  height: 100vh;
  width: 212px;
  min-width: 212px;
  padding-top: 30px;
  background-color: white;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15%;
  width: 140px;
  height: 50px;
  margin-bottom: 50px;
  cursor: pointer;
  text-decoration: none;
`;

const LittleCoderText = styled.div`
  color: var(--Font1, #333);
  font-family: yg-jalnan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-left: 10px;
  text-decoration: none;
`;

const SectionWrap = styled.div`
  height: calc(100% - 120px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const SectionStyled = styled.div`
  width: 160px;
  color: var(--Font2, #555);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 600;
  font-family: Pretendard;

  & div {
    width: 24px;
    height: 24px;
    margin-left: auto;
  }
  color: ${(props) => (props.active ? "#5C80FF" : "#888888")};
  svg {
    stroke: ${(props) => (props.active ? "#5C80FF" : "#888888")};
    fill: ${(props) => (props.active && props.fill ? "#5C80FF" : "white")};
  }
`;

const SectionStyledBottom = styled(SectionStyled)`
  margin-top: auto;
  cursor: pointer;
`;

export default SiderBar;
