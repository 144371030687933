import styled from 'styled-components';
import Schedules from '../components/Schedule/Schedules';
import { scheduleInfo } from '../assets/Data/StudentsScheduleManagement';
import Nav from '../components/Nav/LoginNav';
import { useEffect, useState } from 'react';
import { getScheduleList } from '../api/axios/axios.custom';
import Back from '../components/Mypage/StudentDetail/Back';

function Schedule() {
  const [schedule, setSchedule] = useState([]);
  useEffect(() => {
    getScheduleList()
      .then((res) => {
        console.log(res);
        setSchedule(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Nav></Nav>
      <ScheduleContainer>
        <Back noName={true} />
        <ScheduleTitle>학생 일정 관리</ScheduleTitle>
        {schedule?.map((e) => {
          return (
            <Schedules
              setSchedule={setSchedule}
              info={e}
              DtoList={e.scheduleInfoDtoList}
            />
          );
        })}
      </ScheduleContainer>
    </div>
  );
}

export default Schedule;

const ScheduleTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  padding: 32px 0 0 32px;
`;

const ScheduleContainer = styled.div`
  background: #f5f8ff;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
`;
