import { useEffect, useRef, useState } from "react";

function Timer(prop) {
  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);
  const time = useRef(180);
  const interval = useRef(null);
  
  useEffect(() => {
    time.current -= 1;
  }, []);
  useEffect(() => {
    interval.current = setInterval(() => {
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      time.current -= 1;
    }, 1000);

    return () => clearInterval(interval.current);
  }, []);
  useEffect(() => {
    if (time.current < 0) {
      clearInterval(interval.current);
    }
  }, [sec]);
  return (
    <div
      style={{
        color: "#F00",
        fontSize: "16px",
        fontWeight: "400",
        marginLeft: prop.style,
      }}
    >
      {min}:{sec.toString().padStart(2, "0")}
    </div>
  );
}
export default Timer;
