import styled from 'styled-components';
import LeftArrow from '../../assets/LeftArrow.svg';
import { FlexBox, ModifyBtn } from './PaymentInfo';
import { useNavigate } from 'react-router-dom';

function PaymentTopNav() {
  const navigate = useNavigate();
  return (
    <FlexBox
      style={{
        width: '390px',
        height: '52px',
        margin: '0 auto',
      }}
    >
      <FlexBox style={{ marginTop: '14px', marginLeft: '20px' }}>
        <Left
          src={LeftArrow}
          onClick={() => navigate(-1)}
          style={{ width: '24px', height: ' 24px' }}
        />
        <NavTitle>수강료 결제</NavTitle>
      </FlexBox>
      <MyhomeBtn
        onClick={() => {
          navigate('/mypage');
        }}
      >
        마이홈
      </MyhomeBtn>
    </FlexBox>
  );
}
const Left = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
const NavTitle = styled.div`
  color: var(--Font1, #333);
  font-family: Jalnan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  margin-left: 4px;
`;
const MyhomeBtn = styled.button`
  margin: 0;
  width: 76px;
  height: 32px;
  margin-top: 10px;
  margin-right: 20px;

  border-radius: 8px;
  background: var(--BG1, #fff);

  border: 0px;
  color: var(--Font1, #333);
  font-size: 16px;
  font-weight: 700;
  &hover {
    cursor: pointer;
  }
`;
const Arrow = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
export default PaymentTopNav;
