import styled from 'styled-components';
import { Link } from 'react-router-dom';

function Feedback(props) {
  console.log(props);
  const getDateDiff = (d1) => {
    const date1 = new Date();
    const date2 = new Date(d1);

    const diffDate = date1 - date2;

    const days = Math.floor(diffDate / (1000 * 60 * 60 * 24));
    return Math.abs(days);
  };

  const year = props.year;
  const month = props.date.split('/')[0];
  const day = props.date.split('/')[1];

  const diff = Math.floor(getDateDiff(`${year}-${month}-${day}`));
  return (
    <FeedbackBox>
      <DateBox>
        {diff < 7 ? (
          <Week>이번주</Week>
        ) : diff < 14 ? (
          <Week style={{ color: '#3460FE', border: '1px solid #5C80FF' }}>
            저번주
          </Week>
        ) : (
          <Week
            style={{
              color: '#888888',
              border: ' 1px solid var(--Line1, #CCC)',
            }}
          >{`${Math.floor(diff / 7)}주 전`}</Week>
        )}
        {diff === 0 ? <Day>오늘</Day> : <Day>{`${diff}일 전`}</Day>}
      </DateBox>
      <FeedbackTitle>{`${props.title}`}</FeedbackTitle>
    </FeedbackBox>
  );
}
export default Feedback;

export const FeedbackBox = styled.div`
  width: 475px;
  height: 84px;
  border-radius: 12px;
  background: var(--BG1, #fff);
  margin-top: 10px;
  @media (max-width: 768px) {
    width: 85vw;
  }
`;
const Day = styled.div`
  margin-right: 20px;
  color: var(--Font3, #888);
  text-align: right;
  font-size: 12px;

  font-weight: 600;
`;
const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Week = styled.div`
  margin-top: 16px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 24px;

  border-radius: 8px;
  border: 1px solid #ff812e;
  color: var(--BC4, #ff6500);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`;

const FeedbackTitle = styled.div`
  color: var(--Font1, #333);
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  margin-top: 8px;
`;
