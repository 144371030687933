import styled from 'styled-components';
import { scheduleDetailInfo } from '../../assets/Data/StudentScheduleDetail';
import ScheduleDetailBox from './ScheduleDetailBox';
import { useEffect, useState } from 'react';

function ScheduleDetail(props) {
  const [scheduleDetail, setScheduleDetail] = useState();
  useEffect(() => {
    setScheduleDetail(props.scheduleDetail);
  }, [props.scheduleDetail]);

  return scheduleDetail?.map((e) => {
    return (
      <ScheduleDetailBox
        setSchedule={props.setSchedule}
        setScheduleDetail={setScheduleDetail}
        data={e}
      />
    );
  });
}

export default ScheduleDetail;
