import { FlexBox, CheckBox, SemiTitle } from './PaymentInfo';

function PaymentDropDown(props) {
  console.log(props);
  const handleCheck = (e) => {
    if (e.target.id == 'essential') {
      props.setCheckEssential(!props.checkEssential);
      props.setCheckAll(!props.checkEssential);
      props.setAllow(!props.checkEssential);
    } else props.setCheckSelect(!props.checkSelect);
  };
  return (
    <div>
      <FlexBox
        style={{
          paddingTop: '10px',
          alignItems: 'center',
          paddingLeft: '16px',
        }}
      >
        이용약관에 동의해 주세요.
        <FlexBox>
          <CheckBox
            id="essential"
            checked={props.checkEssential}
            onClick={handleCheck}
            type="checkbox"
          ></CheckBox>
          <SemiTitle
            style={{
              margin: '4px 20px 0px 6px',
            }}
          >
            [필수] 동의
          </SemiTitle>
        </FlexBox>
      </FlexBox>
      <FlexBox
        style={{
          paddingTop: '10px',
          alignItems: 'center',
          paddingLeft: '16px',
        }}
      >
        이용약관에 동의해 주세요.
        <FlexBox>
          <CheckBox
            id="select"
            checked={props.checkSelect}
            onClick={handleCheck}
            type="checkbox"
          ></CheckBox>
          <SemiTitle
            style={{
              margin: '4px 20px 0px 6px',
            }}
          >
            [선택] 동의
          </SemiTitle>
        </FlexBox>
      </FlexBox>
    </div>
  );
}

export default PaymentDropDown;
