import styled from "styled-components";
import { useState } from "react";
import more from "../../../assets/mypageTeach/more.svg";
import { deleteFeedbackApi } from "../../../api/axios/axios.custom";

function FeedbackBox(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(props);

  const handleDeleteFeedback = (res) => {
    const feedbackId = props.feedbackId;

    if (feedbackId !== undefined) {
      deleteFeedbackApi(feedbackId)
        .then((response) => {
          console.log("피드백이 성공적으로 삭제되었습니다");

          console.log("tq", res.data.feedbackListDtoList);
        })
        .catch((error) => {
          console.error("피드백 삭제 중 오류 발생:", error);
        });

      console.log(`ID가 ${feedbackId}인 피드백을 삭제합니다`);
      setIsModalOpen(false);
      window.location.reload();
    } else {
      console.error("feedbackId가 undefined입니다. 삭제를 수행할 수 없습니다.");
    }
  };

  return (
    <Box>
      <div style={{ marginLeft: "20px" }}>
        <TitleBox>
          <Title>{`${props.title}`}</Title>
          <img
            src={more}
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => setIsModalOpen(true)}
            alt="More Options"
          />
        </TitleBox>
        <Content>{props.content}</Content>
        <All>+ 전체보기</All>
      </div>

      {isModalOpen && (
        <Modal>
          <ModalContent>
            <div style={{ marginTop: "60px" }}>피드백을 삭제하시겠습니까?</div>
            <ButtonContainer>
              <Button onClick={() => setIsModalOpen(false)}>유지하기</Button>
              <Button
                onClick={handleDeleteFeedback}
                style={{
                  background: "var(--BC3, #3460FE)",
                  color: " var(--BG1, #FFF) ",
                }}
              >
                삭제하기
              </Button>
            </ButtonContainer>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default FeedbackBox;

const Box = styled.div`
  width: 296px;
  width: 22vw;
  height: 126px;
  border-radius: 12px;
  background: var(--BG1, #fff);
  margin-left: 8px;
  margin-top: 9px;
`;
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;
const Title = styled.div`
  width: 220px;
  color: var(--Font1, #333);
  font-size: 16px;
  font-weight: 600;
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Content = styled.div`
  color: var(--Font2, #555);
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;

  width: 256px;
  height: 32px;
`;
const All = styled.div`
  color: #5980ff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 12px;
`;

const Modal = styled.div`
  /* Styles for the modal overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 280px;
  height: 180px;
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  border-radius: 8px;
  background: var(--BG2, #f4f4f4);
  color: var(--Font1, #333);
  font-size: 14px;
  font-weight: 700;
  font-family: Pretendard;
  padding: 8px 16px;
  margin: 0 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  height: 40px;
`;
