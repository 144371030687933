export const AdminTeFilter = [
  "교사 ID",
  "교사명",
  "성별",
  "아이디",
  "학교",
  "재학상태",
];

export const AdminTeDetailFilter1 = [
  "교사 ID",
  "교사명",
  "성별",
  "최종학력",
  "학교명",
  "재학상태",
  "학과",
];

export const AdminTeDetailFilter2 = ["주소", "전화번호", "이메일"];
