import styled from 'styled-components';
import Side from '../components/sidebar/Sidebar';
import { MypageBackground } from './Mypage';
import ClassInfo from '../components/Mypage/MypageTeach/configuration/ClassInfo';
import Logo from '../assets/Logo.png';
import { studentMypageExpectedClassApi } from '../api/axios/axios.custom';
import { useEffect, useState } from 'react';
import MobileCheck from '../components/mobileCheck';
import Buttombar from '../components/sidebar/Buttombar';
import { Link } from 'react-router-dom';
import UserInfo from '../components/Mypage/MypageStudent/MobileUserInfo';

function MypageManageClass() {
  const [classInfo, setClassInfo] = useState([]);
  const isMobile = MobileCheck();

  useEffect(() => {
    studentMypageExpectedClassApi()
      .then((res) => {
        console.log(res);
        setClassInfo(
          res.data.sort((prev, cur) => {
            if (prev.leftDay < cur.leftDay) return 1;
            if (prev.leftDay > cur.leftDay) return -1;
            console.log(classInfo);
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getTime = (classInfo) => {
    const startTime = classInfo.startTime;
    const startDetailTime = startTime.split('-')[2].split('T')[1].slice(0, 5);
    const endTime = classInfo.endTime;
    const endDetailTime = endTime.split('-')[2].split('T')[1].slice(0, 5);
    return [startDetailTime, endDetailTime];
  };
  const getDay = (classInfo) => {
    const day = classInfo.date;
    if (day == 'MONDAY') return '(월)';
    else if (day == 'TUESDAY') return '(화)';
    else if (day == 'WEDNESDAY') return '(수)';
    else if (day == 'THURSDAY') return '(목)';
    else if (day == 'FRIDAY') return '(금)';
    else if (day == 'SATURDAY') return '(토)';
    else if (day == 'SUNDAY') return '(일)';
  };

  const handleTime = (startTime) => {
    return `${startTime?.split('-')[0]}년 ${startTime?.split('-')[1]}월 ${
      startTime?.split('-')[2].split('T')[0]
    }일`;
  };

  return (
    <div>
      {isMobile ? (
        <Header>
          <LogoImage src={Logo} alt="로고" />
          <UserInfo />
          <LogoutLink to="/">로그아웃</LogoutLink>
        </Header>
      ) : (
        ''
      )}
      <div style={{ display: 'flex' }}>
        {isMobile ? '' : <Side />}
        <MypageBackground>
          <ClassContainer>
            <Title>수업일정</Title>
            <div style={{ marginTop: '12px' }}>
              {classInfo?.map((e) => {
                return (
                  <ClassInfo
                    Dday="오늘"
                    date={handleTime(e.startTime)}
                    startTime={getTime(e)[0]}
                    endTime={getTime(e)[1]}
                    class={e.subjectName}
                    day={getDay(e)}
                    Stu={true}
                    StuManage={true}
                    leftDay={e.leftDay}
                  />
                );
              })}
            </div>
          </ClassContainer>
          {isMobile ? <Buttombar /> : ''}
        </MypageBackground>
      </div>
    </div>
  );
}
export default MypageManageClass;

const Title = styled.div`
  display: flex;

  align-items: center;
  color: var(--Font1, #333);
  height: 36px;
  font-size: 20px;
  font-weight: 700;
`;
const ClassContainer = styled.div`
  margin-top: 32px;
  margin-left: 28px;
  @media screen and (max-width: 750px) {
    margin: 8px auto;
    padding-left: 28px;
  }
`;
const Header = styled.div`
  width: 100vw;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LogoImage = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 4%;
`;
const LogoutLink = styled(Link)`
  color: var(--Font3, #888);
  font-size: 16px;
  font-weight: 700;
  margin-right: 4%;
  text-decoration: none;
`;
