export const AdminStuFilter = [
  "학생 ID",
  "학생명",
  "성별",
  "생년월일",
  "학교명",
  "학년",
  "주소",
  "학부모 전화번호",
  "수강료",
  "담당교사",
];
