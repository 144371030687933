import styled from 'styled-components';
import {
  postScheduleDaily,
  postScheduleMonth,
} from '../../api/axios/axios.custom';

function CreateBtns(props) {
  const onClose = props.onClose;
  const postDailySchedule = () => {
    const { daily, onClose, ...rest } = props;
    const info = rest;
    console.log(new Date());

    postScheduleDaily(info)
      .then((res) => {
        onClose(false);
        window.location.reload();
      })
      .catch((err) => alert(err.response.data.message));
  };
  const postMonthSchedule = () => {
    const { daily, onClose, ...rest } = props;
    const info = rest;

    postScheduleMonth(info)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  return (
    <CreateBtnBox>
      <CreateBtn bg="#F4F4F4" color="#333" onClick={() => onClose(false)}>
        취소
      </CreateBtn>
      {props.daily ? (
        <CreateBtn onClick={postDailySchedule} bg="#3460FE" color="#fff">
          저장
        </CreateBtn>
      ) : (
        <CreateBtn onClick={postMonthSchedule} bg="#3460FE" color="#fff">
          저장
        </CreateBtn>
      )}
    </CreateBtnBox>
  );
}

export default CreateBtns;

const CreateBtnContainer = styled.div`
  width: 252px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 16px;
`;

const CreateBtn = styled.button`
  border-radius: 8px;
  border: 0;
  background: ${(props) => props.bg};
  width: 120px;
  height: 36px;
  flex-shrink: 0;
  color: ${(props) => props.color};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`;

const CreateBtnBox = styled.div`
  display: flex;

  justify-content: end;
  margin-right: 16px;

  margin-top: 48px;
`;
