import Nav from '../components/Nav/Nav';
import styles from './css/selectSignUp.module.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import MobileCheck from '../components/mobileCheck';
function SelectSignUp() {
  const navigate = useNavigate();
  const isMobile = MobileCheck();
  return (
    <div>
      {isMobile ? '' : <Nav />}

      <div className={styles.totalBox}>
        <div>
          <div
            style={{ marginTop: '10px', display: 'flex' }}
            onClick={() => navigate(-1)}
          >
            <button className={styles.goBack}> {'←'} </button>
            {isMobile ? (
              <div className={styles.title}>회원가입</div>
            ) : (
              <div className={styles.title}>로그인으로 돌아가기</div>
            )}
          </div>
        </div>
        <br />
        <br />

        <div className={styles.mobileSelectBox}>
          {isMobile ? (
            <div className={styles.mobileTitle} style={{ marginLeft: '16px' }}>
              회원 유형 선택
            </div>
          ) : (
            ''
          )}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to="/signupstudent" className={styles.selectText}>
              <div className={styles.selectBox}>
                <h3 className={styles.selectBoxTitle}>학생</h3>
                <div className={styles.selectBoxText}>
                  <p style={{ marginBottom: '0px' }}>학생(학부모)</p>
                  {isMobile ? (
                    <p style={{ marginTop: '0px' }}>회원가입</p>
                  ) : (
                    <p style={{ marginTop: '0px' }}>회원가입하러 가기 ▶</p>
                  )}
                </div>
              </div>
            </Link>
            <Link to="/signupteacher" className={styles.selectText}>
              <div
                className={styles.selectBox}
                style={{ border: '2px solid #FF812E' }}
              >
                <h3
                  className={styles.selectBoxTitle}
                  style={{ color: 'var(--BC4, #FF6500)' }}
                >
                  교사
                </h3>
                <div className={styles.selectBoxText}>
                  <p style={{ marginBottom: '0px' }}>교사</p>
                  {isMobile ? (
                    <p style={{ marginTop: '0px' }}>회원가입</p>
                  ) : (
                    <p style={{ marginTop: '0px' }}>회원가입하러 가기 ▶</p>
                  )}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SelectSignUp;
