import styled from "styled-components";
import AdminSiderBar from "../../components/sidebar/AdminSidebar";
import AdminStuInfoTableContainer from "../../components/Admin/StuInfoTable/AdminStuTable.container";

const AdminStuInfo = () => {
  return (
    <AdminStuInfoStyeld>
      <AdminSiderBar />
      <AdminStuInfoTableContainer />
    </AdminStuInfoStyeld>
  );
};

const AdminStuInfoStyeld = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  overflow: hidden;
`;

export default AdminStuInfo;
