import styled from "styled-components";
import LogoImg from "../../assets/Logo.png";
import { ReactComponent as ScheduleImg } from "../../assets/Schedule.svg";
import { ReactComponent as ProfileImg } from "../../assets/Profile.svg";
import { ReactComponent as TeacherImg } from "../../assets/Teacher.svg";
import { ReactComponent as ThingsImg } from "../../assets/Things.svg";
import { ReactComponent as CardImg } from "../../assets/Card.svg";
import { ReactComponent as LogoutImg } from "../../assets/Logout.svg";
import { useLocation } from "react-router-dom";

const AdminSiderBar = () => {
  const { pathname } = useLocation();
  const onClickLentMainBtn = () => {
    window.location.href = "/admin/main";
  };

  const onClickStuInfoBtn = () => {
    window.location.href = "/admin/studentInfo";
  };

  const onClickTeacherInfoBtn = () => {
    window.location.href = "/admin/teacherInfo";
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <AdminSiderBarStyled>
      <LogoStyled>
        <img src={LogoImg} alt="" />
        리틀코더
      </LogoStyled>
      <SectionWrap>
        <SectionStyled
          active={pathname.includes("admin/main")}
          fill={true}
          onClick={onClickLentMainBtn}
        >
          상담신청 확인{" "}
          <div>
            <ScheduleImg fill="#888888" />
          </div>
        </SectionStyled>
        <SectionStyled
          active={pathname.includes("admin/studentInfo")}
          onClick={onClickStuInfoBtn}
        >
          전체학생 정보{" "}
          <div>
            <ProfileImg stroke="#888888" />
          </div>
        </SectionStyled>
        <SectionStyled
          active={pathname.includes("admin/teacherInfo")}
          onClick={onClickTeacherInfoBtn}
          fill={true}
        >
          전체교사 정보{" "}
          <div>
            <TeacherImg fill="#888888" />
          </div>
        </SectionStyled>
        <SectionStyled active={pathname.includes("admin/amount")}>
          금액 승인{" "}
          <div>
            <CardImg stroke="#888888" />
          </div>
        </SectionStyled>
        <SectionStyled active={pathname.includes("admin/tools")}>
          교구 현황{" "}
          <div>
            <ThingsImg stroke="#888888" />
          </div>
        </SectionStyled>
        <SectionStyledBottom onClick={handleLogout}>
          로그아웃{" "}
          <div>
            <LogoutImg fill="#888888" />
          </div>
        </SectionStyledBottom>
      </SectionWrap>
    </AdminSiderBarStyled>
  );
};

const AdminSiderBarStyled = styled.div`
  height: 100%;
  width: 230px;
  min-width: 230px;
  padding-top: 30px;
  background-color: white;
  z-index: 10;
`;

const LogoStyled = styled.div`
  margin-left: 15%;
  width: 180px;
  height: 50px;
  margin-bottom: 50px;
  img {
    margin-right: 10px;
    width: 28px;
    height: 28px;
  }
  color: var(--Font1, #333);
  font-family: yg-jalnan;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
`;

const SectionWrap = styled.div`
  height: calc(100% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SectionStyled = styled.div`
  width: 160px;
  color: #555555;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 600;

  & div {
    width: 24px;
    height: 24px;
    margin-left: auto;
  }
  color: ${(props) => (props.active ? "var(--main-color)" : "inherit")};
  svg {
    stroke: ${(props) => (props.active ? "var(--main-color)" : "#888888")};
    fill: ${(props) =>
      props.active && props.fill
        ? "var(--main-color)"
        : props.fill
        ? "#888888"
        : "white"};
  }
  &:hover {
    color: var(--main-color);
    svg {
      stroke: var(--main-color);
      fill: ${(props) => (props.fill ? "var(--main-color)" : "#white")};
    }
  }
`;

const SectionStyledBottom = styled(SectionStyled)`
  margin-top: auto;
`;

export default AdminSiderBar;
