import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./SignIn.module.css";
import NaverLoginButton from "./NaverLoginButton";
import KakaoLoginButton from "./KakaoLoginButton";
import MobileCheck from "../mobileCheck";
import jwtDecode from "jwt-decode";

function SignInBox() {
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const isMobile = MobileCheck();

  const handleLogin = () => {
    const userData = { loginId: id, password: pw };
    const API = process.env.REACT_APP_BASE_URL;

    axios
      .post(`${API}/api/user/signin`, userData)
      .then((res) => {
        const data = res.data.data;
        const userData = data.userDefaultDto;
        console.log(userData);
        setToken(data.tokenDto.accessToken);
        localStorage.setItem("token", data.tokenDto.accessToken);
        const Role = jwtDecode(data.tokenDto.accessToken).role;
        localStorage.setItem("role", Role);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("name", userData.name);
        localStorage.setItem(
          "userId",
          JSON.stringify(
            userData.teacherId ? userData.teacherId : userData.studentId
          )
        );

        setError("");
        Role == "ROLE_STUDENT"
          ? navigate("/mypage")
          : Role == "ROLE_ADMIN"
          ? navigate("/admin/main")
          : navigate("/mypageteach");
      })
      .catch((error) => {
        console.log(error);
        setToken("");
        setError("잘못된 이메일 또는 비밀번호입니다."); // 로그인 실패 시 처리 (예: 에러 메시지 표시)
      });
  };
  const LoginFn = (e) => {
    e.preventDefault();

    if (!id) {
      return alert("아이디를 입력하세요.");
    } else if (!pw) {
      return alert("비밀번호를 입력하세요");
    }
  };
  const onChangeId = (text) => {
    setId(text.target.value);
  };
  const onChangePw = (text) => {
    setPw(text.target.value);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.background}>
        <div className={styles.loginBox}>
          {isMobile ? (
            <div
              style={{
                color: "#888888",
                fontSize: "24px",
                marginTop: "8px",
                marginLeft: "20px",
              }}
              onClick={() => navigate(-1)}
            >
              ←
            </div>
          ) : (
            ""
          )}
          <div className={styles.titleBox}>
            {isMobile ? (
              ""
            ) : (
              <div
                style={{
                  color: "#888888",
                  fontSize: "24px",
                }}
                onClick={() => navigate(-1)}
              >
                ←
              </div>
            )}
            <div className={styles.title}>로그인</div>
          </div>
          <form onSubmit={LoginFn}>
            <div className={styles.inputBox}>
              <input
                style={{ marginTop: "16px" }}
                onChange={onChangeId}
                className={styles.inputs}
                value={id}
                id="id"
                type="text"
                placeholder="아이디"
              />

              <input
                onChange={onChangePw}
                className={styles.inputs}
                value={pw}
                id="password"
                type="password"
                visible="false"
                placeholder="비밀번호"
              />
            </div>
            <div style={{ color: "red", margin: "0px 43px" }}>{error}</div>
            {token && <div>JWT: {token}</div>}
            <div className={styles.checkbox}>
              <input type="checkbox" />
              <span className={styles.maintain}>자동 로그인</span>
              <a className={styles.find} href="#">
                아이디 / 비밀번호 찾기
              </a>
            </div>
            <button
              className={styles.loginBtn}
              onClick={handleLogin}
              type="submit"
            >
              <p className={styles.loginText}>로그인</p>
            </button>

            <div className={styles.signUp}>
              <div className={styles.signUpBox}>
                <div>처음 방문하셨나요?</div>
                <Link to="/selectsignup" className={styles.signUpText}>
                  회원가입 하기
                </Link>
              </div>
            </div>

            <div className={styles.btnBox}>
              <KakaoLoginButton />
              <NaverLoginButton />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignInBox;
