import styled from 'styled-components';
import { SemiTitle } from './CreateMonthSchedule';
import scheduleCalendar from '../../assets/scheduleCalendar.svg';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Day } from '../../assets/Data/Day';
function SelectDate(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  let year = startDate.getFullYear();
  let month = startDate.getMonth() + 1;
  let date = startDate.getDate();
  let day = startDate.getDay();
  useEffect(() => {
    if (date < 10) {
      date = `0${date}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    let dateInfo = `${year}-${month}-${date}`;

    props.setDailyInfo(dateInfo);
  }, [startDate]);

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    if (e < new Date()) {
      alert('금일 이전의 날짜는 선택할 수 없습니다.');
    } else setStartDate(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <SemiTitle>{props.title}</SemiTitle>
      <SelectBox onClick={handleClick}>
        <p style={{ marginLeft: '16px' }}>{`${year}-${month}-${date}`}</p>
        <img style={{ marginRight: '8px' }} src={scheduleCalendar} />
      </SelectBox>
      {isOpen && (
        <div style={{ position: 'absolute' }}>
          <DatePicker
            className="input-datepicker"
            selected={startDate}
            onChange={handleChange}
            inline
          />
        </div>
      )}
    </div>
  );
}
export const SelectBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 176px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ccc;

  color: var(--Font3, #888);
  font-size: 14px;
  font-weight: 400;
  align-items: center;

  margin-top: 4px;
`;
export default SelectDate;
