import { useEffect, useState, useCallback } from "react";
import { axiosAdminConsList } from "../../../api/axios/axios.custom";
import AdminApplyTable from "./AdminApplyTable";
import styled from "styled-components";
import Pagination from "../../Pagination/Pagination";
import SearchImg from "../../../assets/Search.svg";
import Loading from "../../Common/Loading";

const AdminApplyTableContainer = () => {
  const [consultantList, setConsultantList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getConsultantList = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(async () => {
        const response = await axiosAdminConsList();
        setConsultantList(response.data.reverse());
        const totalPages = Math.ceil(response.data.length / itemsPerPage);
        setTotalPage(totalPages);
        setLoading(false);
      }, 200);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    getConsultantList();
  }, [getConsultantList]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleSearchClick = async () => {
    if (searchText.trim().length < 2) {
      return alert("두 글자 이상의 검색어를 입력해주세요.");
    }
    try {
      //const results = await axiosPubSvcFind(searchText.trim(), filterData);
      //setShowNoResult(results.length === 0);
      //setSearchResults(results);
    } catch (error) {
      console.error("검색 중 오류 발생:", error);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = consultantList.slice(startIndex, endIndex);
  return (
    <>
      {loading && <Loading />}
      <AdminApplyTableContainerStyled>
        <TopContentStyled>
          <TitleStyled>상담 신청 확인</TitleStyled>
          <SearchBarStyled>
            <SearchBar>
              <input
                type="text"
                value={searchText}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress}
              />
              <button onClick={handleSearchClick}>
                <img src={SearchImg} alt="search" />
              </button>
            </SearchBar>
          </SearchBarStyled>
        </TopContentStyled>
        <AdminApplyTable
          consultants={currentPageData}
          getConsultantList={getConsultantList}
        ></AdminApplyTable>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={handlePageChange}
        />
      </AdminApplyTableContainerStyled>
    </>
  );
};

const AdminApplyTableContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  margin: 50px 30px 10px 30px;
`;

const UserInfoStyled = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 50px;
`;

const UserBoxstyeld = styled.div`
  width: 65px;
  height: 20px;
  background-color: #cccccc;
  font-size: 14px;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopContentStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleStyled = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #333333;
`;

const SearchBarStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 45%;
`;

const SearchBar = styled.div`
  width: 100%;
  height: 45px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 4px 10px 0px #0000001a;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 87%;
    font-size: 16px;
    color: #767676;
    flex: 1;
    border: none;
    border-radius: 15px 0 0 15px;
    outline: none;
    margin-left: 30px;
  }

  button {
    width: 40px;
    height: 40px;
    float: right;
    background-color: white;
    margin: 6px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
`;

export default AdminApplyTableContainer;
