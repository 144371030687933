import styled from 'styled-components';
import back from '../../../assets/LeftArrow.svg';
import { useNavigate } from 'react-router-dom';
function Back(props) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '16px',
        marginLeft: '28px',
      }}
    >
      <BackImg onClick={() => navigate(-1)} src={back}></BackImg>
      {props.noName ? '' : <Name>{props.name} 학생</Name>}
    </div>
  );
}

export default Back;

const BackImg = styled.img`
  width: 24px;
  height: 24px;
  filter: invert(38%) sepia(58%) saturate(1974%) hue-rotate(2deg)
    brightness(105%) contrast(105%);

  &:hover {
    cursor: pointer;
  }
`;

const Name = styled.div`
  color: var(--Font3, #888);
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
`;
