import { Link } from "react-router-dom";
import Navigation from "./Nav.module.css";
import Logo from "../../assets/LittleCoderLogo.png";

function Nav() {
  return (
    <header className={Navigation.header}>
      <div style={{ marginLeft: "2%" }}>
        <Link to="/" className={Navigation.linkStyle_l}>
          <img
            src={Logo}
            alt="Logo"
            style={{ marginRight: "10px", width: "32px", height: "32px" }}
          />
          <h1 style={{ margin: "0" }}>리틀코더</h1>
        </Link>
      </div>

      <nav>
        <Link to="/signin" className={Navigation.linkStyle_r}>
          로그인
        </Link>{" "}
        <span style={{ color: "#CCCCCC" }}>|</span>{" "}
        <Link to="/selectsignup" className={Navigation.linkStyle_r}>
          회원가입
        </Link>
      </nav>
    </header>
  );
}

export default Nav;
