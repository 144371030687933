import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function MobileMyPage() {
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const talkBoxRef = useRef(null);

  useEffect(() => {
    if (talkBoxRef.current) {
      talkBoxRef.current.scrollTop = talkBoxRef.current.scrollHeight;
    }
  }, [comments]);

  // YouTube 비디오 URL에서 비디오 ID를 추출하는 함수
  const getYoutubeVideoId = (url) => {
    const regex =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|youtu\.be\/|v=)([^#\\?\s*]{11}).*/;
    const match = url.match(regex);
    return match && match[2] ? match[2] : null;
  };

  // 내장된 YouTube URL을 얻는 함수
  const getEmbedYoutubeUrl = (videoId) => {
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const videoUrl = 'https://www.youtube.com/embed/QP0AaOFb8ko?feature=share'; // 실제 비디오 ID로 교체

  return (
    <TabStyled>
      <TopBox>
        <BackButton onClick={() => navigate(-1)}>{'<-'}</BackButton>
        <FeedbackText>수업피드백</FeedbackText>
      </TopBox>
      <TitleBox>
        <FeedTitle>[2024.01.15] ♣ Hello, Spike Prime - 메뚜기</FeedTitle>
      </TitleBox>
      <CommentBox>
        <div style={{ width: '95%' }}>
          안녕하세요, 수빈 어머니! 아이들과 수업하는 남우쌤입니다.
          <br />
          이번 시간에는 교구를 하나씩 확인하며 모터, 센서 등의 교구를 알아보고
          기초적인 동작을 해보았습니다.
          <br />
          Hello, Spike Prime의 수업으로 메뚜기 모형을 만들고, 디스플레이와
          모터를 이용해 동작해 보았습니다.
          <br />
          두개 이상의 모터를 같은 방향으로 움직이게 하기 위해, 하나의 모터
          방향을 역방향으로 설정하여 한쌍처럼 움직이게 만들어 보았습니다.
          특이사항으로는 어머니가 준비해주신 태블릿이 코딩 앱을 지원하지 않는
          기기라서 제 패드를 다음부터 챙겨가서 수업할 예정입니다. 다음 수업
          시간부터는 패드 준비를 따로 해주시지 않으셔도 좋습니다
          <br />
          <br />
          [수업 제목] <br />
          ♣ Hello, Spike Prime - 메뚜기
          <br />
          <br />
          [수업 목표] <br />
          1. 교구를 살펴보며, 모터 및 센서에 대해서 인지할 수 있다. <br />
          2. 모터의 동작에 대해서 구현을 통해 이해할 수 있다. <br />
          3. 창작을 통해 메뚜기 모델을 발전 시킬 수 있다.
        </div>
      </CommentBox>
      {videoUrl && (
        <iframe
          title="YouTube Video"
          width="380"
          height="400"
          src={getEmbedYoutubeUrl(getYoutubeVideoId(videoUrl))}
          allowFullScreen
          style={{
            display: 'block',
            margin: '0 auto',
            marginTop: '20px',
          }}
        ></iframe>
      )}
    </TabStyled>
  );
}

const TabStyled = styled.div`
  width: 100vw;
  height: 100%;
  background-color: #f5f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TopBox = styled.div`
  width: 100vw;
  height: 52px;
  display: flex;
  align-items: center;
  background-color: #fff;
`;

const BackButton = styled.button`
  background-color: #fff;
  border: none;
  font-size: 24px;
  color: #888888;
  cursor: pointer;
`;

const FeedbackText = styled.div`
  color: var(--Font1, #333);
  font-family: yg-jalnan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const TitleBox = styled.div`
  width: 100vw;
  height: 48px;
  background: #fff;
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const FeedTitle = styled.h1`
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  margin-left: 18px;
`;

const CommentBox = styled.div`
  width: 100vw;
  background: var(--BG1, #fff);
  margin-top: 5px;
  color: var(--Font1, #333);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  justify-content: center;
  text-align: left;
`;

export default MobileMyPage;
