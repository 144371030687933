export const AdminStuSpecFilter = [
  "성별",
  "생년월일",
  "학교명",
  "학년",
  "상세 주소",
  "학부모",
  "학부모 전화번호",
  "이메일",
];
