import { useEffect, useState } from "react";

function MobileCheck() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 예시로 768px 이하일 경우 모바일로 간주
    };

    // 초기화 및 화면 크기 변경 시 이벤트 처리
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}

export default MobileCheck;
