import styled from 'styled-components';
import { Code, Grade, Name } from '../MypageTeach/configuration/StudentInfo';
import { FlexBox } from '../../Schedule/CreateMonthSchedule';

function Student(props) {
  console.log(props);
  const processPhoneNo = (num) => {
    if (num != undefined) {
      return `${num.slice(0, 3)}-${num.slice(3, 7)}-${num.slice(7, 11)}`;
    }
  };
  return (
    <StuContainer>
      <StuDetailTitle>담당학생</StuDetailTitle>
      <StuDetailBox>
        <div style={{ paddingTop: '16px', paddingLeft: '20px' }}>
          <Code>#{props.code}</Code>
          <FlexBox style={{ height: '28px' }}>
            <Name>{props.name}</Name>
            <Grade>
              초{props.grade} / {props.gender == false ? '남' : '여'}
            </Grade>
          </FlexBox>
          <div style={{ marginTop: '59px' }}>
            <Grade style={{ fontWeight: '500' }}>학부모 연락처</Grade>
            <Name style={{ fontSize: '16px' }}>
              {processPhoneNo(props.phoneNo)}
            </Name>
          </div>
        </div>
      </StuDetailBox>
    </StuContainer>
  );
}

export default Student;

export const StuContainer = styled.div`
  margin-left: 24px;
  margin-top: 24px;
`;
export const StuDetailBox = styled.div`
  width: 15vw;
  height: 180px;
  margin-top: 12px;
  border-radius: 12px;
  background: var(--BG1, #fff);
`;
export const StuDetailTitle = styled.div`
  margin-left: 4px;
  color: var(--Font1, #333);
  font-size: 20px;
  font-weight: 600;
`;
