import styles from '../../routes/css/SignUp.module.css';
import { Link } from 'react-router-dom';
import Nav from '../Nav/Nav';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import DaumPostCode from 'react-daum-postcode';
import Agree from './agree';
import Timer from './timer';
import DuplicateCheck from './duplicateCheck';
import { ConstructionOutlined, Flag } from '@mui/icons-material';
import { axiosSmsSend, axiosSmsVerify } from '../../api/axios/axios.custom';

const { useState, useEffect, memo } = require('react');

function SignUpStuWeb() {
  const navigate = useNavigate();
  const [duplicate, setDuplicate] = useState(null);
  const [postNo, setPostNo] = useState('');
  const [certificationNumber, setCertificationNumber] = useState('');
  const [auth, setAuth] = useState(null);
  const [authBtn, setAuthBtn] = useState(false);
  const [button, setButton] = useState('');
  const [childName, setchildName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setpasswordCheck] = useState(false);
  const [passwordSame, setPasswordSame] = useState('');
  const [id, setId] = useState('');
  const [school, setSchool] = useState('');
  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [popup, setPopup] = useState(false);
  const [userName, setUserName] = useState('');
  const API = process.env.REACT_APP_BASE_URL;
  const handleVerify = (e) => {
    e.preventDefault();
    axiosSmsVerify(phone, certificationNumber)
      .then((res) => {
        // console.log(res);
        alert('인증되었습니다.');
        setAuth(true);
        setAuthBtn(false);

        //타이머 멈추는 코드 필요
      })
      .catch((err) => {
        alert('올바르지 않은 인증코드입니다.');
      });
  };
  const handleAuthSend = (e) => {
    e.preventDefault();
    axiosSmsSend(phone)
      .then((res) => {
        // console.log(res);
        alert('인증번호가 발송되었습니다.');
        setAuthBtn(true);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
  const handlechildNameChange = (e) => {
    setchildName(e.target.value);
  };
  const handlenameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleCertificationNumber = (e) => {
    setCertificationNumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    //@와 . 포함해야 올바른 이메일 주소로 확인
    if (e.target.value.includes('@') && e.target.value.includes('.'))
      setEmailCheck(true);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleDetailAddressChange = (e) => {
    setDetailAddress(e.target.value);
  };
  const handlePasswordChange = (e) => {
    const pattern1 = /[0-9]/; // 숫자

    setPassword(e.target.value);
    if (pattern1.test(e.target.value) && e.target.value.length > 7) {
      // console.log(
      //   'num',
      //   e.target.value.match(/[^0~9]/),
      //   'length',
      //   e.target.value.length > 7
      // );
      setpasswordCheck(true);
    }
  };
  const handleIdChange = (e) => {
    setId(e.target.value);
  };
  const handleSchoolChange = (e) => {
    setSchool(e.target.value);
  };
  const handleSexChange = (e) => {
    console.log(e.value);
    setSex(e.value);
  };
  const handleGradeChange = (e) => {
    setGrade(e.value);
  };
  const handlePwSame = (e) => {
    setPasswordSame(e.target.value);
  };
  const onCompletePost = (data) => {
    setPostNo(data.zonecode);
    setAddress(data.address);
  };

  const submitCheck = () => {
    if (id === '') alert('아이디를 입력해주세요.');
    else if (duplicate == true) alert('중복된 아이디입니다.');
    else if (password === '' || passwordCheck === false)
      alert('비밀번호를 올바르게 입력해주세요.');
    else if (email === '' || emailCheck === false)
      alert('이메일을 올바르게 입력해주세요.');
    else if (phone === '') alert('전화번호를 올바르게 입력해주세요.');
    else if (auth != true) alert('본인인증을 진행해주세요');
    else if (address === '') alert('집 주소를 입력해주세요.');
    else if (childName === '') alert('이름을 입력해주세요.');
    else if (detailAddress === '') alert('상세 주소를 입력해주세요.');
    else if (school === '') alert('학교 이름을 입력해주세요');
    else if (grade === '') alert('학년을 선택해주세요.');
    else if (sex === '') alert('성별을 선택해주세요.');
    else return true;
  };

  const sexOption = [
    { value: false, label: '남' },
    { value: true, label: '여' },
  ];
  const [sex, setSex] = useState('');
  const [grade, setGrade] = useState('');
  const gradeOption = [
    { value: '1', label: '1학년' },
    { value: '2', label: '2학년' },
    { value: '3', label: '3학년' },
    { value: '4', label: '4학년' },
    { value: '5', label: '5학년' },
    { value: '6', label: '6학년' },
  ];
  const userData = {
    childName: childName,
    loginId: id,
    password: password,
    email: email,
    phoneNo: phone,
    school: school,
    address: address,
    detailAddress: detailAddress,
    grade: grade,
    gender: sex,
    postNo: postNo,
    userName: userName,
  };
  console.log(userData);
  const handleSubmit = (e) => {
    e.preventDefault(); // submit 태그 특성 상 리로드 후 제출하기 때문에 이것이 필요함
    if (submitCheck() === true) {
      const userData = {
        childName: childName,
        loginId: id,
        password: password,
        passwordSame: passwordSame,
        email: email,
        phoneNo: phone,
        school: school,
        address: address,
        detailAddress: detailAddress,
        grade: grade,
        gender: sex,
        postNo: postNo,
        userName: userName,
      };
      console.log(userData);
      axios

        .post(`${API}/api/user/signup/student`, userData)

        .then((res) => {
          //console.log("회원가입 성공", res);
          alert('회원가입 성공하셨습니다.');
          navigate('/signupclear');
        })
        .catch((error) => {
          // console.log(' 회원가입 실패', error);
        });
    }
  };

  function AuthBtn() {
    return (
      <button onClick={handleAuthSend} className={styles.authSendBtn}>
        인증번호 전송
      </button>
    );
  }
  const MemoizedAuthButton = memo(AuthBtn);
  function VerifyBtn() {
    return (
      <button onClick={handleVerify} className={styles.duplicateBtn}>
        인증
      </button>
    );
  }
  const MemoizedVerifyBtn = memo(VerifyBtn);
  return (
    <div>
      <Nav />
      <div className={styles.container}>
        <div className={styles.background}>
          <div className={styles.setForm}>
            <div className={styles.titleBox}>
              <h3 className={styles.title}>학부모 회원가입</h3>
              <div className={styles.registBox}></div>
            </div>
            <form className={styles.inputForm}>
              <div className={styles.inputName}>학부모 이름 </div>
              <input
                onChange={handlenameChange}
                name="name"
                placeholder="이름 입력"
                className={styles.inputs}
              />
              <div className={styles.inputName}>아이디 ID</div>
              <div style={{ width: '340px', display: 'flex' }}>
                <input
                  onChange={handleIdChange}
                  name="id"
                  placeholder="아이디 입력"
                  className={styles.inputs}
                  style={{ width: '240px', margin: 0 }}
                />
                <DuplicateCheck
                  loginId={id}
                  state={'web'}
                  setDuplicate={setDuplicate}
                />
              </div>
              {id == '' ? (
                <p className={styles.errorSign}>*아이디를 입력해주세요.</p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                비밀번호(8~16자의 영문, 숫자 포함)
              </div>
              <input
                onChange={handlePasswordChange}
                name="pw"
                type="password"
                placeholder="비밀번호 입력"
                visible="false"
                className={styles.inputs}
              />

              <input
                onChange={handlePwSame}
                name="pwSame"
                type="password"
                placeholder="비밀번호 확인"
                visible="false"
                className={styles.inputs}
                style={{ marginTop: '5px' }}
              />

              {password === '' || passwordCheck === false ? (
                <p className={styles.errorSign}>
                  *8~16자의 영문, 숫자를 포함해주세요.
                </p>
              ) : password === passwordSame ? (
                <p style={{ color: 'blue', fontSize: '14px' }}>
                  비밀번호가 일치합니다.
                </p>
              ) : (
                <p style={{ color: 'red', fontSize: '14px' }}>
                  비밀번호가 일치하지 않습니다.
                </p>
              )}

              <div className={styles.inputName}>이메일</div>
              <input
                onChange={handleEmailChange}
                placeholder="이메일 입력"
                className={styles.inputs}
              />
              {email === '' || emailCheck === false ? (
                <p className={styles.errorSign}>
                  *올바른 이메일 주소를 입력해주세요.
                </p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                전화번호(-없이 숫자만 입력)
              </div>
              <input
                onChange={handlePhoneChange}
                placeholder="전화번호 입력"
                className={styles.inputs}
              />
              {phone == '' ? (
                <p className={styles.errorSign}>
                  *-없이 숫자로만 전화번호를 입력해주세요.
                </p>
              ) : (
                ''
              )}
              <MemoizedAuthButton />

              {authBtn === true ? (
                <div
                  className={styles.inputs}
                  style={{
                    width: '240px',
                    margin: '6px 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <input
                    style={{ border: '0' }}
                    onChange={handleCertificationNumber}
                    placeholder="인증번호 입력"
                  />
                  <Timer style={'30px'} />

                  <div>
                    <MemoizedVerifyBtn />
                  </div>
                </div>
              ) : (
                ''
              )}
              {auth == true ? (
                <p className={styles.errorSign} style={{ color: 'blue' }}>
                  인증이 완료되었습니다.
                </p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>집 주소</div>
              <div
                onClick={() => {
                  setPopup(!popup);
                }}
                className={styles.inputs}
              >
                {address == '' ? (
                  <p className={styles.addressText}>집 주소 검색</p>
                ) : (
                  <div>
                    <p
                      className={styles.addressText}
                      style={{ color: 'black', marginTop: '13px' }}
                    >
                      {address}
                    </p>
                  </div>
                )}
              </div>
              {popup && (
                <div>
                  <DaumPostCode onComplete={onCompletePost} autoClose />
                </div>
              )}
              <div>
                {postNo == '' ? (
                  ''
                ) : (
                  <div
                    style={{
                      marginTop: '6px',
                    }}
                    className={styles.inputs}
                  >
                    <div style={{ marginTop: '13px' }}>{postNo}</div>
                  </div>
                )}
              </div>

              <input
                onChange={handleDetailAddressChange}
                style={{ marginTop: '6px' }}
                placeholder="상세 주소 입력"
                className={styles.inputs}
              />
              {detailAddress == '' ? (
                <p className={styles.errorSign}>*상세 주소를 입력해주세요.</p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                자녀 이름(수강하는 모든 자녀의 이름 작성)
              </div>
              <input
                onChange={handlechildNameChange}
                name="name"
                placeholder="이름 입력"
                className={styles.inputs}
              />
              {childName == '' ? (
                <p className={styles.errorSign}>*이름을 입력해주세요.</p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                <span>초등학교</span>{' '}
                <span className={styles.gradeText}>학년</span>
              </div>
              <input
                className={styles.inputsSmall}
                onChange={handleSchoolChange}
                placeholder="학교 이름 입력"
              />
              <Select
                options={gradeOption}
                onChange={handleGradeChange}
                className={styles.inputsSmallGrade}
                name="grade"
                placeholder="학년"
              />
              <div>
                {school == '' ? (
                  <div
                    style={{ position: 'absolute' }}
                    className={styles.errorSign}
                  >
                    *학교 이름을 입력해주세요.
                  </div>
                ) : (
                  ''
                )}
                {grade == '' ? (
                  <div
                    style={{ position: 'absolute', marginLeft: '180px' }}
                    className={styles.errorSign}
                  >
                    *학년을 선택해주세요.
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div style={{ marginTop: '40px' }} className={styles.inputName}>
                성별(자녀)
              </div>

              <Select
                options={sexOption}
                onChange={handleSexChange}
                className={styles.inputsSmall}
                name="sex"
                placeholder="성별"
              />

              {sex === '' ? (
                <p style={{ width: '320px' }} className={styles.errorSign}>
                  *성별을 선택해주세요.
                </p>
              ) : (
                <div style={{ marginBottom: '60px' }}></div>
              )}
              <Agree />
              <button
                disabled={button}
                onClick={handleSubmit}
                className={styles.btn}
              >
                회원가입하기
              </button>
            </form>

            <div className={styles.backSignIn}>
              <Link to="/signin" className={styles.backText}>
                로그인 페이지로 돌아가기
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpStuWeb;
