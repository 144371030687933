import styled from "@emotion/styled";
import DownArrow from "../../assets/DownArrow.svg";
import PaymentDropDown from "./PaymentDropDown";
import { useEffect, useState } from "react";
import { paymentInfoApi } from "../../api/axios/axios.custom";
// 궁금증?
// 1. 이거처럼 반복되는 경우에 그냥 이런 식으로 코드를 반복해야 하는지?
// 2. 스타일 컴포넌트에 마진만 다른 경우 style을 이용해서 했는데 다른 더 좋은(통용되는) 방법이 있는지?
// 3. 스타일 컴포넌트를 적용하면 plcaeholder나 onchange는 어떻게 사용?
function PaymentInfo(props) {
  const [popUp, setPopUp] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkEssential, setCheckEssential] = useState(false);
  const [checkSelect, setCheckSelect] = useState(false);
  const payInfo = props.payInfo;
  const regexPhoneNumber = (target) => {
    return target
      ?.replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  };

  useEffect(() => {
    if (checkAll == true) {
      setCheckEssential(true);
      props.setAllow(true);
    } else {
      setCheckEssential(false);
      props.setAllow(false);
    }
  }, [checkAll]);
  console.log(checkEssential);
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
  };
  const date = new Date();
  const todayMonth = date.getMonth() + 1;
  return (
    <div>
      <PaymentInfoTitle>결제 정보</PaymentInfoTitle>
      <Box hg="158px">
        <BoxInfo>
          <Title> {`${todayMonth}월 수강료 정보`}</Title>
          <SemiTitle>
            {payInfo?.selectedSubjectDto?.selectedSubjectName}
          </SemiTitle>
          <Content style={{ marginTop: "2px" }}>
            조립도를 보고 로봇을 제작하고 로봇 출력을 제어 방법을 탐구하는 수업
          </Content>
          <SemiTitle style={{ fontSize: "16px" }}>
            {payInfo?.totalPrice?.toLocaleString()}원
          </SemiTitle>
        </BoxInfo>
      </Box>
      <Box hg="128px">
        <FlexBox>
          <BoxInfo style={{ width: "100px" }}>
            <Title>주문자 정보</Title>
            <SemiTitle>{payInfo.parentName}</SemiTitle>
            <PhoneEmail>{regexPhoneNumber(payInfo.phoneNo)}</PhoneEmail>
            <PhoneEmail style={{ margin: "0" }}>{payInfo.email}</PhoneEmail>
          </BoxInfo>
        </FlexBox>
      </Box>
      <Box hg="258px">
        <FlexBox>
          <BoxInfo style={{ width: "100px" }}>
            <Title>학생 주소</Title>
            <SemiTitle>{localStorage.getItem("name")}</SemiTitle>
            <PhoneEmail>{regexPhoneNumber(payInfo.phoneNo)}</PhoneEmail>
          </BoxInfo>
        </FlexBox>

        <BoxInfo style={{ paddingTop: "0" }}>
          <Content>{`${payInfo.address} ${payInfo.detailAddress}`}</Content>
          <Content>{payInfo.postNo}</Content>
          <SemiTitle style={{ marginTop: "16px" }}>추가사항 메모</SemiTitle>
          <InputDetail placeholder="추가사항을 입력해주세요."></InputDetail>
        </BoxInfo>
      </Box>
      <Box hg="146px">
        <BoxInfo>
          <Title>최종 결제금액</Title>
          <FlexBox style={{ marginTop: "8px" }}>
            <PhoneEmail>상품가격</PhoneEmail>
            <Content
              style={{
                marginTop: "4px",
                marginRight: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {payInfo?.totalPrice?.toLocaleString()}원
            </Content>
          </FlexBox>
          <FlexBox>
            <PhoneEmail>교구 대여료</PhoneEmail>
            <Content
              style={{
                marginTop: "4px",
                marginRight: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {parseInt(30000).toLocaleString()}원
            </Content>
          </FlexBox>

          <Line></Line>
          <FlexBox>
            <SemiTitle style={{ fontSize: "16px" }}>총 결제 금액</SemiTitle>
            <SemiTitle
              style={{
                color: "#5980FF",
                marginRight: "20px",
                fontSize: "16px",
              }}
            >
              {(payInfo?.totalPrice + 30000).toLocaleString()}원
            </SemiTitle>
          </FlexBox>
        </BoxInfo>
      </Box>
      <Box hg="56px" style={{ marginBottom: 0 }}>
        <BoxInfo>
          <FlexBox>
            <Title
              style={{ marginTop: "3px", cursor: "pointer" }}
              onClick={() => setPopUp(!popUp)}
            >
              이용 약관 동의 <img src={DownArrow}></img>
            </Title>
            <FlexBox>
              <CheckBox
                checked={checkAll}
                onClick={handleCheckAll}
                type="checkbox"
              ></CheckBox>
              <SemiTitle
                style={{
                  margin: "4px 20px 0px 6px",
                }}
              >
                전체 동의
              </SemiTitle>
            </FlexBox>
          </FlexBox>
        </BoxInfo>
      </Box>

      {popUp ? (
        <Box style={{ marginTop: 0 }} hg="100px">
          <PaymentDropDown
            checkEssential={checkEssential}
            checkSelect={checkSelect}
            setCheckEssential={setCheckEssential}
            setCheckSelect={setCheckSelect}
            setCheckAll={setCheckAll}
            setAllow={props.setAllow}
          />
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}

export default PaymentInfo;

export const Box = styled.div`
  border-radius: 12px;
  background: var(--BG1, #fff);
  width: 350px;
  height: ${(props) => props.hg};
  margin: 8px auto;
`;

export const Title = styled.div`
  color: var(--BC4, #ff6500);
  font-size: 16px;
  font-weight: 700;
`;

const BoxInfo = styled.div`
  padding-top: 16px;
  margin-left: 20px;
`;

export const SemiTitle = styled.div`
  color: var(--Font1, #333);
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
`;
const PaymentInfoTitle = styled(SemiTitle)`
  width: 350px;

  font-size: 20px;
  margin: 0 auto;
  padding-left: 24px;
`;

const Content = styled(SemiTitle)`
  width: 310px;
  font-weight: 500;
`;

const PhoneEmail = styled(Content)`
  color: var(--Font3, var(--navbar, #888));
  margin-top: 4px;
`;

export const ModifyBtn = styled.button`
  width: 48px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid var(--Line1, #ccc);
  margin-top: 48px;
  margin-right: 20px;

  background-color: #fff;
  color: var(--Font3, var(--navbar, #888));
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;
const InputDetail = styled.input`
  width: 310px;
  height: 48px;
  flex-shrink: 0;
  margin-top: 4px;
  border-radius: 8px;
  border: 1px solid var(--Line1, #ccc);

  color: var(--Font3, var(--navbar, #888));
  font-size: 16px;
  font-weight: 400;
`;
export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Line = styled.div`
  width: 310px;
  height: 1px;
  background: #ccc;
  margin-top: 8px;
`;

export const CheckBox = styled.input`
  border-radius: 4px;
  border: 1px solid var(--Line1, #ccc);
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;
