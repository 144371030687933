import styled from 'styled-components';
import EmailImg from '../../../assets/myPageStudent/EmailImg.svg';
import PhoneImg from '../../../assets/myPageStudent/PhoneImg.svg';
import teacherImg from '../../../assets/myPageStudent/PhoneImg.svg';
import { getTeacher } from '../../../api/axios/axios.custom';
import { useEffect, useState } from 'react';

function Teacher() {
  const [teacherInfo, setTeacherInfo] = useState();

  useEffect(() => {
    getTeacher()
      .then((res) => {
        setTeacherInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(teacherInfo);
  return (
    <TeacherBox>
      {teacherInfo ? (
        <>
          <TeacherImg src={teacherInfo.teacherPictureUrl}></TeacherImg>
          <InfoBox>
            <Info>담당교사</Info>
            <TeacherName>{teacherInfo?.teacherName} 선생님</TeacherName>
            <Info>
              <img src={EmailImg} />
              {teacherInfo.email}
            </Info>
            <Info>
              <img src={PhoneImg} />
              {`${teacherInfo.phoneNo?.slice(
                0,
                3
              )}-${teacherInfo.phoneNo?.slice(
                3,
                7
              )}-${teacherInfo.phoneNo?.slice(7, 11)}`}
            </Info>
          </InfoBox>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
          }}
        >
          선생님 배정이 필요합니다.
        </div>
      )}
    </TeacherBox>
  );
}
export default Teacher;

const TeacherBox = styled.div`
  display: flex;
  width: 350px;
  height: 128px;
  flex-shrink: 0;
  align-items: center;
  border-radius: 12px;
  background: var(--BG1, #fff);
  margin-top: 10px;
`;
const TeacherName = styled.div`
  color: var(--Font1, #333);
  font-size: 20px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const TeacherImg = styled.img`
  width: 96px;
  height: 96px;
  margin-left: 15px;
`;
const InfoBox = styled.div`
  margin-top: 5px;
  margin-left: 12px;
`;
const Info = styled.div`
  color: var(--Font2, #555);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
