import styles from '../../routes/css/SignUp.module.css';
import { Link } from 'react-router-dom';
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import DaumPostCode from 'react-daum-postcode';
import Agree from './agree';
import Timer from './timer';
import DuplicateCheck from './duplicateCheck';
import {
  axiosSmsSend,
  axiosSmsVerify,
  signupTeachApi,
} from '../../api/axios/axios.custom';
import { PasswordTwoTone } from '@mui/icons-material';
import signUpProfile from '../../assets/signUpProfile.svg';
import styled from 'styled-components';
const { useState, useEffect, memo } = require('react');

function SignUpTeachWeb() {
  const navigate = useNavigate();
  const [duplicate, setDuplicate] = useState(null);
  const [certificationNumber, setCertificationNumber] = useState('');
  const [passwordSame, setPasswordSame] = useState('');
  const [postNo, setPostNo] = useState('');
  const [auth, setAuth] = useState(null);
  const [button, setButton] = useState(false);
  const [img, setImg] = useState('');
  const [previewImg, setPreviewImg] = useState(signUpProfile);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setpasswordCheck] = useState(false);
  const [authBtn, setAuthBtn] = useState(false);
  const [id, setId] = useState('');
  const [school, setSchool] = useState('');
  const [address, setAddress] = useState('');
  const [popup, setPopup] = useState(false);
  const [detailAddress, setDetailAddress] = useState('');
  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState(false);

  const [phone, setPhone] = useState('');
  const [major, setMajor] = useState('');
  const [grade, setGrade] = useState('');
  const [sex, setSex] = useState('');
  const [lastEdu, setLastEdu] = useState('');

  const handleVerify = (e) => {
    e.preventDefault();
    axiosSmsVerify(phone, certificationNumber)
      .then((res) => {
        // console.log(res);
        alert('인증되었습니다.');
        setAuth(true);
        setAuthBtn(false);
      })
      .catch((err) => {
        alert('올바르지 않은 인증코드입니다.');
      });
  };
  const handleAuthSend = (e) => {
    e.preventDefault();

    axiosSmsSend(phone)
      .then((res) => {
        // console.log(res);
        alert('인증번호가 발송되었습니다.');
        setAuthBtn(true);
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };
  const onCompletePost = (data) => {
    setPostNo(data.zonecode);
    setAddress(data.address);
  };

  const handleCertificationNumber = (e) => {
    setCertificationNumber(e.target.value);
  };
  // console.log(certificationNumber);
  const handleDetailAddressChange = (e) => {
    setDetailAddress(e.target.value);
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.includes('@') && e.target.value.includes('.'))
      setEmailCheck(true);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    setPassword(e.target.value);
    if (e.target.value.match(/[^0~9]/) && e.target.value.length > 7)
      setpasswordCheck(true);
  };

  const handleIdChange = (e) => {
    setId(e.target.value);
  };
  const handleSchoolChange = (e) => {
    setSchool(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleMajorChange = (e) => {
    setMajor(e.target.value);
  };
  const handlegradeChange = (e) => {
    // console.log('hi', e.value);
    setGrade(e.value);
  };
  const handleSexChange = (e) => {
    // console.log('hi', e.value);
    setSex(e.value);
  };
  const handleEduChange = (e) => {
    setLastEdu(e.value);
  };
  const submitCheck = () => {
    if (img === '') alert('사진을 선택해주세요.');
    else if (username === '') alert('이름을 입력해주세요.');
    else if (id === '') alert('아이디를 입력해주세요.');
    else if (duplicate == true) alert('중복된 아이디입니다.');
    else if (password === '' || passwordCheck === false)
      alert('비밀번호를 올바르게 입력해주세요.');
    else if (email === '' || emailCheck === false)
      alert('이메일을 올바르게 입력해주세요.');
    else if (phone === '') alert('전화번호를 올바르게 입력해주세요.');
    else if (address === '') alert('집 주소를 입력해주세요.');
    else if (detailAddress === '') alert('상세 주소를 입력해주세요.');
    else if (school === '') alert('학교 이름을 입력해주세요');
    else if (grade === '') alert('학년을 선택해주세요.');
    else if (sex === '') alert('성별을 선택해주세요.');
    else return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // submit 태그 특성 상 리로드 후 제출하기 때문에 이것이 필요함
    if (submitCheck() === true) {
      const API = process.env.REACT_APP_BASE_URL;
      const userData = {
        userName: username,
        loginId: id,
        password: password,
        phoneNo: phone,
        email: email,
        address: address,
        detailAddress: detailAddress,
        graduationDegree: lastEdu,
        enrollmentStatus: grade,
        school: school,
        major: major,
        gender: sex,
        profile: img,
        postNo: postNo,
      };
      signupTeachApi(userData)
        .then((res) => {
          alert('회원가입 성공');
          navigate('/signin');
        })
        .catch((error) => {
          alert('회원가입 실패');
          // console.log(error);
        });
    }
  };
  const handlePwSame = (e) => {
    setPasswordSame(e.target.value);
  };
  const sexOption = [
    { value: false, label: '남' },
    { value: true, label: '여' },
  ];
  const lastEduOption = [
    { value: '고등학교', label: '고등학교' },
    { value: '대학교', label: '대학교' },
    { value: '대학원', label: '대학원' },
  ];
  const gradeOption = [
    { value: '재학', label: '재학' },
    { value: '휴학', label: '휴학' },
    { value: '졸업', label: '졸업' },
  ];

  function AuthBtn() {
    return (
      <button onClick={handleAuthSend} className={styles.authSendBtn}>
        인증번호 전송
      </button>
    );
  }
  const MemoizedAuthButton = memo(AuthBtn);
  function VerifyBtn() {
    return (
      <button onClick={handleVerify} className={styles.duplicateBtn}>
        인증
      </button>
    );
  }

  const readURL = (e) => {
    setImg(e.target.files[0]);
    if (e.target.files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        setPreviewImg(e.target.result);
      };
    }
  };
  // console.log(img);

  const MemoizedVerifyBtn = memo(VerifyBtn);
  return (
    <div>
      <Nav />
      <div className={styles.container}>
        <div className={styles.background}>
          <div className={styles.setForm}>
            <div className={styles.titleBox}>
              <h3 className={styles.title}>교사 회원가입</h3>
              <div className={styles.registBox}></div>
            </div>
            <FlexBox>
              <img className={styles.profileImg} src={previewImg}></img>
              <label for="file">
                <div className={styles.profileText}>사진 올리기</div>
              </label>
              <input
                type="file"
                id="file"
                style={{ display: 'none' }}
                accept="image/jpg, image/jpeg, image/png"
                onChange={readURL}
              ></input>
            </FlexBox>
            <form className={styles.inputForm}>
              <div className={styles.inputName}>이름</div>
              <input
                onChange={handleUsernameChange}
                name="name"
                placeholder="이름 입력"
                className={styles.inputs}
              />
              {username === '' ? (
                <p className={styles.errorSign}>*이름을 입력해주세요.</p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>아이디</div>
              <div style={{ width: '340px', display: 'flex' }}>
                <input
                  onChange={handleIdChange}
                  name="id"
                  placeholder="아이디 입력"
                  className={styles.inputs}
                  style={{ width: '240px', margin: 0 }}
                />
                <DuplicateCheck
                  loginId={id}
                  state={'web'}
                  setDuplicate={setDuplicate}
                />
              </div>
              {id === '' ? (
                <p className={styles.errorSign}>*아이디를 입력해주세요.</p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                비밀번호(8~16자의 영문, 숫자 포함)
              </div>
              <input
                onChange={handlePasswordChange}
                name="pw"
                type="password"
                placeholder="비밀번호 입력"
                className={styles.inputs}
              />
              <input
                onChange={handlePwSame}
                name="pwSame"
                type="password"
                placeholder="비밀번호 확인"
                visible="false"
                className={styles.inputs}
                style={{ marginTop: '5px' }}
              />
              {password === '' || passwordCheck === false ? (
                <p className={styles.errorSign}>
                  *8~16자의 영문, 숫자를 포함해주세요.
                </p>
              ) : password === passwordSame ? (
                <p style={{ color: 'blue', fontSize: '14px' }}>
                  비밀번호가 일치합니다.
                </p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>이메일</div>
              <input
                onChange={handleEmailChange}
                placeholder="이메일 입력"
                className={styles.inputs}
              />
              {email === '' || emailCheck === false ? (
                <p className={styles.errorSign}>
                  *올바른 이메일 주소를 입력해주세요.
                </p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                전화번호(-없이 숫자만 입력)
              </div>
              <input
                onChange={handlePhoneChange}
                placeholder="전화번호 입력"
                className={styles.inputs}
              />
              {phone === '' ? (
                <p className={styles.errorSign}>
                  *-없이 숫자로만 전화번호를 입력해주세요.
                </p>
              ) : (
                ''
              )}
              <MemoizedAuthButton />

              {authBtn === true ? (
                <div
                  className={styles.inputs}
                  style={{
                    width: '240px',
                    margin: '6px 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <input
                    style={{ border: '0' }}
                    onChange={handleCertificationNumber}
                    placeholder="인증번호 입력"
                  />
                  <Timer style={'30px'} />

                  <div>
                    <MemoizedVerifyBtn />
                  </div>
                </div>
              ) : (
                ''
              )}
              {auth == true ? (
                <p className={styles.errorSign} style={{ color: 'blue' }}>
                  인증이 완료되었습니다.
                </p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>집 주소</div>
              <div
                onClick={() => {
                  setPopup(!popup);
                }}
                className={styles.inputs}
              >
                {address == '' ? (
                  <p className={styles.addressText}>집 주소 검색</p>
                ) : (
                  <p className={styles.addressText} style={{ color: 'black' }}>
                    {address}
                  </p>
                )}
              </div>
              <div>
                {postNo == '' ? (
                  ''
                ) : (
                  <div
                    style={{
                      marginTop: '6px',
                    }}
                    className={styles.inputs}
                  >
                    <div style={{ marginTop: '13px' }}>{postNo}</div>
                  </div>
                )}
              </div>
              {popup && (
                <div>
                  <DaumPostCode onComplete={onCompletePost} autoClose />
                </div>
              )}
              <input
                onChange={handleDetailAddressChange}
                style={{ marginTop: '6px' }}
                placeholder="상세 주소 입력"
                className={styles.inputs}
              />
              {detailAddress === '' ? (
                <p className={styles.errorSign}>*상세주소를 입력해주세요.</p>
              ) : (
                ''
              )}
              <div className={styles.inputName}>
                <span>최종 학력</span>{' '}
                <span
                  style={{ marginLeft: '34%' }}
                  className={styles.gradeText}
                >
                  재학 상태
                </span>
              </div>
              <div>
                <Select
                  options={lastEduOption}
                  onChange={handleEduChange}
                  className={styles.inputsSmall}
                  placeholder="학력 선택"
                />
                <Select
                  options={gradeOption}
                  onChange={handlegradeChange}
                  className={styles.inputsSmallGrade}
                  name="grade"
                  placeholder="재학 상태 선택"
                />
              </div>
              {lastEdu == '' ? (
                <span style={{ float: 'left' }} className={styles.errorSign}>
                  *학력을 선택해주세요.
                </span>
              ) : (
                ''
              )}
              {grade == '' ? (
                <span className={styles.errorSign} style={{ float: 'right' }}>
                  *재학상태를 선택해주세요.
                </span>
              ) : (
                ''
              )}
              <div className={styles.inputName} style={{ marginTop: '30px' }}>
                학교
              </div>
              <input
                onChange={handleSchoolChange}
                placeholder="학교 입력"
                className={styles.inputs}
              />
              {school === '' ? (
                <p className={styles.errorSign}>*학교를 입력해주세요.</p>
              ) : (
                ''
              )}
              <input
                onChange={handleMajorChange}
                name="name"
                style={{ marginTop: '6px' }}
                placeholder="학과 입력"
                className={styles.inputs}
              />
              {major === '' ? (
                <p className={styles.errorSign}>*학과를 입력해주세요.</p>
              ) : (
                ''
              )}

              <div className={styles.inputName}>성별</div>
              <Select
                options={sexOption}
                onChange={handleSexChange}
                className={styles.inputsSmall}
                name="sex"
                placeholder="성별"
              />

              {sex === '' ? (
                <p style={{ width: '320px' }} className={styles.errorSign}>
                  *성별을 선택해주세요.
                </p>
              ) : (
                <div style={{ marginBottom: '60px' }}></div>
              )}
              <Agree />
              <button
                disabled={button}
                onClick={handleSubmit}
                className={styles.btn}
              >
                교사신청하기
              </button>
            </form>

            <div className={styles.backSignIn}>
              <Link to="/signin" className={styles.backText}>
                로그인 페이지로 돌아가기
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpTeachWeb;

const FlexBox = styled.div`
  margin: 0 auto;
  width: 120px;
`;
