import styles from './SignIn.module.css';
import naverLogo from '../../assets/naverLogo.png';
function NaverLoginButton() {
  const CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const STATE = 'false';
  const REDIRECTION = 'http://littlecoder.kr/mypage';
  const NaverLoginAPI = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECTION}`;

  const loginHandle = () => {
    window.location.href = NaverLoginAPI;
  };
  return (
    <button
      type="button"
      className={`${styles.Button} ${styles.naver}`}
      onClick={loginHandle}
    >
      <div className={styles.buttonText}>
        <div>
          <img className={styles.btnLogo} src={naverLogo}></img>
          <span>네이버 로그인</span>
        </div>
      </div>
    </button>
  );
}
export default NaverLoginButton;
