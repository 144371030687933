import styled from "styled-components";
import { AdminSearchFilter } from "../../../assets/Data/AdminSearchFilterData";
import { useState } from "react";
import { axiosAdminConsSpec } from "../../../api/axios/axios.custom";
import AdminApplyModal from "./AdminApplyModal";

const AdminApplyTable = ({ consultants, getConsultantList }) => {
  if (!Array.isArray(consultants)) {
    consultants = [];
  }

  const [selectedData, setSelectedData] = useState(null);

  const getApplyDetails = async (studentId) => {
    try {
      const response = await axiosAdminConsSpec(studentId);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleRowClick = async (data) => {
    const studentDetails = await getApplyDetails(data.consultantId);
    setSelectedData(studentDetails);
  };

  const closeModal = () => {
    setSelectedData(null);
  };

  const truncateText = (text, maxLength) => {
    if (text) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 2) + "..";
      } else {
        return text;
      }
    } else {
      return "";
    }
  };

  function formatDate(inputDate) {
    if(!inputDate)
    return;
    const dateObj = new Date(inputDate);
    
    const year = dateObj.getFullYear().toString().slice(-2);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  
    const formattedDate = `${year}.${month}.${day} ${hours}:${minutes}`;
    return formattedDate;
  }

  return (
    <AdminApplyTableStyled>
      <table>
        <TheadStyled>
          <tr>
            {AdminSearchFilter.map((filter, index) => (
              <th key={index}>{filter}</th>
            ))}
          </tr>
        </TheadStyled>
        <TbodyStyled>
          {consultants.map((consultant, index) => (
            <tr key={index} onClick={() => handleRowClick(consultant)}>
              <td>{truncateText(consultant.name, 10)}</td>
              <td>{truncateText(consultant.grade, 10)}</td>
              <td>{consultant.gender ? "남자" : "여자"}</td>
              <td>{truncateText(consultant.phoneNo, 14)}</td>
              <td>{truncateText(consultant.region, 9)}</td>
              <td>
                <CondingStatus status={consultant.codingExperience}>
                  {consultant.codingExperience ? "O" : "X"}
                </CondingStatus>
              </td>
              <td>
                <ContentStatus status={consultant.content}>
                  {consultant.content
                    ? truncateText(consultant.content, 12)
                    : "X"}
                </ContentStatus>
              </td>
              <td>
                <ConsultantStatus status={consultant.consultantStatus}>
                  {consultant.consultantStatus ? "진행" : "미진행"}
                </ConsultantStatus>
              </td>

              <td>
                <ClassStatus status={consultant.wantTryClass}>
                  {consultant.wantTryClass ? "O" : "―"}
                </ClassStatus>
              </td>
              <td>{formatDate(consultant.applicationTime)}</td>
              {/* <td>
                  <CondingStatus status={consultant.codingExperience}>
                    {consultant.consultantStatus ? " ✔" : "X"}
                  </CondingStatus>
                </td> */}
            </tr>
          ))}
        </TbodyStyled>
      </table>
      {selectedData && (
        <AdminApplyModal
          data={selectedData}
          onClose={closeModal}
          getConsultantList={getConsultantList}
          formatDate ={formatDate}
        />
      )}
    </AdminApplyTableStyled>
  );
};

const AdminApplyTableStyled = styled.div`
  width: 100%;
  margin: 15px 0 30px 0;
  overflow: auto;
  & > table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
`;

const TheadStyled = styled.thead`
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #555555;
  & > tr > th {
    font-weight: 500;
  }
`;

const TbodyStyled = styled.tbody`
  & > tr {
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    height: 55px;
    background-color: white;
    border-top: 3px solid #f4f4f4;
    position: relative;
  }
  & > tr > td:first-child {
    border-radius: 8px 0 0 8px;
  }
  & > tr > td:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 10px;
  }
  & > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
`;

const NoListStyled = styled.div`
  text-align: center;
  font-size: 30px;
  margin: 50px;
`;

const ConsultantStatus = styled.span`
  color: ${(props) => (props.status ? "inherit" : "red")};
`;

const CondingStatus = styled.span`
  color: ${(props) =>
    props.status ? "var(--main-color)" : "var(--sub-color)"};
  font-size: 20px;
`;

const ClassStatus = styled.span`
  color: ${(props) => (props.status ? "var(--main-color)" : "inherit")};
  font-size: 20px;
`;

const ContentStatus = styled.span`
  color: ${(props) => (props.status ? "inherit" : "var(--sub-color)")};
  font-size: ${(props) => (props.status ? "inherit" : "20px")};
`;

export default AdminApplyTable;