import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import phoneStyle from './mobileSignUp.module.css';
import webStyle from '../../routes/css/SignUp.module.css';

function DuplicateCheck(prop) {
  const API = process.env.REACT_APP_BASE_URL;
  const [duplicate, setDuplicate] = useState(null);
  const [checked, setChecked] = useState(false);
  // console.log(prop);
  const handleDuplicate = () => {
    if (prop.loginId != '')
      axios
        .get(`${API}/api/user/signup/check-id-exist/${prop.loginId}`)
        .then((res) => {
          setDuplicate(res.data);
          prop.setDuplicate(res.data);
          console.log(res);
          if (prop.state === 'web' && res.data.data == false)
            alert('사용 가능한 아이디입니다.');
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    handleDuplicate();
  }, [checked]);

  function duplicateBtn() {
    return (
      <div>
        {prop.state === 'web' ? (
          <button
            onClick={() => setChecked(!checked)}
            className={webStyle.duplicateBtn}
          >
            중복 확인
          </button>
        ) : (
          <button
            onClick={() => setChecked(!checked)}
            className={phoneStyle.authBtn}
          >
            아이디 중복 확인
          </button>
        )}

        {duplicate === true ? (
          <p className={webStyle.duplicateText} style={{ color: 'red' }}>
            이미 존재하는 아이디입니다.
          </p>
        ) : duplicate === false ? (
          <p className={webStyle.duplicateText} style={{ color: 'blue' }}>
            사용 가능한 아이디입니다.
          </p>
        ) : (
          ''
        )}
      </div>
    );
  }
  const MemoizedDuplicateBtn = memo(duplicateBtn);

  return <MemoizedDuplicateBtn />;
}

export default DuplicateCheck;
