import { useEffect, useState, useCallback } from "react";
import { axiosAdminStuList } from "../../../api/axios/axios.custom";
import AdminStuTable from "./AdminStuTable";
import styled from "styled-components";
import Pagination from "../../Pagination/Pagination";
import Loading from "../../Common/Loading";

const AdminStuInfoTableContainer = () => {
  const [consultantList, setConsultantList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [loading, setLoading] = useState(false);

  const getConsultantList = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(async () => {
        const response = await axiosAdminStuList();
        setConsultantList(response.data.reverse());
        const totalPages = Math.ceil(response.data.length / itemsPerPage);
        setTotalPage(totalPages);
        setLoading(false);
      }, 200);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    getConsultantList();
  }, [getConsultantList]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = consultantList.slice(startIndex, endIndex);
  return (
    <>
      {loading && <Loading />}
      <AdminStuTableContainerStyled>
        <TopContentStyled>
          <TitleStyled>전체 학생 정보 확인</TitleStyled>
        </TopContentStyled>
        <BodyStyled>
          <AdminStuTable
            dataList={currentPageData}
            getConsultantList={getConsultantList}
          ></AdminStuTable>
        </BodyStyled>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={handlePageChange}
        />
      </AdminStuTableContainerStyled>
    </>
  );
};

const AdminStuTableContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  margin: 50px 30px 10px 30px;
`;

const UserInfoStyled = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 50px;
`;

const UserBoxstyeld = styled.div`
  width: 65px;
  height: 20px;
  background-color: #cccccc;
  font-size: 14px;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopContentStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleStyled = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  line-height: 45px;
`;

const BodyStyled = styled.div`
  width: 100%;
`;

export default AdminStuInfoTableContainer;
