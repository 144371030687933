import styles from "./css/consult.module.css";
import { Link } from "react-router-dom";
import Nav from "../components/Nav/Nav";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { axiosConsultApply } from "../api/axios/axios.custom";

const { useState, useEffect } = require("react");

function Consult() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [region, setRegion] = useState("");
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleregionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSexChange = (e) => {
    setSex(e.value);
  };
  const handleGradeChange = (e) => {
    setGrade(e.value);
  };
  const handleExperienceChange = (e) => {
    setExperience(e.value);
  };

  const submitCheck = () => {
    if (username === "") alert("이름을 입력해주세요.");
    else if (grade === "") alert("학년을 선택해주세요.");
    else if (phone === "") alert("전화번호를 올바르게 입력해주세요.");
    else if (region === "") alert("지역을 입력해주세요.");
    else return true;
  };

  const sexOption = [
    { value: false, label: "남" },
    { value: true, label: "여" },
  ];
  const [sex, setSex] = useState("");
  const [grade, setGrade] = useState("");
  const [experience, setExperience] = useState("");
  const [content, setContent] = useState("");
  const [wantTime, setWantTime] = useState("");
  const gradeOption = [
    { value: 1, label: "1학년" },
    { value: 2, label: "2학년" },
    { value: 3, label: "3학년" },
    { value: 4, label: "4학년" },
    { value: 5, label: "5학년" },
    { value: 6, label: "6학년" },
  ];
  const experienceOption = [
    { value: true, label: "O" },
    { value: false, label: "X" },
  ];

  const handleWantTime = (e) => {
    setWantTime(e.target.value);
  };

  const handleSubmit = () => {
    if (submitCheck() === true) {
      const userData = {
        // content: content,
        grade: grade,
        name: username,
        phoneNo: phone,
        region: region,
      };

      async function handleConsultApply() {
        try {
          const responseData = await axiosConsultApply(userData);
          // console.log("수업 신청 성공", responseData);
          alert("수업 신청에 성공하였습니다.");
          navigate("/");
        } catch (error) {
          // console.error("수업 신청 실패", error);
        }
      }
      handleConsultApply(userData);
    }
  };

  return (
    <>
      <Nav />
      <div className={styles.mobileNav}>
        <Link to="/" className={styles.linkWithoutUnderline}>
          <span class="arrow-button">&#8592;</span>
        </Link>
        무료 체험수업 신청하기
      </div>
      <div className={styles.container}>
        <div className={styles.background}>
          <div className={styles.setForm}>
            <div className={styles.titleBox}>
              <h3 className={styles.title}>신청서 작성</h3>
              <div className={styles.registBox}></div>
            </div>

            <form className={styles.inputForm}>
              <div className={styles.inputName}>
                학생 이름 <span>*</span>
              </div>
              <input
                onChange={handleUsernameChange}
                name="name"
                placeholder="이름 입력"
                className={styles.inputs}
              />
              <div className={styles.select}>
                <div className={styles.inputName}>
                  <div className={styles.gradeText}>
                    학년 <span>*</span>
                  </div>
                  <Select
                    options={gradeOption}
                    onChange={handleGradeChange}
                    className={styles.inputsSmallGrade}
                    name="grade"
                    placeholder="학년 선택"
                  />
                </div>
              </div>

              <div className={styles.inputName}>
                학부모 전화번호(-없이 숫자만 입력) <span>*</span>
              </div>
              <input
                onChange={handlePhoneChange}
                placeholder="전화번호 입력"
                className={styles.inputs}
              />
              <div className={styles.inputName}>지역(Ex: OO시 OO구)</div>

              <input
                onChange={handleregionChange}
                placeholder="거주 지역 입력"
                className={styles.inputs}
              />

              {/* <div className={styles.select}>
                <div className={styles.inputName}>
                  <span className={styles.gradeText}>코딩 경험 유무</span>
                  <Select
                    options={experienceOption}
                    onChange={handleExperienceChange}
                    className={styles.inputsSmallGrade}
                    name="grade"
                    placeholder="유/무 선택"
                  />
                </div>

                <div className={styles.inputName}>
                  <span className={styles.gradeText}>상담 희망시간</span>

                  <input
                    name="wantTime"
                    className={styles.inputsSmallGrade}
                    style={{
                      height: "46px",
                      width: "150px",
                      marginLeft: "26px",
                    }}
                    onChange={handleWantTime}
                    placeholder="00:00"
                  ></input>
                </div>
              </div> */}

              {/* <div className={styles.inputName}>문의사항</div>
              <textarea
                style={{
                  paddingTop: "10px",
                  height: "6.25em",
                  resize: "none",
                }}
                onChange={handleContentChange}
                placeholder="문의사항 입력"
                className={styles.inputs}
              ></textarea> */}
            </form>

            <button onClick={handleSubmit} className={styles.btn}>
              체험수업 신청하기
            </button>

            {/* <div className={styles.backSignIn}>
              <Link to="/signin" className={styles.backText}>
                로그인 페이지로 돌아가기
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className={styles.post}>
        <h1>전화로 체험수업 신청하세요!</h1>
        <p>010-9863-3020</p>
        <button
          style={{
            width: "310px",
            height: "56px",
            borderRadius: "8px",
            background: "var(--BG1, #FFF)",
            flexShrink: 0,
            fontSize: "20px",
            fontWeight: 800,
            fontFamily: "Pretendard",
            color: "var(--Font1, #333)",
            marginTop: "5px",
            border: "1px solid var(--BC3, #3460FE)",
          }}
        >
          전화로 신청하기
        </button>
      </div>
    </>
  );
}

export default Consult;
