import styled from 'styled-components';
import EmailImg from '../../../assets/myPageStudent/EmailImg.svg';
import PhoneImg from '../../../assets/myPageStudent/PhoneImg.svg';
import teacherImg from '../../../assets/myPageStudent/PhoneImg.svg';
import { getTeacher } from '../../../api/axios/axios.custom';
import { useEffect, useState } from 'react';

function Teacher() {
  const [teacherInfo, setTeacherInfo] = useState();

  useEffect(() => {
    getTeacher()
      .then((res) => {
        setTeacherInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(teacherInfo);
  return teacherInfo == undefined ? (
    <TeacherBox
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        fontWeight: '500',
      }}
    >
      선생님 배정이 필요합니다.
    </TeacherBox>
  ) : (
    <TeacherBox>
      <TeacherImg src={teacherInfo?.teacherPictureUrl}></TeacherImg>
      <InfoBox>
        <Info>담당교사</Info>
        <TeacherName>{teacherInfo?.teacherName} 선생님</TeacherName>
        <Info>
          <img src={EmailImg} />
          {teacherInfo?.email}
        </Info>
        <Info>
          <img src={PhoneImg} />
          {`${teacherInfo?.phoneNo?.slice(0, 3)}-${teacherInfo?.phoneNo?.slice(
            3,
            7
          )}-${teacherInfo?.phoneNo?.slice(7, 11)}`}
        </Info>
      </InfoBox>
    </TeacherBox>
  );
}
export default Teacher;

const TeacherBox = styled.div`
  display: flex;
  width: 392px;
  height: 184px;
  border-radius: 12px;
  background: var(--BG1, #fff);
  margin-top: 10px;
  margin-left: 24px;
`;
const TeacherName = styled.div`
  color: var(--Font1, #333);
  font-size: 20px;
  font-weight: 600;
`;
const TeacherImg = styled.img`
  margin-top: 16px;
  margin-left: 20px;
  width: 152px;
  height: 152px;
`;
const InfoBox = styled.div`
  margin-top: 20px;
  margin-left: 12px;
`;
const Info = styled.div`
  color: var(--Font2, #555);
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;

  display: flex;
  align-items: center;
`;
