import { Token } from '@mui/icons-material';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const axiosConsultApply = async (userData) => {
  try {
    const response = await axios.post('/api/user/consultant/apply', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const axiosGetKakaoTokenUrl = '/api/user/kakao-login';
export const axiosGetKakaoToken = async (code) => {
  try {
    const response = await axios.get(axiosGetKakaoTokenUrl, {
      params: {
        code: code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const axiosSmsSend = async (phoneNo) => {
  try {
    // console.log(phoneNo);
    const response = await axios.post('/api/user/sms-certification/sends', {
      phoneNo: phoneNo,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const axiosGetNaverTokenUrl = '/api/user/naver-login';
export const axiosGetNaverToken = async (code) => {
  try {
    const response = await axios.get(axiosGetNaverTokenUrl, {
      params: {
        code: code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const axiosSmsVerify = async (phoneNo, certificationNumber) => {
  try {
    const response = await axios.post('/api/user/sms-certification/verify', {
      certificationNumber: certificationNumber,
      phoneNo: phoneNo,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminConsList = async () => {
  try {
    const response = await axios.get('/api/admin/consultant/list', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminConsSpec = async (consultantId) => {
  try {
    const response = await axios.get('/api/admin/consultant/specific', {
      params: {
        consultantId: consultantId,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const signupTeachApi = async (userData) => {
  console.log(userData.profile);
  const formData = new FormData();
  formData.append('profileImg', userData.profile);
  delete userData.profile;
  const blob = new Blob([JSON.stringify(userData)], {
    type: 'application/json',
  });
  formData.append('signupTeacherRequestDto', blob);

  try {
    // console.log(formData.get('profileImg'));
    // console.log(formData.get('signupTeacherRequestDto'));
    const response = await axios.post(`/api/user/signup/teacher`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const axiosAdminStuList = async () => {
  try {
    const response = await axios.get('/api/admin/student/list', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminStuSpec = async (studentId) => {
  try {
    const response = await axios.get('/api/admin/student/specific', {
      params: {
        studentId: studentId,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminStuSave = async (studentDto) => {
  try {
    const response = await axios.post('/api/admin/student/save', studentDto, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminConSave = async (consultantUpdateDto) => {
  try {
    const response = await axios.post(
      '/api/admin/consultant/status',
      consultantUpdateDto,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getScheduleList = async () => {
  try {
    const response = await axios.get(
      `/api/teacher/subject/schedule/list?page=${1}`,

      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const postScheduleDaily = async (infoDto) => {
  console.log(infoDto);
  try {
    const response = await axios.post(
      '/api/teacher/subject/schedule/daily',
      infoDto,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const postScheduleMonth = async (info) => {
  try {
    const response = await axios.post(
      '/api/teacher/subject/schedule/monthly',
      info,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteScheduleApi = async (props) => {
  try {
    const response = await axios.delete(
      `/api/teacher/subject/schedule/delete`,

      {
        params: { scheduleId: props },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTeacher = async () => {
  try {
    const response = await axios.get('/api/student/teacher', {
      params: { studentId: localStorage.getItem('userId') },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const paymentInfoApi = () => {
  return axios.get('/api/order/info', {
    params: { studentId: localStorage.getItem('userId') },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

export const paymentApi = (paymentInfo) => {
  console.log(paymentInfo);
  return axios.post('/api/order/request', paymentInfo, { headers: {} });
};

export const paymentSuccessApi = (successInfo) => {
  console.log(successInfo);
  return axios.post('/api/order/success', successInfo, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

export const ExpectedClassStudentApi = () => {
  return axios.post('/api/student/subject/schedule/list', '', {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });
};

export const axiosAdminTeacList = async () => {
  try {
    const response = await axios.get('/api/admin/teacher/list', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminTeacSave = async (teacherDto) => {
  try {
    const response = await axios.post('/api/admin/teacher/save', teacherDto, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosAdminTeacSpec = async (teacherId) => {
  try {
    const response = await axios.get('/api/admin/teacher/specific', {
      params: {
        teacherId: teacherId,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosGetStuSpecific = async (studentId) => {
  try {
    const response = await axios.get('/api/student/specific', {
      params: {
        studentId: studentId,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const axiosStuModifySave = async (studentModifyRequestDto) => {
  try {
    const response = await axios.post(
      '/api/student/modify/info',
      studentModifyRequestDto,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const studentMypageExpectedClassApi = async () => {
  try {
    const response = await axios.post(
      `/api/student/subject/schedule/list?studentId=${localStorage.getItem(
        'userId'
      )}`,
      '',
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosGetTeaSpecific = async (teacherId) => {
  try {
    const response = await axios.get('/api/teacher/specific', {
      params: {
        teacherId: teacherId,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const axiosTeaModifySave = async (teacherModifyRequestDto) => {
  try {
    const response = await axios.post(
      '/api/teacher/modify/info',
      teacherModifyRequestDto,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const studentFeedbackApi = async (page, teacherId, studentId) => {
  try {
    const response = await axios.post(
      '/api/student/feedback/list',
      {
        page: page,
        studentId: studentId,
        teacherId: teacherId,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const instance = axios.create({
  baseURL: 'http://www.leco.kr:8081/',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
});

export const axiosFeedbackCreate = async (postData) => {
  try {
    const response = await axios.post(
      '/api/teacher/feedback/create',
      {
        content: postData.content,
        subjectScheduleId: postData.subjectScheduleId,
        title: postData.title,
        userMatchId: postData.userMatchId,
        videoUrl: postData.videoUrl,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosFeedbackList = async (page, studentId) => {
  try {
    const response = await axios.post(
      `/api/teacher/feedback/list`,
      {
        page: page,
        studentId: studentId,
        teacherId: localStorage.getItem('userId'),
      },

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response) {
      // 서버 응답이 왔지만 상태 코드가 2xx 범위 밖인 경우
      console.error('Server responded with an error:', error.response.data);
    } else if (error.request) {
      // 서버에 요청이 전송되지 않은 경우
      console.error('No response received from the server:', error.request);
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생한 경우
      console.error('Error setting up the request:', error.message);
    }

    throw error;
  }
};

export const axiosFeedbackDetail = async (feedbackId) => {
  try {
    const response = await axios.post(`/api/user/feedback/${feedbackId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosFeedbackDelete = async (feedbackId) => {
  try {
    const response = await axios.get(
      `/api/user/feedback/delete/${feedbackId}`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const teacherExpectedClassApi = async (page) => {
  try {
    const response = await axios.get(
      `/api/teacher/subject/schedule/coming/all/list?page=${page}`,

      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const axiosFeedbackUpdate = async (userData) => {
  try {
    const response = await axios.post('/api/user/feedback/update', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const studentExpectedClassApi = async (page, matchId) => {
  try {
    const response = await axios.get(
      `/api/teacher/subject/schedule/coming/student/list?page=${page}&userMatchId=${matchId}`,

      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getStudentInfoApi = async (matchId) => {
  try {
    const response = await axios.get(
      `/api/teacher/student?userMatchId=${matchId}`,

      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const teacherMypageFeedbackListApi = async (page, studentId) => {
  console.log(page, studentId, localStorage.getItem('userId'));
  try {
    const response = await axios.post(
      `/api/teacher/feedback/list`,
      {
        page: page,
        studentId: studentId,
        teacherId: localStorage.getItem('userId'),
      },

      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const studentFeedbackListApi = async (page, studentId) => {
  try {
    const response = await axios.post(
      `/api/student/feedback/list`,
      {
        page: page,
        studentId: studentId,
        teacherId: localStorage.getItem('userId'),
      },

      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFeedbackApi = async (feedbackId) => {
  try {
    const response = await axios.delete(
      `/api/teacher/feedback/delete/${feedbackId}`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const FeedbackClassList = async () => {
  try {
    const response = await axios.get(`/api/teacher/feedback/subject/schedule`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
