import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Title } from "./Paging";
function SemiBox(props) {
  const navigate = useNavigate();
  return (
    <SemiTitleContainer>
      <SemiTitle>{props.title}</SemiTitle>
      <ManageBtn onClick={() => navigate(props.navigate)}>
        {props.btn}
      </ManageBtn>
    </SemiTitleContainer>
  );
}

export default SemiBox;

const SemiTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0 8px 8px;

  align-items: center;
  justify-content: space-between;
`;
const SemiTitle = styled(Title)`
  font-size: 16px;
`;
const ManageBtn = styled.button`
  width: 92px;
  height: 28px;
  border: 0;
  color: var(--BG1, #fff);
  font-size: 14px;
  font-weight: 700;

  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: var(--BC3, #3460fe);
`;
