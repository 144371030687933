import { Container } from './Students';
import Paging from './configuration/Paging';
import ClassInfo from './configuration/ClassInfo';
import { useEffect, useState } from 'react';
import SemiBox from './configuration/SemiBox';
import { info } from '../../../assets/Data/TeacherMypageClassInfo';
import {
  studentExpectedClassApi,
  teacherExpectedClassApi,
} from '../../../api/axios/axios.custom';
import { useParams } from 'react-router-dom';

function ExpectedClass(props) {
  const [page, setPage] = useState(1);
  const [scheduleList, setScheduleList] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [length, setLength] = useState();
  const params = useParams();

  const getTime = (classInfo) => {
    const startTime = classInfo.startTime;
    const startDetailTime = startTime.split('-')[2].split('T')[1].slice(0, 5);
    const endTime = classInfo.endTime;
    const endDetailTime = endTime.split('-')[2].split('T')[1].slice(0, 5);
    return [startDetailTime, endDetailTime];
  };

  useEffect(() => {
    props.Stu
      ? studentExpectedClassApi(page, params.id)
          .then((res) => {
            console.log(res);
            setScheduleList(res.data.comingScheduleDtoList);
            setMaxPage(res.data.totalLength);
            setLength(3);
          })
          .catch((err) => {
            console.log(err);
          })
      : teacherExpectedClassApi(page)
          .then((res) => {
            console.log(res);
            setScheduleList(res.data.comingScheduleDtoList);
            setMaxPage(res.data.totalLength);
            setLength(8);
          })
          .catch((err) => {
            console.log(err);
          });
  }, [page]);
  console.log(scheduleList);
  return (
    <Container>
      <Paging
        text="예정된 수업"
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(maxPage / length)}
      />
      <SemiBox
        title="다가올 수업 순"
        btn="일정 관리하기"
        navigate="/mypageteach/schedule"
      />
      {scheduleList?.map((e) => {
        return (
          <ClassInfo
            page={page}
            year={e.year}
            month={e.month}
            day={e.day}
            startTime={getTime(e)[0]}
            endTime={getTime(e)[1]}
            student={e.studentName}
            date={e.date}
            leftDay={e.leftDay}
            Stu={props.Stu}
          />
        );
      })}
    </Container>
  );
}

export default ExpectedClass;
